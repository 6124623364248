import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import BuckKnives119SpecialPic from '../images/BuckKnives119Special.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import HuntingKnivesPic from '../../images/HuntingKnives.jpg';


function BuckKnives119Special () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Buck Knives 119 Special</h1>
          <Link href="https://www.buckknives.com/product/119-special-knife/0119FAM01/" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ HuntingKnivesPic} style={{height:'120px'}} alt="Hunting Knives"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Buck Knives 119 Special" height="130px" src={BuckKnives119SpecialPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Buck Knives 119 Special is a classic hunting knife known for its reliability and craftsmanship. It features a 6-inch stainless steel clip blade, an ergonomically designed handle made of phenolic material, and includes a genuine leather sheath for safe storage. It is widely appreciated for its rugged design, sharpness, and durability, making it a popular choice among outdoor enthusiasts and hunters.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">High-quality stainless steel blade offers excellent edge retention.</li>
<li className="ListItemStyle">Classic design with a modern build for durability.</li>
<li className="ListItemStyle">Ergonomic handle ensures a comfortable grip.</li>
<li className="ListItemStyle">Includes a genuine leather sheath for protection and portability.</li>
<li className="ListItemStyle">Suitable for a wide range of outdoor tasks.</li>
<li className="ListItemStyle">Backed by Buck Knives Forever Warranty for peace of mind.</li>
<li className="ListItemStyle">Sharp out of the box, requiring minimal maintenance.</li>
<li className="ListItemStyle">Made in the USA, ensuring high manufacturing standards.</li>
<li className="ListItemStyle">Perfect balance between blade and handle for better control.</li>
<li className="ListItemStyle">Resistant to corrosion, ideal for different environmental conditions.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Relatively higher price point compared to other hunting knives.</li>
<li className="ListItemStyle">Phenolic handle may not be preferred by everyone.</li>
<li className="ListItemStyle">Leather sheath requires maintenance to prevent wear.</li>
<li className="ListItemStyle">Clip point blade design may not suit all cutting tasks.</li>
<li className="ListItemStyle">Limited to right-handed users with standard sheath configuration.</li>
<li className="ListItemStyle">Heavier than some other knives in the same category.</li>
<li className="ListItemStyle">Blade can be difficult to sharpen for inexperienced users.</li>
<li className="ListItemStyle">Does not offer a full tang, which may concern some users.</li>
<li className="ListItemStyle">The sheath can be bulky for some users' preferences.</li>
<li className="ListItemStyle">Not ideal for ultra-light backpacking due to weight.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default BuckKnives119Special;

