import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import GarmininReachMini2Pic from '../images/GarmininReachMini2.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import SatelliteCommunicatorsPic from '../../images/SatelliteCommunicators.jpg';


function GarmininReachMini2 () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Garmin inReach Mini 2</h1>
          <Link href="https://www.garmin.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ SatelliteCommunicatorsPic} style={{height:'120px'}} alt="SatelliteCommunicators"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Garmin inReach Mini 2" height="130px" src={GarmininReachMini2Pic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Garmin inReach Mini 2 is a compact, lightweight satellite communicator designed for outdoor adventurers. It offers two-way text messaging and SOS capabilities over the Iridium satellite network, ensuring global coverage. It also includes GPS navigation and location sharing features, making it ideal for remote expeditions where standard cell service is not available.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Compact and lightweight design, making it easy to carry on adventures.</li>
<li className="ListItemStyle">Reliable two-way texting via the Iridium satellite network.</li>
<li className="ListItemStyle">Emergency SOS feature with 24/7 monitoring through GEOS.</li>
<li className="ListItemStyle">Global satellite coverage ensures connectivity even in remote areas.</li>
<li className="ListItemStyle">TracBack routing to help users navigate back to their starting point.</li>
<li className="ListItemStyle">Location sharing allows friends and family to track your progress in real time.</li>
<li className="ListItemStyle">Up to 14 days of battery life in default 10-minute tracking mode.</li>
<li className="ListItemStyle">Compatible with the Garmin Earthmate App for additional mapping and communication features.</li>
<li className="ListItemStyle">Rugged and durable design with an IPX7 water resistance rating.</li>
<li className="ListItemStyle">Interfaces with other Garmin devices and apps for enhanced functionality.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Requires a subscription plan for satellite services, adding ongoing cost.</li>
<li className="ListItemStyle">Small screen size may be difficult to use for detailed navigation.</li>
<li className="ListItemStyle">Limited standalone mapping capabilities compared to larger GPS units.</li>
<li className="ListItemStyle">Typing messages on the mini interface can be cumbersome.</li>
<li className="ListItemStyle">No voice communication, only text-based messaging.</li>
<li className="ListItemStyle">Initial setup and syncing with devices can be complex for new users.</li>
<li className="ListItemStyle">Battery life significantly decreases with frequent use of advanced features.</li>
<li className="ListItemStyle">Signal availability may be obstructed by dense canopy or deep canyons.</li>
<li className="ListItemStyle">Higher price point compared to basic GPS units without satellite communication.</li>
<li className="ListItemStyle">Not ideal for users who prioritize large, detailed maps on a single device.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default GarmininReachMini2;

