import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import VortexOpticsViperHDPic from '../images/VortexOpticsViperHD.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import BinocularsPic from '../../images/Binoculars.jpg';


function VortexOpticsViperHD () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Vortex Optics Viper HD</h1>
          <Link href="https://vortexoptics.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ BinocularsPic} style={{height:'120px'}} alt="Binoculars"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Vortex Optics Viper HD" height="130px" src={VortexOpticsViperHDPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Vortex Optics Viper HD is a high-end binocular designed for outdoor use, offering exceptional clarity, color fidelity, and light transmission. Built with advanced HD extra-low dispersion glass and rugged construction, it caters to birdwatchers, hunters, and outdoor enthusiasts looking for reliability and high performance in various lighting conditions.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Superior image clarity with HD extra-low dispersion glass</li>
<li className="ListItemStyle">Rugged and durable construction for rough environments</li>
<li className="ListItemStyle">Wide field of view for expansive viewing</li>
<li className="ListItemStyle">Waterproof and fogproof for use in harsh weather conditions</li>
<li className="ListItemStyle">Lightweight and easy to carry for extended outings</li>
<li className="ListItemStyle">Comfortable eyecups that adjust to personal preferences</li>
<li className="ListItemStyle">Adjustable diopter for customized focus adjustment</li>
<li className="ListItemStyle">Excellent color fidelity with high-quality lens coatings</li>
<li className="ListItemStyle">Long eye relief providing comfort for eyeglass wearers</li>
<li className="ListItemStyle">Comes with a lifetime warranty providing peace of mind</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Higher price point compared to mid-range models</li>
<li className="ListItemStyle">Some users may find it less compact than other options</li>
<li className="ListItemStyle">Lower light performance may not match more expensive models</li>
<li className="ListItemStyle">Occasionally noted for a bit of edge softness</li>
<li className="ListItemStyle">Focus wheel might feel stiff to some users</li>
<li className="ListItemStyle">Limited depth of field compared to some competitors</li>
<li className="ListItemStyle">Lens caps can be prone to detachment</li>
<li className="ListItemStyle">May require additional stabilization for steady viewing</li>
<li className="ListItemStyle">Not as user-friendly for beginners due to various adjustments</li>
<li className="ListItemStyle">Accessories included may not meet all users' expectations</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default VortexOpticsViperHD;

