import * as React from 'react';
import Grid  from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
// import {useState, useEffect} from "react";
// import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';

import TrailCamsPic from '../images/TrailCams.jpg';

//import BrowningStrikeForceHDProXPic from './images/BrowningStrikeForceHDProX.png';
//import BushnellCoreDSNoGlowPic from './images/BushnellCoreDSNoGlow.png';
//import MoultrieA900iPic from './images/MoultrieA900i.png';
//import SpypointSolarDarkPic from './images/SpypointSolarDark.png';
//import StealthCamG42NGPic from './images/StealthCamG42NG.png';


function TrailCams () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const items = [
        {
      //manuLink:"[get link from company website]",
      //imageAlt:"Browning Strike Force HD Pro X",
      //imageRef:BrowningStrikeForceHDProXPic,
      id:"BrowningStrikeForceHDProX",
      itemLink:"../Electronics/TrailCams/BrowningStrikeForceHDProX",
      itemBoldText:"Browning Strike Force HD Pro X:",
      itemText:"The Browning Strike Force HD Pro X is a trail camera designed for wildlife monitoring and scouting. It boasts high-resolution imagery, a fast trigger speed, and an invisible infrared flash, which makes it suitable for both day and night use. The camera is compact, easy to set up, and offers advanced features like time-lapse and video capabilities."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Bushnell Core DS No Glow",
      //imageRef:BushnellCoreDSNoGlowPic,
      id:"BushnellCoreDSNoGlow",
      itemLink:"../Electronics/TrailCams/BushnellCoreDSNoGlow",
      itemBoldText:"Bushnell Core DS No Glow:",
      itemText:"The Bushnell Core DS No Glow is a high-performance trail camera designed for wildlife monitoring and security applications. Featuring dual sensors, it provides sharp image quality during the day and night. Its no-glow feature ensures discreet operation with infrared LEDs that do not emit a visible flash, making it ideal for capturing images of wildlife without startling them or for covert surveillance."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Moultrie A-900i",
      //imageRef:MoultrieA900iPic,
      id:"MoultrieA900i",
      itemLink:"../Electronics/TrailCams/MoultrieA900i",
      itemBoldText:"Moultrie A-900i:",
      itemText:"The Moultrie A-900i is a compact and popular trail camera designed for wildlife monitoring and security purposes. It features a 30-megapixel sensor, 0.4-second trigger speed, and invisible flash for nighttime captures, making it suitable for both day and night surveillance. The camera is easy to set up and offers long battery life, making it a reliable choice for outdoor enthusiasts."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Spypoint Solar Dark",
      //imageRef:SpypointSolarDarkPic,
      id:"SpypointSolarDark",
      itemLink:"../Electronics/TrailCams/SpypointSolarDark",
      itemBoldText:"Spypoint Solar Dark:",
      itemText:"The Spypoint Solar Dark is a trail camera primarily used for wildlife monitoring and hunting purposes. It features a solar panel for extended battery life, a fast trigger speed, and a low glow flash that reduces visible light to avoid spooking animals. The camera aims to provide consistent, reliable performance in outdoor environments."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Stealth Cam G42NG",
      //imageRef:StealthCamG42NGPic,
      id:"StealthCamG42NG",
      itemLink:"../Electronics/TrailCams/StealthCamG42NG",
      itemBoldText:"Stealth Cam G42NG:",
      itemText:"The Stealth Cam G42NG is a trail camera designed for wildlife and security monitoring. It features HD video recording, 10 megapixel photo resolution, and a no-glow infrared flash for capturing images at night without spooking game. The camera is capable of shooting in various settings including burst mode and time-lapse. It's known for its compact design and weather-resistant housing suited for outdoor use."
    },
	
    ];
/*
  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setMobileOpen(false);
        //console.log('setMobileOpen=false');
      } else {
        setMobileOpen(true);
        //console.log('setMobileOpen=true');
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>
   <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <Typography align="left" variant="body1" component={'span'}>

    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
	        <h1>Trail Cams</h1>
        </td>
        <td>
          <img className="RoundedCorners" src={TrailCamsPic} style={{height:'120px'}} alt="Trail Cams"></img>
        </td>
      </tr>
      </tbody>
    </table>


    Do you want to see what is going on at your hunting spot when you are not around? Trail cams can do just that. Whether you are trying to capture that big buck or just want to see what type of critters are roaming around, a trail cam is a must. Below are some great choices.

    </Typography>
    <br/>

    {items.map((element)=> (
          <div key={`${element.id}-div`}>
            <Paper key={`${element.id}-paper`} elevation={3}
              sx={{
                p: 2,
                margin: 'auto',
                flexGrow: 1,
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
              }}
            >
            <Grid container key={`${element.id}-container`} spacing={12} rowSpacing={1}>
              <Grid item key={`${element.id}-outer`} xs={12} sm container>
                <Grid item key={`${element.id}-column`} xs container direction="column" spacing={9}>
                  <Grid item key={`${element.id}-inner`} xs>
                    <Typography key={`${element.id}-t1`} gutterBottom variant="subtitle1" component="div">
                      <a href={element.itemLink}><b>{element.itemBoldText}</b></a> {element.itemText}
                    </Typography>
                    <Typography key={`${element.id}-t2`} align="center">
                      <Link key={`${element.id}-l1`} href={element.itemLink}>
                        <Button key={`${element.id}-b1`} variant="contained">Pros & Cons</Button>
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Paper>
          <br/>
      </div>
      ))}

    </div>
    </>
  );
}

export default TrailCams;

