import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import REICoopCampXChairPic from '../images/REICoopCampXChair.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import CampChairsPic from '../../images/CampChairs.jpg';


function REICoopCampXChair () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>REI Co-op Camp X Chair</h1>
          <Link href="https://www.rei.com/" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={CampChairsPic} style={{height:'120px'}} alt="Camp Chairs"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="REI Co-op Camp X Chair" height="130px" src={REICoopCampXChairPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The REI Co-op Camp X Chair is a portable and comfortable camping chair designed for outdoor enthusiasts. It features a sturdy steel frame, a breathable mesh seat, and a compact, foldable design for easy transport and storage. The chair is ideal for camping, picnics, and other outdoor activities.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Lightweight and portable, making it easy to carry.</li>
<li className="ListItemStyle">Durable steel frame provides stability and support.</li>
<li className="ListItemStyle">Breathable mesh fabric keeps you cool and comfortable.</li>
<li className="ListItemStyle">Compact foldable design for easy storage.</li>
<li className="ListItemStyle">Includes a cup holder for added convenience.</li>
<li className="ListItemStyle">Affordable price point compared to similar chairs.</li>
<li className="ListItemStyle">Quick and easy setup and takedown.</li>
<li className="ListItemStyle">Comes with a carry bag for transport.</li>
<li className="ListItemStyle">Versatile for various outdoor activities.</li>
<li className="ListItemStyle">Good weight capacity for most users.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">May not be as comfortable for extended sitting periods.</li>
<li className="ListItemStyle">Limited color options available.</li>
<li className="ListItemStyle">Cup holder may not fit larger bottles.</li>
<li className="ListItemStyle">Mesh fabric can be less durable over time.</li>
<li className="ListItemStyle">Seat height may be low for taller individuals.</li>
<li className="ListItemStyle">Armrests are not padded.</li>
<li className="ListItemStyle">Not suitable for very heavy individuals.</li>
<li className="ListItemStyle">Can be difficult to fold back into the carry bag.</li>
<li className="ListItemStyle">No adjustable features for personalized comfort.</li>
<li className="ListItemStyle">May not provide enough back support for some users.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default REICoopCampXChair;

