import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import ESEELaserStrikePic from '../images/ESEELaserStrike.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import HuntingKnivesPic from '../../images/HuntingKnives.jpg';


function ESEELaserStrike () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>ESEE Laser Strike</h1>
          <Link href="https://eseeknives.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ HuntingKnivesPic} style={{height:'120px'}} alt="Hunting Knives"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="ESEE Laser Strike" height="130px" src={ESEELaserStrikePic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The ESEE Laser Strike is a fixed-blade survival knife known for its durability, versatile design, and strong ergonomic grip, making it a favorite among outdoor enthusiasts and survivalists. It features a 4.75-inch high carbon steel blade with a black powder coating, combined with a removable Micarta handle. The knife also includes a fire-starting system and a durable Kydex sheath.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Durable high carbon steel blade.</li>
<li className="ListItemStyle">Versatile design suitable for various outdoor tasks.</li>
<li className="ListItemStyle">Ergonomic Micarta handle for a secure grip.</li>
<li className="ListItemStyle">Includes a fire-starting system for survival situations.</li>
<li className="ListItemStyle">Robust black powder coating enhances corrosion resistance.</li>
<li className="ListItemStyle">Full tang design adds to structural integrity.</li>
<li className="ListItemStyle">Comes with a high-quality Kydex sheath for protection.</li>
<li className="ListItemStyle">Blade is easy to sharpen in the field.</li>
<li className="ListItemStyle">Made by a reputable company known for quality survival gear.</li>
<li className="ListItemStyle">Backed by a lifetime warranty.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">High carbon steel requires regular maintenance to prevent rust.</li>
<li className="ListItemStyle">Heavier than some other survival knives.</li>
<li className="ListItemStyle">Price may be considered high for budget-conscious buyers.</li>
<li className="ListItemStyle">Micarta handle scales may get slippery when wet.</li>
<li className="ListItemStyle">Not ideal for detailed carving tasks due to blade thickness.</li>
<li className="ListItemStyle">Blade coating may wear off with extensive use.</li>
<li className="ListItemStyle">Requires practice to efficiently use the fire-starting system.</li>
<li className="ListItemStyle">Kydex sheath may be noisier than other materials.</li>
<li className="ListItemStyle">Some users might prefer a longer blade for specific tasks.</li>
<li className="ListItemStyle">Slightly larger profile can make carrying less convenient for some.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default ESEELaserStrike;

