import * as React from 'react';
import Grid  from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
// import {useState, useEffect} from "react";
// import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';

import SatelliteCommunicatorsPic from '../images/SatelliteCommunicators.jpg';

//import GarminGPSMAP66iPic from './images/GarminGPSMAP66i.png';
//import GarmininReachMini2Pic from './images/GarmininReachMini2.png';
//import SomewearGlobalHotspotPic from './images/SomewearGlobalHotspot.png';
//import SPOTXPic from './images/SPOTX.png';
//import ZOLEOSatelliteCommunicatorPic from './images/ZOLEOSatelliteCommunicator.png';


function SatelliteCommunicators () {
 // const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const items = [
        {
      //manuLink:"[get link from company website]",
      //imageAlt:"Garmin GPSMAP 66i",
      //imageRef:GarminGPSMAP66iPic,
      id:"GarminGPSMAP66i",
      itemLink:"../Electronics/SatelliteCommunicators/GarminGPSMAP66i",
      itemBoldText:"Garmin GPSMAP 66i:",
      itemText:"The Garmin GPSMAP 66i is a rugged, handheld GPS and satellite communicator designed for outdoor enthusiasts and professionals. It combines reliable topographic navigation with inReach satellite communication technology for real-time location sharing and SOS emergency functionality. The device features a 3-inch color display, preloaded maps, and weather forecasts, making it suitable for hiking, camping, and other remote adventures."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Garmin inReach Mini 2",
      //imageRef:GarmininReachMini2Pic,
      id:"GarmininReachMini2",
      itemLink:"../Electronics/SatelliteCommunicators/GarmininReachMini2",
      itemBoldText:"Garmin inReach Mini 2:",
      itemText:"The Garmin inReach Mini 2 is a compact, lightweight satellite communicator designed for outdoor adventurers. It offers two-way text messaging and SOS capabilities over the Iridium satellite network, ensuring global coverage. It also includes GPS navigation and location sharing features, making it ideal for remote expeditions where standard cell service is not available."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Somewear Global Hotspot",
      //imageRef:SomewearGlobalHotspotPic,
      id:"SomewearGlobalHotspot",
      itemLink:"../Electronics/SatelliteCommunicators/SomewearGlobalHotspot",
      itemBoldText:"Somewear Global Hotspot:",
      itemText:"The Somewear Global Hotspot is a compact and durable satellite communication device designed to provide reliable connectivity and essential location-based services in remote areas. It integrates with smartphones via Bluetooth, enabling users to send and receive messages, track location, access weather updates, and call for emergency help worldwide, all without relying on cellular networks."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"SPOT X",
      //imageRef:SPOTXPic,
      id:"SPOTX",
      itemLink:"../Electronics/SatelliteCommunicators/SPOTX",
      itemBoldText:"SPOT X:",
      itemText:"The SPOT X is a satellite messenger device designed for outdoor enthusiasts and professionals who require reliable communication when off-the-grid. It offers two-way messaging, GPS tracking, an SOS feature for emergencies, and a backlit keyboard for easy typing. The device connects directly to satellites, ensuring communication where cell service is unavailable."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"ZOLEO Satellite Communicator",
      //imageRef:ZOLEOSatelliteCommunicatorPic,
      id:"ZOLEOSatelliteCommunicator",
      itemLink:"../Electronics/SatelliteCommunicators/ZOLEOSatelliteCommunicator",
      itemBoldText:"ZOLEO Satellite Communicator:",
      itemText:"The ZOLEO Satellite Communicator is a rugged and versatile device designed for reliable communication beyond cellular coverage. It connects seamlessly with a smartphone or tablet via the ZOLEO app, allowing users to send and receive messages, track locations, and send SOS alerts worldwide through the Iridium satellite network."
    },
	
    ];
/*
  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setMobileOpen(false);
        //console.log('setMobileOpen=false');
      } else {
        setMobileOpen(true);
        //console.log('setMobileOpen=true');
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>
   <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <Typography align="left" variant="body1" component={'span'}>

    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
	        <h1>Satellite Communicators</h1>
        </td>
        <td>
          <img className="RoundedCorners" src={SatelliteCommunicatorsPic} style={{height:'120px'}} alt="Satellite Communicators"></img>
        </td>
      </tr>
      </tbody>
    </table>


    If you get far off the beaten path, you likely won’t have cell service where you are. Having a satellite communicator with you can provide that link to friends and family, or emergency services, when needed. I have heard many stories of people being in the woods by themselves and getting hurt, without any way to communicate with the outside world. A satellite communicator can bring peace of mind to family members when you are deep in the outdoors. Below are some terrific options to have with you.

    </Typography>
    <br/>

    {items.map((element)=> (
          <div key={`${element.id}-div`}>
            <Paper key={`${element.id}-paper`} elevation={3}
              sx={{
                p: 2,
                margin: 'auto',
                flexGrow: 1,
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
              }}
            >
            <Grid container key={`${element.id}-container`} spacing={12} rowSpacing={1}>
              <Grid item key={`${element.id}-outer`} xs={12} sm container>
                <Grid item key={`${element.id}-column`} xs container direction="column" spacing={9}>
                  <Grid item key={`${element.id}-inner`} xs>
                    <Typography key={`${element.id}-t1`} gutterBottom variant="subtitle1" component="div">
                      <a href={element.itemLink}><b>{element.itemBoldText}</b></a> {element.itemText}
                    </Typography>
                    <Typography key={`${element.id}-t2`} align="center">
                      <Link key={`${element.id}-l1`} href={element.itemLink}>
                        <Button key={`${element.id}-b1`} variant="contained">Pros & Cons</Button>
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Paper>
          <br/>
      </div>
      ))}

    </div>
    </>
  );
}

export default SatelliteCommunicators;

