import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import JetboilFlashCookingSystemPic from '../images/JetboilFlashCookingSystem.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import CampingStovesPic from '../../images/CampingStoves.jpg';


function JetboilFlashCookingSystem () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Jetboil Flash Cooking System</h1>
          <Link href="https://www.jetboil.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ CampingStovesPic} style={{height:'120px'}} alt="CampingStoves"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Jetboil Flash Cooking System" height="130px" src={JetboilFlashCookingSystemPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Jetboil Flash Cooking System is a compact and efficient personal cooking system designed for outdoor enthusiasts. It features a fast boiling time, integrated burner and cooking cup, and a push-button igniter, making it ideal for quick meals and hot drinks on the go.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Fast boiling time, typically around 100 seconds for 0.5 liters of water.</li>
<li className="ListItemStyle">Compact and lightweight, making it easy to carry on hikes and camping trips.</li>
<li className="ListItemStyle">Integrated cooking cup and burner for convenience and efficiency.</li>
<li className="ListItemStyle">Push-button igniter for easy and reliable ignition.</li>
<li className="ListItemStyle">Fuel canister stabilizer included for added stability during cooking.</li>
<li className="ListItemStyle">Insulating cozy with a color-change heat indicator to show when the water is hot.</li>
<li className="ListItemStyle">Compatible with Jetboil accessories for versatility.</li>
<li className="ListItemStyle">Efficient fuel usage, allowing for longer use with less fuel.</li>
<li className="ListItemStyle">Easy to assemble and disassemble for quick setup and packing.</li>
<li className="ListItemStyle">Durable construction suitable for outdoor conditions.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Limited cooking capacity, primarily suitable for boiling water and simple meals.</li>
<li className="ListItemStyle">Not ideal for cooking complex meals or for groups.</li>
<li className="ListItemStyle">Requires specific Jetboil fuel canisters, which may not be available everywhere.</li>
<li className="ListItemStyle">The integrated system limits the use of other pots and pans.</li>
<li className="ListItemStyle">Can be unstable on uneven surfaces despite the stabilizer.</li>
<li className="ListItemStyle">The igniter can be unreliable in very windy or wet conditions.</li>
<li className="ListItemStyle">The cozy can become worn over time with heavy use.</li>
<li className="ListItemStyle">Higher price point compared to some other portable stoves.</li>
<li className="ListItemStyle">Not suitable for simmering or low-heat cooking.</li>
<li className="ListItemStyle">The plastic components can be prone to damage if not handled carefully.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default JetboilFlashCookingSystem;

