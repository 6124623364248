import * as React from 'react';
import Grid  from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import {useState, useEffect} from "react";
//import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';

import CampChairsPic from '../images/CampChairs.jpg';

//import ALPSMountaineeringKingKongChairPic from './images/ALPSMountaineeringKingKongChair.png';
//import ColemanPortableCampingQuadChairwith4CanCoolerPic from './images/ColemanPortableCampingQuadChairwith4CanCooler.png';
//import HelinoxChairOnePic from './images/HelinoxChairOne.png';
//import KijaroDualLockPortableCampingandSportsChairPic from './images/KijaroDualLockPortableCampingandSportsChair.png';
//import REICoopCampXChairPic from './images/REICoopCampXChair.png';


function CampChairs () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const items = [
        {
      //manuLink:"[get link from company website]",
      //imageAlt:"ALPS Mountaineering King Kong Chair",
      //imageRef:ALPSMountaineeringKingKongChairPic,
      id:"ALPSMountaineeringKingKongChair",
      itemLink:"../Camping/CampChairs/ALPSMountaineeringKingKongChair",
      itemBoldText:"ALPS Mountaineering King Kong Chair:",
      itemText:"The ALPS Mountaineering King Kong Chair is a durable and comfortable folding chair designed for outdoor use. It features a sturdy steel frame, a high weight capacity, and padded armrests for added comfort. The chair also includes convenient storage options with cup holders and side pockets, making it ideal for camping, tailgating, or any outdoor event."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Coleman Portable Camping Quad Chair with 4-Can Cooler",
      //imageRef:ColemanPortableCampingQuadChairwith4CanCoolerPic,
      id:"ColemanPortableCampingQuadChairwith4CanCooler",
      itemLink:"../Camping/CampChairs/ColemanPortableCampingQuadChairwith4CanCooler",
      itemBoldText:"Coleman Portable Camping Quad Chair with 4-Can Cooler:",
      itemText:"The Coleman Portable Camping Quad Chair with 4-Can Cooler is a versatile and comfortable folding chair designed for outdoor activities such as camping, tailgating, and picnics. It features a built-in cooler pouch that can hold up to four cans, a mesh cup holder, a storage pocket, and a cushioned seat and backrest for enhanced comfort. The chair is made with a sturdy steel frame and durable fabric, supporting up to 325 pounds. It is easy to fold and transport, making it a convenient choice for outdoor enthusiasts."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Helinox Chair One",
      //imageRef:HelinoxChairOnePic,
      id:"HelinoxChairOne",
      itemLink:"../Camping/CampChairs/HelinoxChairOne",
      itemBoldText:"Helinox Chair One:",
      itemText:"The Helinox Chair One is a lightweight, portable camping chair designed for outdoor enthusiasts. It features a compact design that is easy to assemble and disassemble, making it ideal for camping, hiking, and other outdoor activities. The chair is made with durable materials and offers a comfortable seating experience despite its minimalistic design."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Kijaro Dual Lock Portable Camping and Sports Chair",
      //imageRef:KijaroDualLockPortableCampingandSportsChairPic,
      id:"KijaroDualLockPortableCampingandSportsChair",
      itemLink:"../Camping/CampChairs/KijaroDualLockPortableCampingandSportsChair",
      itemBoldText:"Kijaro Dual Lock Portable Camping and Sports Chair:",
      itemText:"The Kijaro Dual Lock Portable Camping and Sports Chair is a versatile and comfortable folding chair designed for outdoor activities such as camping, sports events, and picnics. It features a dual lock system for extra stability, a durable steel frame, breathable mesh fabric, and convenient accessories like cup holders and an organizer with zip and mesh pockets."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"REI Co-op Camp X Chair",
      //imageRef:REICoopCampXChairPic,
      id:"REICoopCampXChair",
      itemLink:"../Camping/CampChairs/REICoopCampXChair",
      itemBoldText:"REI Co-op Camp X Chair:",
      itemText:"The REI Co-op Camp X Chair is a portable and comfortable camping chair designed for outdoor enthusiasts. It features a sturdy steel frame, a breathable mesh seat, and a compact, foldable design for easy transport and storage. The chair is ideal for camping, picnics, and other outdoor activities."
    },
	
    ];
/*
  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setMobileOpen(false);
        //console.log('setMobileOpen=false');
      } else {
        setMobileOpen(true);
        //console.log('setMobileOpen=true');
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (

   <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
        <Typography align="left" variant="body1" component={'span'} >

        <table>
          <tbody>
          <tr>
            <td style={{width:'95%'}}>
              <h1>Camp Chairs</h1>
            </td>
            <td>
              <img className="RoundedCorners" src={CampChairsPic} style={{height:'120px'}} alt="Camp Chairs"></img>
            </td>
          </tr>
          </tbody>
        </table>


        Very few things are as peaceful as sitting around a campfire in your favorite chair with a group of friends. Here are some camp chairs that can help add to that experience.

        </Typography>
        <br/>

        {items.map((element)=> (
          <div key={`${element.id}-div`}>
            <Paper key={`${element.id}-paper`} elevation={3}
              sx={{
                p: 2,
                margin: 'auto',
                flexGrow: 1,
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
              }}
            >
            <Grid container key={`${element.id}-container`} spacing={12} rowSpacing={1}>
              <Grid item key={`${element.id}-outer`} xs={12} sm container>
                <Grid item key={`${element.id}-column`} xs container direction="column" spacing={9}>
                  <Grid item key={`${element.id}-inner`} xs>
                    <Typography key={`${element.id}-t1`} gutterBottom variant="subtitle1" component="div">
                      <a href={element.itemLink}><b>{element.itemBoldText}</b></a> {element.itemText}
                    </Typography>
                    <Typography key={`${element.id}-t2`} align="center">
                      <Link key={`${element.id}-l1`} href={element.itemLink}>
                        <Button key={`${element.id}-b1`} variant="contained">Pros & Cons</Button>
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Paper>
          <br/>
      </div>
      ))}

    </div>

  );
}

export default CampChairs;

