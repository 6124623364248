import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import MarmotTrestlesEliteEcoPic from '../images/MarmotTrestlesEliteEco.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import SleepingBagsPic from '../../images/SleepingBags.jpg';


function MarmotTrestlesEliteEco () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Marmot Trestles Elite Eco</h1>
          <Link href="https://www.marmot.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ SleepingBagsPic} style={{height:'120px'}} alt="SleepingBags"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Marmot Trestles Elite Eco" height="130px" src={MarmotTrestlesEliteEcoPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Marmot Trestles Elite Eco is a synthetic sleeping bag that balances eco-friendliness with performance. It's designed for backpackers and campers who demand a reliable and versatile sleeping bag that performs well in various conditions. It features an anatomically shaped footbox, fold-down second zipper for easy access and ventilation, a full-length locking YKK two-way zipper with anti-snag slider, and 3D hood construction. The synthetic insulation is made from recycled materials, making it an environmentally conscious choice.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Eco-friendly synthetic insulation made from recycled materials.</li>
<li className="ListItemStyle">Comfortable and warm in varied weather conditions.</li>
<li className="ListItemStyle">Lightweight construction suitable for backpacking.</li>
<li className="ListItemStyle">Includes a practical compression stuff sack for easy packing.</li>
<li className="ListItemStyle">Durable and robust shell material.</li>
<li className="ListItemStyle">Features an anatomically shaped footbox for added comfort.</li>
<li className="ListItemStyle">Two-way zipper allows for easy ventilation and access.</li>
<li className="ListItemStyle">Hood is easily adjustable for maximum warmth.</li>
<li className="ListItemStyle">Great balance of price and performance.</li>
<li className="ListItemStyle">Versatile for multiple camping and backpacking uses.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">May be bulkier compared to down sleeping bags when packed.</li>
<li className="ListItemStyle">Slightly heavier than some ultralight options.</li>
<li className="ListItemStyle">Limited in color options.</li>
<li className="ListItemStyle">The zipper can snag if not handled carefully.</li>
<li className="ListItemStyle">Temperature rating might not be sufficient for extreme cold conditions.</li>
<li className="ListItemStyle">Limited length options might not suit very tall users.</li>
<li className="ListItemStyle">Might feel less breathable in high-temperature weather.</li>
<li className="ListItemStyle">Synthetic fill may compress over time reducing insulation.</li>
<li className="ListItemStyle">Potentially less compact compared to premium models.</li>
<li className="ListItemStyle">Not suitable for those looking for minimalistic features.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default MarmotTrestlesEliteEco;

