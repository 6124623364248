import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import ExotacnanoSTRIKERXLPic from '../images/ExotacnanoSTRIKERXL.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import FireStartersPic from '../../images/FireStarters.jpg';


function ExotacnanoSTRIKERXL () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Exotac nanoSTRIKER XL</h1>
          <Link href="https://exotac.com/products/nanostriker-xl" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ FireStartersPic} style={{height:'120px'}} alt="FireStarters"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Exotac nanoSTRIKER XL" height="130px" src={ExotacnanoSTRIKERXLPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Exotac nanoSTRIKER XL is a compact, high-quality firestarter designed for outdoor enthusiasts and survivalists. It features an all-in-one design with a replaceable ferrocerium rod and a tungsten carbide striker, housed in a durable, waterproof aluminum body. The nanoSTRIKER XL is designed to be lightweight and portable, making it easy to add to any camping or emergency kit.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Compact and lightweight design makes it easy to carry.</li>
<li className="ListItemStyle">Durable aluminum body withstands harsh outdoor conditions.</li>
<li className="ListItemStyle">Waterproof construction ensures reliability in wet environments.</li>
<li className="ListItemStyle">Replaceable ferrocerium rod extends the lifespan of the tool.</li>
<li className="ListItemStyle">High-quality tungsten carbide striker provides efficient spark production.</li>
<li className="ListItemStyle">Integrated lanyard loop for easy attachment to gear or keychains.</li>
<li className="ListItemStyle">Produces a large number of sparks, facilitating quick fire-starting.</li>
<li className="ListItemStyle">Precision engineering ensures consistent performance.</li>
<li className="ListItemStyle">Strong build quality ensures long-term use.</li>
<li className="ListItemStyle">Designed with outdoor enthusiasts in mind.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Higher price point compared to some other firestarters.</li>
<li className="ListItemStyle">Small size may make it easy to misplace.</li>
<li className="ListItemStyle">Requires technique to use effectively, which may need practice.</li>
<li className="ListItemStyle">Limited to use as a firestarter only.</li>
<li className="ListItemStyle">Replacement rods need to be purchased separately.</li>
<li className="ListItemStyle">Not as effective in extremely windy conditions without some shielding.</li>
<li className="ListItemStyle">Initial setup and handling could be awkward for first-time users.</li>
<li className="ListItemStyle">May not be ideal for large group or multi-fire needs due to its size.</li>
<li className="ListItemStyle">Needs regular maintenance to ensure optimal performance.</li>
<li className="ListItemStyle">Lanyard loop can make it cumbersome if not attached properly.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default ExotacnanoSTRIKERXL;

