import * as React from 'react';
import Grid  from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
// import {useState, useEffect} from "react";
// import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';

import _2WayRadiosPic from '../images/2WayRadios.jpg';

//import BaoFengBFF8HPPic from './images/BaoFengBFF8HP.png';
//import MidlandGXT1000VP4Pic from './images/MidlandGXT1000VP4.png';
//import MotorolaMS350RPic from './images/MotorolaMS350R.png';
//import MotorolaT600H2OPic from './images/MotorolaT600H2O.png';
//import UnidenSX5072CKHSPic from './images/UnidenSX5072CKHS.png';


function _2WayRadios () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const items = [
        {
      //manuLink:"[get link from company website]",
      //imageAlt:"BaoFeng BF-F8HP",
      //imageRef:BaoFengBFF8HPPic,
      id:"BaoFengBFF8HP",
      itemLink:"../Electronics/2WayRadios/BaoFengBFF8HP",
      itemBoldText:"BaoFeng BF-F8HP:",
      itemText:"The BaoFeng BF-F8HP is a high-power handheld dual band two way radio. It's an upgrade from the popular UV-5R, featuring an upgraded transmission power of 8 watts, improved receiver, and enhanced battery life. It is favored by amateur radio enthusiasts for its affordability, durability, and versatility in operations across various frequency ranges."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Midland GXT1000VP4",
      //imageRef:MidlandGXT1000VP4Pic,
      id:"MidlandGXT1000VP4",
      itemLink:"../Electronics/2WayRadios/MidlandGXT1000VP4",
      itemBoldText:"Midland GXT1000VP4:",
      itemText:"The Midland GXT1000VP4 is a popular two-way radio designed for long-range communication, making it ideal for outdoor activities like hiking, camping, and hunting. It boasts features such as NOAA weather alerts, 50 channels, and a robust build suitable for rugged conditions. The device comes with eVOX for hands-free operation and offers a reliable range with various privacy codes for secure communication."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Motorola MS350R",
      //imageRef:MotorolaMS350RPic,
      id:"MotorolaMS350R",
      itemLink:"../Electronics/2WayRadios/MotorolaMS350R",
      itemBoldText:"Motorola MS350R:",
      itemText:"The Motorola MS350R is a two-way radio designed for outdoor enthusiasts and professionals who require reliable communication in rugged conditions. It offers a range of up to 35 miles under optimal conditions and is equipped with numerous features such as NOAA weather alerts, waterproof design, and multiple channels."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Motorola T600 H2O",
      //imageRef:MotorolaT600H2OPic,
      id:"MotorolaT600H2O",
      itemLink:"../Electronics/2WayRadios/MotorolaT600H2O",
      itemBoldText:"Motorola T600 H2O:",
      itemText:"The Motorola T600 H2O is a durable and water-resistant two-way radio designed for outdoor enthusiasts. It is equipped with a range of up to 35 miles in ideal conditions, and features 22 channels, including weather channels to keep users updated on the latest forecasts. The device is built to float in water, making it ideal for boating and water activities. It also comes with a built-in flashlight with both white and red LEDs for visibility in the dark."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Uniden SX507-2CKHS",
      //imageRef:UnidenSX5072CKHSPic,
      id:"UnidenSX5072CKHS",
      itemLink:"../Electronics/2WayRadios/UnidenSX5072CKHS",
      itemBoldText:"Uniden SX507-2CKHS:",
      itemText:"The Uniden SX507-2CKHS is a set of long-range FRS two-way radios designed for outdoor adventures, offering up to 50 miles of communication range in optimal conditions. It features 22 channels and 121 privacy codes to minimize interference, NOAA weather alerts for safety, and is both waterproof and rugged against tough conditions. Additionally, it comes with rechargeable batteries and a hands-free operation mode."
    },
	
    ];
/*
  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setMobileOpen(false);
        //console.log('setMobileOpen=false');
      } else {
        setMobileOpen(true);
        //console.log('setMobileOpen=true');
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>
   <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <Typography align="left" variant="body1" component={'span'}>

    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
	        <h1>2-Way Radios</h1>
        </td>
        <td>
          <img className="RoundedCorners" src={_2WayRadiosPic} style={{height:'120px'}} alt="2-Way Radios"></img>
        </td>
      </tr>
      </tbody>
    </table>


    2-way radios are a great tool to stay connected with your buddies when you are out having fun in the outdoors. Whether you are letting them know where you are, or if you need help with something, it is great to press a button to talk instead of needing to track them down. Below are some great options for you to consider for your next 2-way radio.

    </Typography>
    <br/>

    {items.map((element)=> (
          <div key={`${element.id}-div`}>
            <Paper key={`${element.id}-paper`} elevation={3}
              sx={{
                p: 2,
                margin: 'auto',
                flexGrow: 1,
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
              }}
            >
            <Grid container key={`${element.id}-container`} spacing={12} rowSpacing={1}>
              <Grid item key={`${element.id}-outer`} xs={12} sm container>
                <Grid item key={`${element.id}-column`} xs container direction="column" spacing={9}>
                  <Grid item key={`${element.id}-inner`} xs>
                    <Typography key={`${element.id}-t1`} gutterBottom variant="subtitle1" component="div">
                      <a href={element.itemLink}><b>{element.itemBoldText}</b></a> {element.itemText}
                    </Typography>
                    <Typography key={`${element.id}-t2`} align="center">
                      <Link key={`${element.id}-l1`} href={element.itemLink}>
                        <Button key={`${element.id}-b1`} variant="contained">Pros & Cons</Button>
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Paper>
          <br/>
      </div>
      ))}

    </div>
    </>
  );
}

export default _2WayRadios;

