import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import EberlestockX2PackPic from '../images/EberlestockX2Pack.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import HuntingPacksPic from '../../images/HuntingPacks.jpg';


function EberlestockX2Pack () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Eberlestock X2 Pack</h1>
          <Link href="https://eberlestock.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ HuntingPacksPic} style={{height:'120px'}} alt="Hunting Packs"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Eberlestock X2 Pack" height="130px" src={EberlestockX2PackPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Eberlestock X2 Pack is a versatile and durable backpack designed for outdoor enthusiasts, particularly hunters. It offers ample storage, excellent organization features, and is known for its rugged build quality. With an emphasis on carrying capacity and comfort, the X2 Pack accommodates a variety of equipment and accessories, making it ideal for extended trips in various terrains.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Compact and functional design suitable for hunters and hikers</li>
<li className="ListItemStyle">Durable materials that withstand harsh conditions</li>
<li className="ListItemStyle">Versatile storage options with numerous pockets and compartments</li>
<li className="ListItemStyle">Ample carrying capacity for gear and essentials</li>
<li className="ListItemStyle">Compatible with hydration systems for convenience</li>
<li className="ListItemStyle">Comfortable padded straps and suspension system</li>
<li className="ListItemStyle">Customizable with external attachment points and webbing</li>
<li className="ListItemStyle">Suitable for various outdoor activities and environments</li>
<li className="ListItemStyle">Efficient organization for easy access to gear</li>
<li className="ListItemStyle">Built-in rifle/bow carrier for hunting trips</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">May feel heavy when fully loaded</li>
<li className="ListItemStyle">Limited internal space for bulky items</li>
<li className="ListItemStyle">Higher price point compared to some competitors</li>
<li className="ListItemStyle">Might not fit all body types comfortably</li>
<li className="ListItemStyle">Front pockets can be difficult to access while wearing</li>
<li className="ListItemStyle">No rain cover included</li>
<li className="ListItemStyle">Some users find the zippers less than smooth</li>
<li className="ListItemStyle">Can be overkill for short trips or light loads</li>
<li className="ListItemStyle">Limited color options available</li>
<li className="ListItemStyle">Straps may require frequent adjustments for optimal fit</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default EberlestockX2Pack;

