import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import KeltyCosmicDownPic from '../images/KeltyCosmicDown.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import SleepingBagsPic from '../../images/SleepingBags.jpg';


function KeltyCosmicDown () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Kelty Cosmic Down</h1>
          <Link href="https://www.kelty.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ SleepingBagsPic} style={{height:'120px'}} alt="SleepingBags"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Kelty Cosmic Down" height="130px" src={KeltyCosmicDownPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Kelty Cosmic Down sleeping bag is a lightweight, compressible, and affordable sleeping bag designed for backpackers and campers. It features water-resistant down insulation, a draft collar, and a durable construction suited for three-season use.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Lightweight and compressible, making it easy to pack.</li>
<li className="ListItemStyle">Affordable price point for a down sleeping bag.</li>
<li className="ListItemStyle">Water-resistant down insulation for better performance in damp conditions.</li>
<li className="ListItemStyle">Durable construction suitable for long-term use.</li>
<li className="ListItemStyle">Comfortable design with a roomy footbox.</li>
<li className="ListItemStyle">Draft collar to prevent heat loss.</li>
<li className="ListItemStyle">Available in different temperature ratings to suit various conditions.</li>
<li className="ListItemStyle">Zipper draft tube minimizes cold air entering through the zipper.</li>
<li className="ListItemStyle">PFC-free water-repellent finish, healthier for the environment.</li>
<li className="ListItemStyle">Comes with a stuff sack for easy storage and transport.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Not as warm as higher-end down sleeping bags.</li>
<li className="ListItemStyle">May not be suitable for extreme cold weather conditions.</li>
<li className="ListItemStyle">Zipper can snag occasionally during use.</li>
<li className="ListItemStyle">Bulkier than some premium models when packed.</li>
<li className="ListItemStyle">Limited color options available.</li>
<li className="ListItemStyle">Some users may find the fit too snug.</li>
<li className="ListItemStyle">The hood design could be more ergonomic.</li>
<li className="ListItemStyle">Down insulation may shift, reducing warmth in some areas.</li>
<li className="ListItemStyle">Requires gentle care and maintenance to preserve loft.</li>
<li className="ListItemStyle">The interior fabric may feel less soft compared to more expensive bags.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default KeltyCosmicDown;

