import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import StealthCamG42NGPic from '../images/StealthCamG42NG.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import TrailCamsPic from '../../images/TrailCams.jpg';


function StealthCamG42NG () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Stealth Cam G42NG</h1>
          <Link href="https://www.stealthcam.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ TrailCamsPic} style={{height:'120px'}} alt="TrailCams"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Stealth Cam G42NG" height="130px" src={StealthCamG42NGPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Stealth Cam G42NG is a trail camera designed for wildlife and security monitoring. It features HD video recording, 10 megapixel photo resolution, and a no-glow infrared flash for capturing images at night without spooking game. The camera is capable of shooting in various settings including burst mode and time-lapse. It's known for its compact design and weather-resistant housing suited for outdoor use.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">High-quality 10 megapixel images</li>
<li className="ListItemStyle">No-glow infrared flash for unnoticed night shots</li>
<li className="ListItemStyle">Records HD video with audio</li>
<li className="ListItemStyle">Multiple image capturing modes including time-lapse and burst</li>
<li className="ListItemStyle">Durable, weather-resistant housing</li>
<li className="ListItemStyle">Compact size for easy concealment</li>
<li className="ListItemStyle">Long battery life</li>
<li className="ListItemStyle">Fast trigger speed</li>
<li className="ListItemStyle">User-friendly interface</li>
<li className="ListItemStyle">Affordable price point compared to competitors</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">No Wi-Fi or Bluetooth connectivity</li>
<li className="ListItemStyle">Limited internal storage, requires additional SD cards</li>
<li className="ListItemStyle">Setup and programming can be complex for some users</li>
<li className="ListItemStyle">Night shots can be slightly noisier than day shots</li>
<li className="ListItemStyle">Low-quality LCD screen for viewing images on the device</li>
<li className="ListItemStyle">No rechargeable battery option</li>
<li className="ListItemStyle">Trigger range may be shorter in cold weather</li>
<li className="ListItemStyle">Video length is limited</li>
<li className="ListItemStyle">No real-time photo transmission</li>
<li className="ListItemStyle">Manual is not very detailed, making initial setup challenging</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default StealthCamG42NG;

