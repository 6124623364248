import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import BushnellCoreDSNoGlowPic from '../images/BushnellCoreDSNoGlow.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import TrailCamsPic from '../../images/TrailCams.jpg';


function BushnellCoreDSNoGlow () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Bushnell Core DS No Glow</h1>
          <Link href="https://www.bushnell.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ TrailCamsPic} style={{height:'120px'}} alt="TrailCams"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Bushnell Core DS No Glow" height="130px" src={BushnellCoreDSNoGlowPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Bushnell Core DS No Glow is a high-performance trail camera designed for wildlife monitoring and security applications. Featuring dual sensors, it provides sharp image quality during the day and night. Its no-glow feature ensures discreet operation with infrared LEDs that do not emit a visible flash, making it ideal for capturing images of wildlife without startling them or for covert surveillance.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Dual sensor technology for enhanced image quality</li>
<li className="ListItemStyle">No-glow infrared LEDs for discreet imaging</li>
<li className="ListItemStyle">High-resolution images and videos</li>
<li className="ListItemStyle">Reliable battery life suitable for extended use</li>
<li className="ListItemStyle">Sturdy and durable construction</li>
<li className="ListItemStyle">Fast trigger speed for capturing quick movements</li>
<li className="ListItemStyle">Easy setup and user-friendly interface</li>
<li className="ListItemStyle">Effective night vision capabilities</li>
<li className="ListItemStyle">Versatile use for wildlife and security</li>
<li className="ListItemStyle">Wide detection range and field of view</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Higher price point compared to basic models</li>
<li className="ListItemStyle">Limited connectivity options (no Wi-Fi or Bluetooth)</li>
<li className="ListItemStyle">Not weatherproof without additional housing</li>
<li className="ListItemStyle">Average recovery time between triggers</li>
<li className="ListItemStyle">Requires frequent memory card swaps for heavy use</li>
<li className="ListItemStyle">Certain settings might confuse beginners</li>
<li className="ListItemStyle">Lacks real-time monitoring feature</li>
<li className="ListItemStyle">Video resolution quality could be improved</li>
<li className="ListItemStyle">Bulky compared to some compact models</li>
<li className="ListItemStyle">Limited customization for image and video settings</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default BushnellCoreDSNoGlow;

