import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import BioliteCampStove2Pic from '../images/BioliteCampStove2.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import CampingStovesPic from '../../images/CampingStoves.jpg';


function BioliteCampStove2 () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Biolite CampStove 2+</h1>
          <Link href="https://www.bioliteenergy.com/products/campstove-2-plus" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ CampingStovesPic} style={{height:'120px'}} alt="CampingStoves"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Biolite CampStove 2+" height="130px" src={BioliteCampStove2Pic}/>  */}

    <Typography align="left" variant="body1">
        The Biolite CampStove 2+ is a portable camping stove that uses wood as fuel and generates electricity to charge devices via a USB port. It features an integrated battery, a fan for improved combustion, and a lightweight design, making it ideal for outdoor enthusiasts who want to cook meals and charge devices while camping.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Generates electricity to charge devices while cooking.</li>
<li className="ListItemStyle">Uses renewable wood fuel, reducing the need for gas canisters.</li>
<li className="ListItemStyle">Integrated battery stores power for later use.</li>
<li className="ListItemStyle">Lightweight and portable, easy to carry on camping trips.</li>
<li className="ListItemStyle">Fan-assisted combustion improves efficiency and reduces smoke.</li>
<li className="ListItemStyle">Can boil water quickly, making it practical for cooking.</li>
<li className="ListItemStyle">Durable construction suitable for outdoor use.</li>
<li className="ListItemStyle">Comes with a flexible USB light for convenience.</li>
<li className="ListItemStyle">Eco-friendly design reduces reliance on fossil fuels.</li>
<li className="ListItemStyle">Compatible with a range of Biolite accessories for versatile cooking.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Requires dry wood or biomass, which may not always be available.</li>
<li className="ListItemStyle">Higher initial cost compared to traditional camping stoves.</li>
<li className="ListItemStyle">Learning curve to efficiently manage the fire and fuel.</li>
<li className="ListItemStyle">Limited power output for charging larger devices.</li>
<li className="ListItemStyle">Fan noise can be noticeable during operation.</li>
<li className="ListItemStyle">May require frequent refueling during extended cooking sessions.</li>
<li className="ListItemStyle">Not ideal for use in areas with fire restrictions.</li>
<li className="ListItemStyle">Performance can be affected by wet or windy conditions.</li>
<li className="ListItemStyle">Charging speed is slower compared to dedicated power banks.</li>
<li className="ListItemStyle">Requires maintenance to keep the fan and battery in good condition.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default BioliteCampStove2;

