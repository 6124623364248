import * as React from 'react';
import Grid  from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
// import {useState, useEffect} from "react";
// import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';

import BinocularsPic from '../images/Binoculars.jpg';

//import CelestronNatureDXPic from './images/CelestronNatureDX.png';
//import NikonMonarch5Pic from './images/NikonMonarch5.png';
//import SwarovskiELPic from './images/SwarovskiEL.png';
//import VortexOpticsViperHDPic from './images/VortexOpticsViperHD.png';
//import ZeissTerraEDPic from './images/ZeissTerraED.png';


function Binoculars () {
 // const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const items = [
        {
      //manuLink:"[get link from company website]",
      //imageAlt:"Celestron Nature DX",
      //imageRef:CelestronNatureDXPic,
      id:"CelestronNatureDX",
      itemLink:"../Optics/Binoculars/CelestronNatureDX",
      itemBoldText:"Celestron Nature DX:",
      itemText:"The Celestron Nature DX is a versatile binocular designed for outdoor enthusiasts. Built with quality optics, it offers excellent performance for birdwatching, hiking, and other nature activities. It features a robust and waterproof design and comes in a range of magnifications to cater to different viewing needs."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Nikon Monarch 5",
      //imageRef:NikonMonarch5Pic,
      id:"NikonMonarch5",
      itemLink:"../Optics/Binoculars/NikonMonarch5",
      itemBoldText:"Nikon Monarch 5:",
      itemText:"The Nikon Monarch 5 binoculars are a popular choice for wildlife enthusiasts and bird watchers, known for their high-quality optics and rugged design. They provide excellent clarity, brightness, and durability, making them suitable for various outdoor activities."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Swarovski EL",
      //imageRef:SwarovskiELPic,
      id:"SwarovskiEL",
      itemLink:"../Optics/Binoculars/SwarovskiEL",
      itemBoldText:"Swarovski EL:",
      itemText:"The Swarovski EL is a premium line of binoculars renowned for its exceptional optical performance, clarity, and comfort. Designed for outdoor enthusiasts and professionals who demand the highest quality, these binoculars offer a combination of innovative technology and top-tier craftsmanship, making them a popular choice among bird watchers, hunters, and nature lovers."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Vortex Optics Viper HD",
      //imageRef:VortexOpticsViperHDPic,
      id:"VortexOpticsViperHD",
      itemLink:"../Optics/Binoculars/VortexOpticsViperHD",
      itemBoldText:"Vortex Optics Viper HD:",
      itemText:"The Vortex Optics Viper HD is a high-end binocular designed for outdoor use, offering exceptional clarity, color fidelity, and light transmission. Built with advanced HD extra-low dispersion glass and rugged construction, it caters to birdwatchers, hunters, and outdoor enthusiasts looking for reliability and high performance in various lighting conditions."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Zeiss Terra ED",
      //imageRef:ZeissTerraEDPic,
      id:"ZeissTerraED",
      itemLink:"../Optics/Binoculars/ZeissTerraED",
      itemBoldText:"Zeiss Terra ED:",
      itemText:"The Zeiss Terra ED is a popular pair of binoculars designed for outdoor enthusiasts and birdwatchers, offering quality optics at an accessible price point. Featuring compact and lightweight construction, the Terra ED models provide bright, clear images through fully multi-coated lenses and precision manufacturing. These binoculars aim to deliver a durable and comfortable viewing experience with reliable Zeiss optics technology."
    },
	
    ];
/*
  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setMobileOpen(false);
        //console.log('setMobileOpen=false');
      } else {
        setMobileOpen(true);
        //console.log('setMobileOpen=true');
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (

   <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <Typography align="left" variant="body1" component={'span'}>

    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
	        <h1>Binoculars</h1>
        </td>
        <td>
          <img className="RoundedCorners" src={BinocularsPic} style={{height:'120px'}} alt="Binoculars"></img>
        </td>
      </tr>
      </tbody>
    </table>



    Having a pair of binoculars opens up a world of possibilities, bringing distant sights closer and enhancing outdoor experiences. Whether you're watching birds, exploring landscapes, or attending sports events, binoculars allow you to see details that would otherwise go unnoticed. They provide a greater appreciation of nature, enabling you to observe wildlife from a respectful distance without disturbing their natural habitat. 

    </Typography>
    <br/>

        {items.map((element)=> (
          <div key={`${element.id}-div`}>
            <Paper key={`${element.id}-paper`} elevation={3}
              sx={{
                p: 2,
                margin: 'auto',
                flexGrow: 1,
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
              }}
            >
            <Grid container key={`${element.id}-container`} spacing={12} rowSpacing={1}>
              <Grid item key={`${element.id}-outer`} xs={12} sm container>
                <Grid item key={`${element.id}-column`} xs container direction="column" spacing={9}>
                  <Grid item key={`${element.id}-inner`} xs>
                    <Typography key={`${element.id}-t1`} gutterBottom variant="subtitle1" component="div">
                      <a href={element.itemLink}><b>{element.itemBoldText}</b></a> {element.itemText}
                    </Typography>
                    <Typography key={`${element.id}-t2`} align="center">
                      <Link key={`${element.id}-l1`} href={element.itemLink}>
                        <Button key={`${element.id}-b1`} variant="contained">Pros & Cons</Button>
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Paper>
          <br/>
      </div>
      ))}

    </div>

  );
}

export default Binoculars;

