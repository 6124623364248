import * as React from 'react';
import Grid  from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import {useState, useEffect} from "react";
//import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';

import RedDotSightsPic from '../images/RedDotSights.jpg';

//import AimpointPROPatrolRifleOptic)Pic from './images/AimpointPROPatrolRifleOptic).png';
//import EOTechEXPS3HolographicWeaponSightPic from './images/EOTechEXPS3HolographicWeaponSight.png';
//import HolosunHS510CPic from './images/HolosunHS510C.png';
//import SigSauerRomeo5Pic from './images/SigSauerRomeo5.png';
//import TrijiconMROMiniatureRifleOptic)Pic from './images/TrijiconMROMiniatureRifleOptic).png';


function RedDotSights () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const items = [
        {
      //manuLink:"[get link from company website]",
      //imageAlt:"Aimpoint PRO (Patrol Rifle Optic)",
      //imageRef:AimpointPROPatrolRifleOptic)Pic,
      id:"AimpointPROPatrolRifleOptic",
      itemLink:"../Optics/RedDotSights/AimpointPROPatrolRifleOptic",
      itemBoldText:"Aimpoint PRO (Patrol Rifle Optic):",
      itemText:"The Aimpoint PRO (Patrol Rifle Optic) is a high-quality red dot sight designed for law enforcement and military professionals but also used by civilian shooters. It offers a reliable, durable, and easy-to-use optic for quick target acquisition in varied lighting conditions, with a long battery life and rugged design."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"EOTech EXPS3 Holographic Weapon Sight",
      //imageRef:EOTechEXPS3HolographicWeaponSightPic,
      id:"EOTechEXPS3HolographicWeaponSight",
      itemLink:"../Optics/RedDotSights/EOTechEXPS3HolographicWeaponSight",
      itemBoldText:"EOTech EXPS3 Holographic Weapon Sight:",
      itemText:"The EOTech EXPS3 Holographic Weapon Sight is a high-performance firearm optic known for its advanced holographic technology, offering rapid target acquisition and adaptability in various lighting conditions. It is particularly popular among military, law enforcement, and shooting sports enthusiasts due to its durability and ease of use. The sight features a compact design, night vision compatibility, and a configurable reticle, which enhance its versatility and practicality in different shooting scenarios."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Holosun HS510C",
      //imageRef:HolosunHS510CPic,
      id:"HolosunHS510C",
      itemLink:"../Optics/RedDotSights/HolosunHS510C",
      itemBoldText:"Holosun HS510C:",
      itemText:"The Holosun HS510C is a versatile and durable open reflex red dot sight designed for rifles and carbines. It features a multi-reticle system, solar-powered technology with battery backup, and a rugged aluminum and titanium housing. Its aim is to provide quick target acquisition and adaptability in various lighting conditions, making it ideal for both tactical and recreational use."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Sig Sauer Romeo5",
      //imageRef:SigSauerRomeo5Pic,
      id:"SigSauerRomeo5",
      itemLink:"../Optics/RedDotSights/SigSauerRomeo5",
      itemBoldText:"Sig Sauer Romeo5:",
      itemText:"The Sig Sauer Romeo5 is a popular red dot sight designed for use on rifles, shotguns, and other firearms. Featuring a 2 MOA dot, it offers reliable aiming precision with a range of brightness settings for different lighting conditions. The Romeo5 is known for its durable construction, ease of use, and affordability, making it a favorable option for both new and experienced shooters."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Trijicon MRO (Miniature Rifle Optic)",
      //imageRef:TrijiconMROMiniatureRifleOptic)Pic,
      id:"TrijiconMROMiniatureRifleOptic",
      itemLink:"../Optics/RedDotSights/TrijiconMROMiniatureRifleOptic",
      itemBoldText:"Trijicon MRO (Miniature Rifle Optic):",
      itemText:"The Trijicon MRO (Miniature Rifle Optic) is a reflex sight known for its rugged durability and ease of use. Designed for rifles and carbines, it offers a large viewing area and an adjustable reticle for fast target acquisition, making it suitable for both professional use and recreational shooting. The MRO is also distinguished by its exceptional battery life and compatibility with a wide range of mounts."
    },
	
    ];
/*
  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setMobileOpen(false);
        //console.log('setMobileOpen=false');
      } else {
        setMobileOpen(true);
        //console.log('setMobileOpen=true');
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (

   <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <Typography align="left" variant="body1" component={'span'}>

    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
	        <h1>Red Dot Sights</h1>
        </td>
        <td>
          <img className="RoundedCorners" src={RedDotSightsPic} style={{height:'120px'}} alt="Red Dot Sights"></img>
        </td>
      </tr>
      </tbody>
    </table>



    Red dot sights are excellent tools for enhancing shooting accuracy and speed, making them popular for hunting, tactical shooting, and recreational use. They provide a clear, illuminated point of aim, allowing users to quickly acquire targets without needing to perfectly align rear and front sights. Red dot sights are also great in low-light situations, as the illuminated reticle is easy to see even in dim conditions. Compact and lightweight, they’re easy to mount on various firearms, providing an intuitive aiming solution that enhances both performance and confidence.

    </Typography>
    <br/>

        {items.map((element)=> (
          <div key={`${element.id}-div`}>
            <Paper key={`${element.id}-paper`} elevation={3}
              sx={{
                p: 2,
                margin: 'auto',
                flexGrow: 1,
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
              }}
            >
            <Grid container key={`${element.id}-container`} spacing={12} rowSpacing={1}>
              <Grid item key={`${element.id}-outer`} xs={12} sm container>
                <Grid item key={`${element.id}-column`} xs container direction="column" spacing={9}>
                  <Grid item key={`${element.id}-inner`} xs>
                    <Typography key={`${element.id}-t1`} gutterBottom variant="subtitle1" component="div">
                      <a href={element.itemLink}><b>{element.itemBoldText}</b></a> {element.itemText}
                    </Typography>
                    <Typography key={`${element.id}-t2`} align="center">
                      <Link key={`${element.id}-l1`} href={element.itemLink}>
                        <Button key={`${element.id}-b1`} variant="contained">Pros & Cons</Button>
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Paper>
          <br/>
      </div>
      ))}

    </div>

  );
}

export default RedDotSights;

