import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import ZeissTerraEDPic from '../images/ZeissTerraED.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import BinocularsPic from '../../images/Binoculars.jpg';


function ZeissTerraED () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Zeiss Terra ED</h1>
          <Link href="https://www.zeiss.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ BinocularsPic} style={{height:'120px'}} alt="Binoculars"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Zeiss Terra ED" height="130px" src={ZeissTerraEDPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Zeiss Terra ED is a popular pair of binoculars designed for outdoor enthusiasts and birdwatchers, offering quality optics at an accessible price point. Featuring compact and lightweight construction, the Terra ED models provide bright, clear images through fully multi-coated lenses and precision manufacturing. These binoculars aim to deliver a durable and comfortable viewing experience with reliable Zeiss optics technology.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">High-quality optics with impressive clarity and brightness</li>
<li className="ListItemStyle">Lightweight and compact design makes it convenient for travel</li>
<li className="ListItemStyle">Sturdy construction with a durable, rubber-coated body</li>
<li className="ListItemStyle">Waterproof and fog-proof for reliable outdoor use</li>
<li className="ListItemStyle">Wide field of view enhances outdoor and wildlife observation</li>
<li className="ListItemStyle">Comfortable eye relief which is beneficial for eyeglass wearers</li>
<li className="ListItemStyle">Smooth and precise focus wheel for easy adjustments</li>
<li className="ListItemStyle">Good color fidelity and contrast for true-to-life images</li>
<li className="ListItemStyle">Reliable performance in low-light conditions</li>
<li className="ListItemStyle">Excellent value for money in the mid-range binocular market</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Some users may find the field of view narrower than expected</li>
<li className="ListItemStyle">Less advanced optical coatings compared to high-end Zeiss models</li>
<li className="ListItemStyle">Not as compact as some competitors in the same category</li>
<li className="ListItemStyle">Eyecups may not stay in place securely during use</li>
<li className="ListItemStyle">Limited accessory package included with purchase</li>
<li className="ListItemStyle">No locking diopter adjustment which might require frequent readjustment</li>
<li className="ListItemStyle">May exhibit minor chromatic aberration in high-contrast settings</li>
<li className="ListItemStyle">The focus knob might be slightly stiff in colder environments</li>
<li className="ListItemStyle">Not ideal for very low-light conditions despite good performance</li>
<li className="ListItemStyle">Relatively plain design lacking aesthetic flair</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default ZeissTerraED;

