import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import MoraknivCompanionHeavyDutyPic from '../images/MoraknivCompanionHeavyDuty.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import HuntingKnivesPic from '../../images/HuntingKnives.jpg';


function MoraknivCompanionHeavyDuty () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Morakniv Companion Heavy Duty</h1>
          <Link href="https://morakniv.se/produkt/companion-heavyduty-s/" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ HuntingKnivesPic} style={{height:'120px'}} alt="Hunting Knives"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Morakniv Companion Heavy Duty" height="130px" src={MoraknivCompanionHeavyDutyPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Morakniv Companion Heavy Duty is a robust and reliable fixed-blade knife designed for outdoor enthusiasts, bushcrafters, and survivalists. It features a thicker 4.1-inch stainless steel blade with a durable Scandi grind that offers exceptional strength and edge retention, making it ideal for heavy-duty tasks. The ergonomic handle provides a comfortable and secure grip, even in wet conditions, while the included plastic sheath ensures safe storage and easy portability.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Thick stainless steel blade for heavy-duty tasks</li>
<li className="ListItemStyle">Superior strength and edge retention</li>
<li className="ListItemStyle">Ergonomic handle for a comfortable grip</li>
<li className="ListItemStyle">Affordable price point</li>
<li className="ListItemStyle">Durable and resilient blade construction</li>
<li className="ListItemStyle">Includes a protective plastic sheath</li>
<li className="ListItemStyle">Easy to sharpen due to Scandi grind</li>
<li className="ListItemStyle">Corrosion-resistant materials</li>
<li className="ListItemStyle">Suitable for various outdoor activities</li>
<li className="ListItemStyle">Lightweight and easy to carry</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Plastic sheath may feel flimsy</li>
<li className="ListItemStyle">Handle material not ideal for everyone</li>
<li className="ListItemStyle">Lacks a full tang design</li>
<li className="ListItemStyle">Blade may require frequent maintenance in harsh conditions</li>
<li className="ListItemStyle">Limited color options</li>
<li className="ListItemStyle">Not suitable for fine, detailed work</li>
<li className="ListItemStyle">Handle may be too large for small hands</li>
<li className="ListItemStyle">Plastic sheath lacks attachment options</li>
<li className="ListItemStyle">No lanyard hole in the handle</li>
<li className="ListItemStyle">Scandi grind might not be preferred by all users</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default MoraknivCompanionHeavyDuty;

