import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import GarmineTrex32xPic from '../images/GarmineTrex32x.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import HandheldGPSPic from '../../images/HandheldGPS.jpg';


function GarmineTrex32x () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Garmin eTrex 32x</h1>
          <Link href="https://www.garmin.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ HandheldGPSPic} style={{height:'120px'}} alt="HandheldGPS"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Garmin eTrex 32x" height="130px" src={GarmineTrex32xPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Garmin eTrex 32x is a handheld GPS device known for its durability and versatility, designed for outdoor enthusiasts. It features a 2.2-inch sunlight-readable display and preloaded maps, including roads and trial routes from all terrains. The device supports both GPS and GLONASS satellite systems, offering improved positioning in challenging environments, along with a 3-axis compass and barometric altimeter.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Durable and robust design suitable for harsh conditions.</li>
<li className="ListItemStyle">Supports both GPS and GLONASS for enhanced signal reception.</li>
<li className="ListItemStyle">Long battery life, making it ideal for extended outdoor activities.</li>
<li className="ListItemStyle">Preloaded TopoActive maps offer versatile guidance.</li>
<li className="ListItemStyle">Includes a 3-axis compass and barometric altimeter for detailed navigation.</li>
<li className="ListItemStyle">Customizable user settings and profiles.</li>
<li className="ListItemStyle">Compact and lightweight design for easy portability.</li>
<li className="ListItemStyle">Compatible with a wide range of mounts and accessories.</li>
<li className="ListItemStyle">Intuitive user interface for straightforward operation.</li>
<li className="ListItemStyle">MicroSD slot for additional map storage and expandability.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Screen might be small for some users.</li>
<li className="ListItemStyle">Resolution is lower than some modern devices.</li>
<li className="ListItemStyle">No touchscreen, relies on button navigation which can be slow.</li>
<li className="ListItemStyle">Limited internal memory might need expansion for extensive use.</li>
<li className="ListItemStyle">Preloaded maps might lack detail in certain regions.</li>
<li className="ListItemStyle">Could be considered expensive compared to smartphone alternatives.</li>
<li className="ListItemStyle">Bluetooth functionalities are limited compared to modern devices.</li>
<li className="ListItemStyle">Learning curve for those unfamiliar with dedicated GPS devices.</li>
<li className="ListItemStyle">Some users may find the device menus complicated.</li>
<li className="ListItemStyle">Updates or additional maps can be expensive.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default GarmineTrex32x;

