import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import MoultrieA900iPic from '../images/MoultrieA900i.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import TrailCamsPic from '../../images/TrailCams.jpg';


function MoultrieA900i () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Moultrie A-900i</h1>
          <Link href="https://www.moultriefeeders.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ TrailCamsPic} style={{height:'120px'}} alt="TrailCams"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Moultrie A-900i" height="130px" src={MoultrieA900iPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Moultrie A-900i is a compact and popular trail camera designed for wildlife monitoring and security purposes. It features a 30-megapixel sensor, 0.4-second trigger speed, and invisible flash for nighttime captures, making it suitable for both day and night surveillance. The camera is easy to set up and offers long battery life, making it a reliable choice for outdoor enthusiasts.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">High-quality 30-megapixel images.</li>
<li className="ListItemStyle">Fast 0.4-second trigger speed.</li>
<li className="ListItemStyle">Invisible infrared flash for undetectable night shots.</li>
<li className="ListItemStyle">Compact design that is easy to conceal.</li>
<li className="ListItemStyle">Long battery life suitable for extended field use.</li>
<li className="ListItemStyle">Easy to set up and operate, even for beginners.</li>
<li className="ListItemStyle">Affordable price compared to other cameras with similar features.</li>
<li className="ListItemStyle">Durable construction suitable for various weather conditions.</li>
<li className="ListItemStyle">Compatible with various memory card sizes.</li>
<li className="ListItemStyle">Good detection range for spotting wildlife.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Limited to still images only; no video recording.</li>
<li className="ListItemStyle">Image quality may diminish in very low light conditions.</li>
<li className="ListItemStyle">No wireless connectivity for remote access and management.</li>
<li className="ListItemStyle">Occasional overexposure issues in direct sunlight.</li>
<li className="ListItemStyle">Plastic construction may not withstand extreme impacts.</li>
<li className="ListItemStyle">Lacks advanced scheduling capabilities.</li>
<li className="ListItemStyle">Longer recovery time between shots compared to some competitors.</li>
<li className="ListItemStyle">No built-in viewer for reviewing captures on site.</li>
<li className="ListItemStyle">Limited customization of settings via onboard interface.</li>
<li className="ListItemStyle">Requires separate purchase of batteries and memory card.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default MoultrieA900i;

