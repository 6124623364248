import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import CelestronNatureDXPic from '../images/CelestronNatureDX.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import BinocularsPic from '../../images/Binoculars.jpg';


function CelestronNatureDX () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Celestron Nature DX</h1>
          <Link href="https://www.celestron.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ BinocularsPic} style={{height:'120px'}} alt="Binoculars"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Celestron Nature DX" height="130px" src={CelestronNatureDXPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Celestron Nature DX is a versatile binocular designed for outdoor enthusiasts. Built with quality optics, it offers excellent performance for birdwatching, hiking, and other nature activities. It features a robust and waterproof design and comes in a range of magnifications to cater to different viewing needs.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Affordable pricing for quality optics.</li>
<li className="ListItemStyle">Fully multi-coated lenses for brighter images.</li>
<li className="ListItemStyle">Waterproof and fog-proof design.</li>
<li className="ListItemStyle">Lightweight and comfortable for extended use.</li>
<li className="ListItemStyle">Twist-up eyecups for easy eye relief adjustment.</li>
<li className="ListItemStyle">Wide field of view for comprehensive observation.</li>
<li className="ListItemStyle">Rubber-armored body for durability.</li>
<li className="ListItemStyle">Great close focus ability for observing nearby subjects.</li>
<li className="ListItemStyle">Available in multiple magnifications to suit various needs.</li>
<li className="ListItemStyle">Comes with a carrying case and neck strap for convenience.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">May not perform as well in low-light conditions compared to higher-end models.</li>
<li className="ListItemStyle">Some users may experience chromatic aberration.</li>
<li className="ListItemStyle">The field of view is narrower compared to some premium models.</li>
<li className="ListItemStyle">Focus adjustment can be stiff for some units.</li>
<li className="ListItemStyle">Image clarity may degrade towards the edges.</li>
<li className="ListItemStyle">Objective lens covers may not fit securely.</li>
<li className="ListItemStyle">Limited eye relief may not be suitable for all eyeglass wearers.</li>
<li className="ListItemStyle">Inter-pupillary adjustment can be challenging for some users.</li>
<li className="ListItemStyle">The neck strap may be uncomfortable for long periods.</li>
<li className="ListItemStyle">Close focus distance may not meet every user's preference.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default CelestronNatureDX;

