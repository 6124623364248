import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import TrijiconMROMiniatureRifleOptic)Pic from '../images/TrijiconMROMiniatureRifleOptic).png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import RedDotSightsPic from '../../images/RedDotSights.jpg';


function TrijiconMROMiniatureRifleOptic () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Trijicon MRO (Miniature Rifle Optic)</h1>
          <Link href="https://www.trijicon.com/products/mro" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ RedDotSightsPic} style={{height:'120px'}} alt="RedDotSights"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Trijicon MRO (Miniature Rifle Optic)" height="130px" src={TrijiconMROMiniatureRifleOptic)Pic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Trijicon MRO (Miniature Rifle Optic) is a reflex sight known for its rugged durability and ease of use. Designed for rifles and carbines, it offers a large viewing area and an adjustable reticle for fast target acquisition, making it suitable for both professional use and recreational shooting. The MRO is also distinguished by its exceptional battery life and compatibility with a wide range of mounts.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Durable construction designed to withstand tough conditions.</li>
<li className="ListItemStyle">Large objective lens for increased field of view.</li>
<li className="ListItemStyle">Unlimited eye relief allows for quick target acquisition.</li>
<li className="ListItemStyle">Battery life up to 5 years of continuous use.</li>
<li className="ListItemStyle">Easy-to-use brightness adjustment settings.</li>
<li className="ListItemStyle">True 1x power for both eyes open shooting.</li>
<li className="ListItemStyle">Adjustable reticle brightness for various conditions.</li>
<li className="ListItemStyle">Compatible with a wide range of mounts.</li>
<li className="ListItemStyle">Compact and lightweight design.</li>
<li className="ListItemStyle">Parallax-free design for improved accuracy.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Higher price point compared to some competitors.</li>
<li className="ListItemStyle">Limited to 2 MOA reticle size options.</li>
<li className="ListItemStyle">Battery compartment can be difficult to access.</li>
<li className="ListItemStyle">Some users report slight tint in the lens.</li>
<li className="ListItemStyle">Slightly heavier than other miniature optics.</li>
<li className="ListItemStyle">No night vision compatibility in some models.</li>
<li className="ListItemStyle">Adjustment knobs might require tool use, which can be inconvenient.</li>
<li className="ListItemStyle">Mounting height can affect co-witness capabilities.</li>
<li className="ListItemStyle">Limited reticle pattern customization.</li>
<li className="ListItemStyle">Some users may find adjustments less intuitive.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default TrijiconMROMiniatureRifleOptic;

