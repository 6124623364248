import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import UCOStormproofMatchesPic from '../images/UCOStormproofMatches.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import FireStartersPic from '../../images/FireStarters.jpg';


function UCOStormproofMatches () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>UCO Stormproof Matches</h1>
          <Link href="https://ucogear.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ FireStartersPic} style={{height:'120px'}} alt="FireStarters"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="UCO Stormproof Matches" height="130px" src={UCOStormproofMatchesPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        UCO Stormproof Matches are highly reliable matches designed to ignite in harsh weather conditions. They are windproof and waterproof, making them a popular choice for outdoor enthusiasts who need a dependable fire-starting solution during camping, hiking, or emergency situations. Each match burns for approximately 15 seconds, providing ample time to ignite various fire-starting materials.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Windproof and waterproof design ensures reliability in harsh conditions.</li>
<li className="ListItemStyle">Each match burns for about 15 seconds, giving sufficient time to start a fire.</li>
<li className="ListItemStyle">Includes a durable, waterproof case to keep matches secure and dry.</li>
<li className="ListItemStyle">Comes with extra strikers in the case for prolonged usability.</li>
<li className="ListItemStyle">Suitable for a variety of outdoor and emergency situations.</li>
<li className="ListItemStyle">Matches are easy to ignite even in adverse weather conditions.</li>
<li className="ListItemStyle">Bright flame allows easy visibility when igniting.</li>
<li className="ListItemStyle">Compact and lightweight, making them easy to carry in a backpack.</li>
<li className="ListItemStyle">Reliable performance recognized by outdoor enthusiasts.</li>
<li className="ListItemStyle">Provides peace of mind when exploring remote areas or during emergencies.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Matches can be relatively expensive compared to regular matches.</li>
<li className="ListItemStyle">Limited strike surface after coatings wear down.</li>
<li className="ListItemStyle">The case, while durable, can become bulkier in smaller packs.</li>
<li className="ListItemStyle">Requires care to not exhaust all the igniters quickly.</li>
<li className="ListItemStyle">Once ignited, cannot be easily extinguished and reused.</li>
<li className="ListItemStyle">Some users find the match head large and cumbersome to handle.</li>
<li className="ListItemStyle">Burn time, while ample, might be shorter than expected in windy conditions.</li>
<li className="ListItemStyle">Potential difficulty lighting in extremely low temperatures.</li>
<li className="ListItemStyle">Replacement matches and strikers must often be purchased separately.</li>
<li className="ListItemStyle">Relies on manual ignition rather than automatic, needing practice for optimal use.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default UCOStormproofMatches;

