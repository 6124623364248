import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import BenchmadeHiddenCanyonHunterPic from '../images/BenchmadeHiddenCanyonHunter.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import HuntingKnivesPic from '../../images/HuntingKnives.jpg';


function BenchmadeHiddenCanyonHunter () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Benchmade Hidden Canyon Hunter</h1>
          <Link href="https://www.benchmade.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ HuntingKnivesPic} style={{height:'120px'}} alt="Hunting Knives"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Benchmade Hidden Canyon Hunter" height="130px" src={BenchmadeHiddenCanyonHunterPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Benchmade Hidden Canyon Hunter is a compact fixed-blade hunting knife known for its superior quality, durability, and performance in field dressing and hunting tasks. Crafted with precision, it features a full-tang CPM-S30V steel blade that offers excellent edge retention and corrosion resistance, coupled with ergonomic and durable handle options, making it a reliable companion for hunters.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">High-quality CPM-S30V steel blade with excellent edge retention.</li>
<li className="ListItemStyle">Compact and lightweight design, ideal for portability and ease of use.</li>
<li className="ListItemStyle">Ergonomic handle with good grip for comfortable use.</li>
<li className="ListItemStyle">Versatile utility for a variety of hunting and outdoor tasks.</li>
<li className="ListItemStyle">Durable construction ensuring long-lasting performance.</li>
<li className="ListItemStyle">Comes with a quality leather or Kydex sheath for secure storage.</li>
<li className="ListItemStyle">Sharp out of the box with a well-designed blade profile.</li>
<li className="ListItemStyle">Full tang design adding to its strength and reliability.</li>
<li className="ListItemStyle">Respected Benchmade brand known for craftsmanship and warranty service.</li>
<li className="ListItemStyle">Great balance and weight distribution for precise control.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Higher price point compared to similar knives, affecting affordibility.</li>
<li className="ListItemStyle">Blade size may be too small for those who prefer larger knives.</li>
<li className="ListItemStyle">Occasional reports of handle scales being less durable over time.</li>
<li className="ListItemStyle">Sheath retention might loosen with heavy use.</li>
<li className="ListItemStyle">Limited to hunting and specific outdoor activities; not a general-purpose knife.</li>
<li className="ListItemStyle">Some users might find the handle too short for larger hands.</li>
<li className="ListItemStyle">May require regular maintenance to keep in peak condition.</li>
<li className="ListItemStyle">Blade corrosion possible if not properly cared for, despite resistance.</li>
<li className="ListItemStyle">Kydex sheath option is bulkier than leather for some users.</li>
<li className="ListItemStyle">Limited handle color and material options available.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default BenchmadeHiddenCanyonHunter;

