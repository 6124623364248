import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import GerberBearGryllsFireStarterPic from '../images/GerberBearGryllsFireStarter.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import FireStartersPic from '../../images/FireStarters.jpg';


function GerberBearGryllsFireStarter () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Gerber Bear Grylls Fire Starter</h1>
          <Link href="https://www.gerbergear.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ FireStartersPic} style={{height:'120px'}} alt="FireStarters"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Gerber Bear Grylls Fire Starter" height="130px" src={GerberBearGryllsFireStarterPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Gerber Bear Grylls Fire Starter is a compact, reliable tool designed for outdoor enthusiasts to easily start a fire. It features a ferrocerium rod and metal striker, lanyard to keep them secure and prevent loss, watertight storage compartment for tinder, and an emergency whistle. Its design caters to survival situations, making fire starting efficient and simple in various weather conditions.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Compact and portable, easy to carry.</li>
<li className="ListItemStyle">Includes a built-in emergency whistle.</li>
<li className="ListItemStyle">Watertight compartment for storing tinder.</li>
<li className="ListItemStyle">Durable construction suitable for outdoor use.</li>
<li className="ListItemStyle">Trusted brand with a focus on survival gear.</li>
<li className="ListItemStyle">Effective in various weather conditions.</li>
<li className="ListItemStyle">Lanyard helps prevent loss of components.</li>
<li className="ListItemStyle">Good spark generation for reliable fire starting.</li>
<li className="ListItemStyle">Accompanied by Bear Grylls survival instructions.</li>
<li className="ListItemStyle">Affordable price point for its features.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Rod and striker need practice for effective use.</li>
<li className="ListItemStyle">Tinder storage is relatively small.</li>
<li className="ListItemStyle">May require dry tinder for optimal performance.</li>
<li className="ListItemStyle">Emergency whistle is not very loud.</li>
<li className="ListItemStyle">Lanyard might be too short for some users.</li>
<li className="ListItemStyle">Wear over time can reduce effectiveness.</li>
<li className="ListItemStyle">Limited additional features compared to competitors.</li>
<li className="ListItemStyle">Instructions can be too basic for some users.</li>
<li className="ListItemStyle">Design might not appeal to all aesthetics.</li>
<li className="ListItemStyle">Not suitable for very large fire starting needs.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default GerberBearGryllsFireStarter;

