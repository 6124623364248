import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import HolosunHS510CPic from '../images/HolosunHS510C.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import RedDotSightsPic from '../../images/RedDotSights.jpg';


function HolosunHS510C () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Holosun HS510C</h1>
          <Link href="https://holosun.com/" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ RedDotSightsPic} style={{height:'120px'}} alt="RedDotSights"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Holosun HS510C" height="130px" src={HolosunHS510CPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Holosun HS510C is a versatile and durable open reflex red dot sight designed for rifles and carbines. It features a multi-reticle system, solar-powered technology with battery backup, and a rugged aluminum and titanium housing. Its aim is to provide quick target acquisition and adaptability in various lighting conditions, making it ideal for both tactical and recreational use.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Multi-reticle system for versatility</li>
<li className="ListItemStyle">Durable construction using aluminum and titanium</li>
<li className="ListItemStyle">Solar-powered with battery backup for extended use</li>
<li className="ListItemStyle">Quick target acquisition and wide field of view</li>
<li className="ListItemStyle">Parallax free design for precise accuracy</li>
<li className="ListItemStyle">Shake awake technology conserves battery life</li>
<li className="ListItemStyle">Easy to install and adjust</li>
<li className="ListItemStyle">Waterproof and fog-resistant build</li>
<li className="ListItemStyle">Crisp and clear reticle display</li>
<li className="ListItemStyle">Compatible with a variety of firearms</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">May be heavier compared to some other red dot sights</li>
<li className="ListItemStyle">Higher price point than basic models</li>
<li className="ListItemStyle">Limited eye relief may be a concern for some</li>
<li className="ListItemStyle">Solar panel might not be effective in low light</li>
<li className="ListItemStyle">Adjustment turrets can be stiff initially</li>
<li className="ListItemStyle">May require a riser for optimal use in some setups</li>
<li className="ListItemStyle">Not ideal for pistols due to size</li>
<li className="ListItemStyle">Battery compartment can be tricky to access</li>
<li className="ListItemStyle">Potential glare from the glass in bright conditions</li>
<li className="ListItemStyle">Limited compatibility with magnifiers without additional mounts</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default HolosunHS510C;

