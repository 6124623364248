import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import GarminOregon750Pic from '../images/GarminOregon750.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import HandheldGPSPic from '../../images/HandheldGPS.jpg';


function GarminOregon750 () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Garmin Oregon 750</h1>
          <Link href="https://www.garmin.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ HandheldGPSPic} style={{height:'120px'}} alt="HandheldGPS"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Garmin Oregon 750" height="130px" src={GarminOregon750Pic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Garmin Oregon 750 is a versatile handheld GPS device designed for outdoor enthusiasts. It features a dual-orientation, sunlight-readable touchscreen with high-resolution display and multi-touch capabilities. The device comes equipped with an 8-megapixel autofocus camera, wireless connectivity for sharing data, and access to GPS and GLONASS satellite systems to ensure accurate navigation in challenging environments. Ideal for hiking, geocaching, and other outdoor activities, the Oregon 750 offers customizable mapping options and advanced tracking features.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">High-resolution touchscreen that is easy to read in sunlight</li>
<li className="ListItemStyle">Dual-orientation display allowing flexibility in viewing</li>
<li className="ListItemStyle">Integrated 8-megapixel camera for capturing outdoor moments</li>
<li className="ListItemStyle">Access to both GPS and GLONASS satellites for improved accuracy</li>
<li className="ListItemStyle">Wireless connectivity enables easy sharing of waypoints, routes, and geocaches</li>
<li className="ListItemStyle">Customizable mapping options with support for various map formats</li>
<li className="ListItemStyle">Compatible with Paperless Geocaching features</li>
<li className="ListItemStyle">Rugged and water-resistant design suitable for outdoor conditions</li>
<li className="ListItemStyle">Long battery life with multiple power source options</li>
<li className="ListItemStyle">Advanced tracking functions like point-to-point routing and elevation tracking</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Higher price point compared to some competitors</li>
<li className="ListItemStyle">Touchscreen may be less responsive in very wet or cold conditions</li>
<li className="ListItemStyle">Limited internal storage requiring additional microSD for extensive mapping</li>
<li className="ListItemStyle">Some users report occasional software glitches or freezes</li>
<li className="ListItemStyle">Battery compartment can be difficult to open</li>
<li className="ListItemStyle">Heavier and bulkier than simpler GPS models</li>
<li className="ListItemStyle">Camera quality may not match standalone cameras or smartphones</li>
<li className="ListItemStyle">Wireless transfer can be slow depending on connection</li>
<li className="ListItemStyle">Interface may have a learning curve for new users</li>
<li className="ListItemStyle">Limited useful life due to rapid advancements in GPS technology</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default GarminOregon750;

