import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import ColemanClassicPropaneStovePic from '../images/ColemanClassicPropaneStove.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import CampingStovesPic from '../../images/CampingStoves.jpg';


function ColemanClassicPropaneStove () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Coleman Classic Propane Stove</h1>
          <Link href="https://www.coleman.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ CampingStovesPic} style={{height:'120px'}} alt="CampingStoves"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Coleman Classic Propane Stove" height="130px" src={ColemanClassicPropaneStovePic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Coleman Classic Propane Stove is a portable and reliable camping stove designed for outdoor cooking. It features two adjustable burners that provide a total of 20,000 BTUs of cooking power, allowing for efficient cooking in various outdoor conditions. The stove is equipped with wind-blocking panels to shield the burners from the wind, ensuring consistent performance. It is compact, easy to transport, and operates on propane cylinders, making it a popular choice for campers and outdoor enthusiasts.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Affordable and budget-friendly.</li>
<li className="ListItemStyle">Compact and portable design.</li>
<li className="ListItemStyle">Easy to set up and use.</li>
<li className="ListItemStyle">Two adjustable burners for versatile cooking.</li>
<li className="ListItemStyle">Wind-blocking panels enhance performance in windy conditions.</li>
<li className="ListItemStyle">Durable construction for outdoor use.</li>
<li className="ListItemStyle">Compatible with standard propane cylinders.</li>
<li className="ListItemStyle">Good heat output with 20,000 BTUs.</li>
<li className="ListItemStyle">Easy to clean with removable grate.</li>
<li className="ListItemStyle">Reliable ignition system.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Limited to two burners.</li>
<li className="ListItemStyle">No built-in ignition, requires matches or lighter.</li>
<li className="ListItemStyle">Wind panels may not fully block strong winds.</li>
<li className="ListItemStyle">Propane cylinders not included.</li>
<li className="ListItemStyle">May not be suitable for large groups.</li>
<li className="ListItemStyle">Burners are close together, limiting large pot use.</li>
<li className="ListItemStyle">Requires flat surface for stability.</li>
<li className="ListItemStyle">No temperature gauge for precise cooking.</li>
<li className="ListItemStyle">Metal parts can rust if not properly maintained.</li>
<li className="ListItemStyle">Carrying case not included.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default ColemanClassicPropaneStove;

