import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import MysteryRanchMetcalfPic from '../images/MysteryRanchMetcalf.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import HuntingPacksPic from '../../images/HuntingPacks.jpg';


function MysteryRanchMetcalf () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Mystery Ranch Metcalf</h1>
          <Link href="https://www.mysteryranch.com/metcalf-pack" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ HuntingPacksPic} style={{height:'120px'}} alt="Hunting Packs"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Mystery Ranch Metcalf" height="130px" src={MysteryRanchMetcalfPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Mystery Ranch Metcalf is a versatile and durable backpack designed for hunting and outdoor adventures. It offers a balance of comfort, support, and high load carrying capacity. Its modular design includes a detachable pack and frame, allowing for adaptability during different phases of a hunt or outdoor excursion. Known for its superior craftsmanship and high-quality materials, it is a preferred choice for serious hunters and outdoor enthusiasts.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">High load capacity suitable for multi-day hunts.</li>
<li className="ListItemStyle">Durable materials and construction for rugged use.</li>
<li className="ListItemStyle">Versatile modular design with detachable components.</li>
<li className="ListItemStyle">Adjustable fit for enhanced comfort and support.</li>
<li className="ListItemStyle">Multiple compartments for organized gear storage.</li>
<li className="ListItemStyle">Water-resistant materials to protect contents.</li>
<li className="ListItemStyle">Compatible with hydration systems.</li>
<li className="ListItemStyle">Quiet fabric ideal for stealthy hunting.</li>
<li className="ListItemStyle">Strong frame support for stability.</li>
<li className="ListItemStyle">Reputation for high quality and reliability.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Higher price point compared to some competitors.</li>
<li className="ListItemStyle">Heavier than some other hunting backpacks.</li>
<li className="ListItemStyle">Complex adjustments may take time to learn.</li>
<li className="ListItemStyle">Limited availability in certain regions.</li>
<li className="ListItemStyle">Could be overkill for simple day hikes.</li>
<li className="ListItemStyle">Pack compressibility might be less than others.</li>
<li className="ListItemStyle">Some users find the initial setup challenging.</li>
<li className="ListItemStyle">Accessories may need to be purchased separately.</li>
<li className="ListItemStyle">May require practice to efficiently load and unload.</li>
<li className="ListItemStyle">Not the lightest option available for ultralight hunters.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default MysteryRanchMetcalf;

