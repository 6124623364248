import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import Badlands2200Pic from '../images/Badlands2200.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import HuntingPacksPic from '../../images/HuntingPacks.jpg';


function Badlands2200 () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Badlands 2200</h1>
          <Link href="https://www.badlandsgear.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ HuntingPacksPic} style={{height:'120px'}} alt="Hunting Packs"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Badlands 2200" height="130px" src={Badlands2200Pic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Badlands 2200 is a versatile hunting backpack known for its durable construction, ergonomic design, and ample storage options. This pack is designed to support hunters on extended trips, providing comfort through its load-lifting features and high-quality materials. With a strong focus on utility and user experience, the Badlands 2200 is a favorite among outdoor enthusiasts.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Durable and rugged construction that withstands harsh outdoor environments.</li>
<li className="ListItemStyle">Comfortable fit with ergonomic design suitable for extended use.</li>
<li className="ListItemStyle">Ample storage space includes multiple compartments and external lashing points.</li>
<li className="ListItemStyle">Integrated meat shelf for carrying game effectively.</li>
<li className="ListItemStyle">Water-resistant materials keep gear dry in wet conditions.</li>
<li className="ListItemStyle">Strong and reliable zippers and buckles enhance durability.</li>
<li className="ListItemStyle">Compatible with hydration packs for convenient water access.</li>
<li className="ListItemStyle">Padded straps and hip belt distribute weight evenly, reducing strain.</li>
<li className="ListItemStyle">Lifetime warranty provides confidence in lasting quality.</li>
<li className="ListItemStyle">Modular design allows customization and attachment of additional gear.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Relatively high price compared to other hunting backpacks.</li>
<li className="ListItemStyle">Might feel heavy when fully loaded with gear.</li>
<li className="ListItemStyle">Limited color options, focusing largely on camouflage aesthetics.</li>
<li className="ListItemStyle">Some users may find the size unsuitable for quick, short hunts.</li>
<li className="ListItemStyle">Adjusting the backpack for the perfect fit can be time-consuming.</li>
<li className="ListItemStyle">Lack of integrated rain cover despite water-resistant materials.</li>
<li className="ListItemStyle">Initial set-up and configuration might be complex for new users.</li>
<li className="ListItemStyle">Zippers might be noisy, which can be a concern during hunts.</li>
<li className="ListItemStyle">Can be bulky for smaller-framed individuals.</li>
<li className="ListItemStyle">Straps could be excessive for minimal gear loads.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default Badlands2200;

