import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import AimpointPROPatrolRifleOptic)Pic from '../images/AimpointPROPatrolRifleOptic).png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import RedDotSightsPic from '../../images/RedDotSights.jpg';


function AimpointPROPatrolRifleOptic () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Aimpoint PRO (Patrol Rifle Optic)</h1>
          <Link href="https://aimpoint.us/aimpoint-pro/" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ RedDotSightsPic} style={{height:'120px'}} alt="RedDotSights"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Aimpoint PRO (Patrol Rifle Optic)" height="130px" src={AimpointPROPatrolRifleOptic)Pic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Aimpoint PRO (Patrol Rifle Optic) is a high-quality red dot sight designed for law enforcement and military professionals but also used by civilian shooters. It offers a reliable, durable, and easy-to-use optic for quick target acquisition in varied lighting conditions, with a long battery life and rugged design.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Long battery life of up to 30,000 hours on a single battery.</li>
<li className="ListItemStyle">Rugged and durable construction suitable for extreme conditions.</li>
<li className="ListItemStyle">Always-on feature eliminates the need to switch on or off.</li>
<li className="ListItemStyle">Night vision compatible with 4 night vision settings.</li>
<li className="ListItemStyle">Compatible with a wide variety of rifles and mounts.</li>
<li className="ListItemStyle">2 MOA red dot for precision and fast target acquisition.</li>
<li className="ListItemStyle">Waterproof and shockproof design for enhanced reliability.</li>
<li className="ListItemStyle">Comes with a QRP2 mount for easy attachment and detachment.</li>
<li className="ListItemStyle">Clear, high-quality optics with anti-reflective coatings.</li>
<li className="ListItemStyle">Simple operation allows for ease of use in high-pressure scenarios.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Heavier compared to some other optics in its class.</li>
<li className="ListItemStyle">The price point may be higher for budget-conscious consumers.</li>
<li className="ListItemStyle">Limited to a red dot with no reticle customization options.</li>
<li className="ListItemStyle">Brightness adjustment knob can be stiff for some users.</li>
<li className="ListItemStyle">Mounting height might require additional adjustments for some users.</li>
<li className="ListItemStyle">Not the smallest optic, potentially impacting some setups.</li>
<li className="ListItemStyle">Battery compartment may require tools to access.</li>
<li className="ListItemStyle">Non-magnified optic may not suit long-range shooting needs.</li>
<li className="ListItemStyle">May require additional accessories for optimal use at night.</li>
<li className="ListItemStyle">Fixed mount option may be less versatile for some users' preferences.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default AimpointPROPatrolRifleOptic;

