import * as React from 'react';
import Grid  from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import {useState, useEffect} from "react";
//import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';

import FireStartersPic from '../images/FireStarters.jpg';

//import ExotacnanoSTRIKERXLPic from './images/ExotacnanoSTRIKERXL.png';
//import GerberBearGryllsFireStarterPic from './images/GerberBearGryllsFireStarter.png';
//import LightMyFireSwedishFireSteelPic from './images/LightMyFireSwedishFireSteel.png';
//import UCOStormproofMatchesPic from './images/UCOStormproofMatches.png';
//import ZippoEmergencyFireStarterKitPic from './images/ZippoEmergencyFireStarterKit.png';


function FireStarters () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const items = [
        {
      //manuLink:"[get link from company website]",
      //imageAlt:"Exotac nanoSTRIKER XL",
      //imageRef:ExotacnanoSTRIKERXLPic,
      id:"ExotacnanoSTRIKERXL",
      itemLink:"../Camping/FireStarters/ExotacnanoSTRIKERXL",
      itemBoldText:"Exotac nanoSTRIKER XL:",
      itemText:"The Exotac nanoSTRIKER XL is a compact, high-quality firestarter designed for outdoor enthusiasts and survivalists. It features an all-in-one design with a replaceable ferrocerium rod and a tungsten carbide striker, housed in a durable, waterproof aluminum body. The nanoSTRIKER XL is designed to be lightweight and portable, making it easy to add to any camping or emergency kit."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Gerber Bear Grylls Fire Starter",
      //imageRef:GerberBearGryllsFireStarterPic,
      id:"GerberBearGryllsFireStarter",
      itemLink:"../Camping/FireStarters/GerberBearGryllsFireStarter",
      itemBoldText:"Gerber Bear Grylls Fire Starter:",
      itemText:"The Gerber Bear Grylls Fire Starter is a compact, reliable tool designed for outdoor enthusiasts to easily start a fire. It features a ferrocerium rod and metal striker, lanyard to keep them secure and prevent loss, watertight storage compartment for tinder, and an emergency whistle. Its design caters to survival situations, making fire starting efficient and simple in various weather conditions."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Light My Fire Swedish FireSteel",
      //imageRef:LightMyFireSwedishFireSteelPic,
      id:"LightMyFireSwedishFireSteel",
      itemLink:"../Camping/FireStarters/LightMyFireSwedishFireSteel",
      itemBoldText:"Light My Fire Swedish FireSteel:",
      itemText:"The Light My Fire Swedish FireSteel is a reliable fire starter designed for outdoor enthusiasts. Originally developed for the Swedish Ministry of Defense, this fire steel can be used in all weather conditions and at any altitude. It produces a 3,000°C (5,500°F) spark and is ideal for lighting campfires, stoves, and barbecues. It is known for its durability and can last for approximately 3,000 strikes."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"UCO Stormproof Matches",
      //imageRef:UCOStormproofMatchesPic,
      id:"UCOStormproofMatches",
      itemLink:"../Camping/FireStarters/UCOStormproofMatches",
      itemBoldText:"UCO Stormproof Matches:",
      itemText:"UCO Stormproof Matches are highly reliable matches designed to ignite in harsh weather conditions. They are windproof and waterproof, making them a popular choice for outdoor enthusiasts who need a dependable fire-starting solution during camping, hiking, or emergency situations. Each match burns for approximately 15 seconds, providing ample time to ignite various fire-starting materials."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Zippo Emergency Fire Starter Kit",
      //imageRef:ZippoEmergencyFireStarterKitPic,
      id:"ZippoEmergencyFireStarterKit",
      itemLink:"../Camping/FireStarters/ZippoEmergencyFireStarterKit",
      itemBoldText:"Zippo Emergency Fire Starter Kit:",
      itemText:"The Zippo Emergency Fire Starter Kit is a compact and reliable tool designed for outdoor adventurers and emergency situations. It comes with water-resistant waxed tinder sticks and a flint wheel ignition system, making it easy to start a fire even in adverse weather conditions. The kit is lightweight, easy to carry, and built with durable materials for long-lasting performance."
    },
	
    ];
/*
  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setMobileOpen(false);
        //console.log('setMobileOpen=false');
      } else {
        setMobileOpen(true);
        //console.log('setMobileOpen=true');
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>
   <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <Typography align="left" variant="body1" component={'span'}>

    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
	        <h1>Fire Starters</h1>
        </td>
        <td>
          <img className="RoundedCorners" src={FireStartersPic} style={{height:'120px'}} alt="Fire Starters"></img>
        </td>
      </tr>
      </tbody>
    </table>


    Being able to start a fire when in the outdoors can rarely be a life-threatening situation. However, when in that situation it is critical that you be able to start a fire. Other times it can mean the difference between a great time around a campfire and a not-so-great time sitting out in the cold. Below are some great options for starting a fire when outdoors.

    </Typography>
    <br/>

    {items.map((element)=> (
          <div key={`${element.id}-div`}>
            <Paper key={`${element.id}-paper`} elevation={3}
              sx={{
                p: 2,
                margin: 'auto',
                flexGrow: 1,
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
              }}
            >
            <Grid container key={`${element.id}-container`} spacing={12} rowSpacing={1}>
              <Grid item key={`${element.id}-outer`} xs={12} sm container>
                <Grid item key={`${element.id}-column`} xs container direction="column" spacing={9}>
                  <Grid item key={`${element.id}-inner`} xs>
                    <Typography key={`${element.id}-t1`} gutterBottom variant="subtitle1" component="div">
                      <a href={element.itemLink}><b>{element.itemBoldText}</b></a> {element.itemText}
                    </Typography>
                    <Typography key={`${element.id}-t2`} align="center">
                      <Link key={`${element.id}-l1`} href={element.itemLink}>
                        <Button key={`${element.id}-b1`} variant="contained">Pros & Cons</Button>
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Paper>
          <br/>
      </div>
      ))}

    </div>
    </>
  );
}

export default FireStarters;

