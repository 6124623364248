import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import GarminGPSMAP66sstPic from '../images/GarminGPSMAP66sst.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import HandheldGPSPic from '../../images/HandheldGPS.jpg';


function GarminGPSMAP66sst () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Garmin GPSMAP 66s/st</h1>
          <Link href="https://www.garmin.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ HandheldGPSPic} style={{height:'120px'}} alt="HandheldGPS"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Garmin GPSMAP 66s/st" height="130px" src={GarminGPSMAP66sstPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Garmin GPSMAP 66s/st is a handheld GPS device designed for outdoor enthusiasts, such as hikers, campers, and geocachers, seeking reliable navigation tools. It features a robust build, a bright 3-inch color display, and supports multiple satellite systems (GPS, GLONASS, and Galileo) for improved positioning. The 66st model also includes preloaded topographic maps. Both models offer wireless connectivity and various navigation and tracking features, making them a versatile choice for outdoor navigation.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Rugged and durable design suitable for outdoor use.</li>
<li className="ListItemStyle">Supports multiple satellite systems for more accurate positioning.</li>
<li className="ListItemStyle">Large, bright 3-inch display that is easily readable in sunlight.</li>
<li className="ListItemStyle">Preloaded topographic maps on the 66st model for immediate use.</li>
<li className="ListItemStyle">Wireless connectivity with smartphones and other devices.</li>
<li className="ListItemStyle">Long battery life, suitable for extended outdoor activities.</li>
<li className="ListItemStyle">8 GB of internal memory and a microSD card slot for additional storage.</li>
<li className="ListItemStyle">Comprehensive navigation features including altimeter, barometer, and compass.</li>
<li className="ListItemStyle">BirdsEye Satellite Imagery subscription included for direct downloads.</li>
<li className="ListItemStyle">IPX7 water resistance rating allows use in various weather conditions.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Relatively high cost compared to some other handheld GPS devices.</li>
<li className="ListItemStyle">Complex interface may have a steep learning curve for new users.</li>
<li className="ListItemStyle">Lacks a touchscreen, which may be a disadvantage for some users.</li>
<li className="ListItemStyle">Limited and basic camera features compared to multifunctional devices like smartphones.</li>
<li className="ListItemStyle">Heavier and bulkier than some other handheld GPS units.</li>
<li className="ListItemStyle">Menu navigation can feel slow and cumbersome.</li>
<li className="ListItemStyle">Some users report issues with syncing and connectivity.</li>
<li className="ListItemStyle">Initial satellite acquisition can take longer than expected.</li>
<li className="ListItemStyle">Does not include a lithium-ion battery as standard; relies on AA batteries.</li>
<li className="ListItemStyle">Occasional reports of software glitches or bugs.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default GarminGPSMAP66sst;

