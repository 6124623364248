import * as React from 'react';
import Grid  from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
// import {useState, useEffect} from "react";
// import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';

import HuntingPacksPic from '../images/HuntingPacks.jpg';

//import ALPSOutdoorZCommanderPackBagPic from './images/ALPSOutdoorZCommanderPackBag.png';
//import Badlands2200Pic from './images/Badlands2200.png';
//import EberlestockX2PackPic from './images/EberlestockX2Pack.png';
//import KUIUProPackSystemPic from './images/KUIUProPackSystem.png';
//import MysteryRanchMetcalfPic from './images/MysteryRanchMetcalf.png';


function HuntingPacks () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const items = [
        {
      //manuLink:"[get link from company website]",
      //imageAlt:"ALPS OutdoorZ Commander + Pack Bag",
      //imageRef:ALPSOutdoorZCommanderPackBagPic,
      id:"ALPSOutdoorZCommanderPackBag",
      itemLink:"../HuntingGear/HuntingPacks/ALPSOutdoorZCommanderPackBag",
      itemBoldText:"ALPS OutdoorZ Commander + Pack Bag:",
      itemText:"The ALPS OutdoorZ Commander + Pack Bag is a versatile and durable external frame backpack designed for hunting and outdoor adventures. It features a spacious pack bag along with a stand-alone external frame that can be used for hauling meat or other heavy loads. This backpack is built from rugged materials, with adjustable components for a personalized fit, and includes several compartments and attachment points for accessories."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Badlands 2200",
      //imageRef:Badlands2200Pic,
      id:"HuntingPacks",
      itemLink:"../HuntingGear/HuntingPacks/Badlands2200",
      itemBoldText:"Badlands 2200:",
      itemText:"The Badlands 2200 is a versatile hunting backpack known for its durable construction, ergonomic design, and ample storage options. This pack is designed to support hunters on extended trips, providing comfort through its load-lifting features and high-quality materials. With a strong focus on utility and user experience, the Badlands 2200 is a favorite among outdoor enthusiasts."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Eberlestock X2 Pack",
      //imageRef:EberlestockX2PackPic,
      id:"EberlestockX2Pack",
      itemLink:"../HuntingGear/HuntingPacks/EberlestockX2Pack",
      itemBoldText:"Eberlestock X2 Pack:",
      itemText:"The Eberlestock X2 Pack is a versatile and durable backpack designed for outdoor enthusiasts, particularly hunters. It offers ample storage, excellent organization features, and is known for its rugged build quality. With an emphasis on carrying capacity and comfort, the X2 Pack accommodates a variety of equipment and accessories, making it ideal for extended trips in various terrains."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"KUIU Pro Pack System",
      //imageRef:KUIUProPackSystemPic,
      id:"KUIUProPackSystem",
      itemLink:"../HuntingGear/HuntingPacks/KUIUProPackSystem",
      itemBoldText:"KUIU Pro Pack System:",
      itemText:"The KUIU Pro Pack System is a versatile and high-performance backpack designed specifically for hunters and outdoor enthusiasts. It is known for its durability, lightweight design, and customizable features, providing ample storage and organization for various gear and necessities during outdoor adventures."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Mystery Ranch Metcalf",
      //imageRef:MysteryRanchMetcalfPic,
      id:"MysteryRanchMetcalf",
      itemLink:"../HuntingGear/HuntingPacks/MysteryRanchMetcalf",
      itemBoldText:"Mystery Ranch Metcalf:",
      itemText:"The Mystery Ranch Metcalf is a versatile and durable backpack designed for hunting and outdoor adventures. It offers a balance of comfort, support, and high load carrying capacity. Its modular design includes a detachable pack and frame, allowing for adaptability during different phases of a hunt or outdoor excursion. Known for its superior craftsmanship and high-quality materials, it is a preferred choice for serious hunters and outdoor enthusiasts."
    },
	
    ];
/*
  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setMobileOpen(false);
        //console.log('setMobileOpen=false');
      } else {
        setMobileOpen(true);
        //console.log('setMobileOpen=true');
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>
   <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <Typography align="left" variant="body1" component={'span'}>

    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
	        <h1>Hunting Packs</h1>
        </td>
        <td>
          <img className="RoundedCorners" src={HuntingPacksPic} style={{height:'120px'}} alt="Hunting Packs"></img>
        </td>
      </tr>
      </tbody>
    </table>


    Having a comfortable hunting pack that has the necessary storage to carry everything you need can make a hunt much more enjoyable. Whether you are on a day hunt or plan to be out several days, your pack is an important thing to have. Below are some fantastic options for your next hunt.

    </Typography>
    <br/>

    {items.map((element)=> (
          <div key={`${element.id}-div`}>
            <Paper key={`${element.id}-paper`} elevation={3}
              sx={{
                p: 2,
                margin: 'auto',
                flexGrow: 1,
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
              }}
            >
            <Grid container key={`${element.id}-container`} spacing={12} rowSpacing={1}>
              <Grid item key={`${element.id}-outer`} xs={12} sm container>
                <Grid item key={`${element.id}-column`} xs container direction="column" spacing={9}>
                  <Grid item key={`${element.id}-inner`} xs>
                    <Typography key={`${element.id}-t1`} gutterBottom variant="subtitle1" component="div">
                      <a href={element.itemLink}><b>{element.itemBoldText}</b></a> {element.itemText}
                    </Typography>
                    <Typography key={`${element.id}-t2`} align="center">
                      <Link key={`${element.id}-l1`} href={element.itemLink}>
                        <Button key={`${element.id}-b1`} variant="contained">Pros & Cons</Button>
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Paper>
          <br/>
      </div>
      ))}

    </div>
    </>
  );
}

export default HuntingPacks;

