import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import MenuIcon from '@mui/icons-material/Menu';
import jmoHeader from '../images/JMO-Header-small.png';
import '../components/jmo.css';
import '../components/campingmenu';
import { NavLink } from "react-router-dom";
import ProductMenuMenu from '../components/productmenu';
/* import CampingMenu from '../components/campingmenu';
import GearMenu from '../components/gearmenu';
import ElectronicsMenu from '../components/electronicsmenu'; */
import { AppBar, Toolbar, Typography, IconButton, Drawer, List, ListItem, ListItemText, CssBaseline, useTheme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import ProductMenu from './productmenu';

const drawerWidth = 250;
const drawerMarginTop = 128;


const AppBarStyled = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
}));

const DrawerStyled = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    marginTop: "2px"
  },
}));

/* const DrawerHeader = styled('div')(({ theme }) => ({
  ...theme.mixins.toolbar,
})); */



function JMOMenu(props) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileOpen, setMobileOpen] = React.useState(false);
  // const { window } = props;

/*   const MainContent = styled(Box)(({ open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: 'margin 0.3s',
    marginLeft: open ? drawerWidth : 0,
  })); */

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);   
  };

  const drawer = (
    
    <div style={{marginTop: drawerMarginTop}}>
      
    <Divider/>
      <List>
        <ListItem key="Home" disablePadding>
          <ListItemButton LinkComponent={NavLink} to="/">
            <ListItemText><b>Home</b></ListItemText>
          </ListItemButton>
        </ListItem>
      </List>
      <Divider/>
      <ProductMenu/>
{/*       <CampingMenu/>
      <GearMenu/>
      <ElectronicsMenu/> */}


      <Divider />
    </div>
  );

  // Remove this const when copying and pasting into your project.
  //const container = window !== undefined ? () => window().document.body : undefined;
  
   
  return (

<div>
{/* <a href ="/">
        <img className="App-logo"  src={jmoLogo} alt="Join Me Outdoors"></img>
      </a>  */}

     <Box sx={{ display: 'flex' }}> 

      <CssBaseline />

      <AppBarStyled position="fixed">
          <Toolbar>
            {isMobile && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            )}
            <Typography variant="h6" noWrap>
              <a href="/">
                <img src={jmoHeader} style={{marginTop: "7px"}} href="/" alt="Join Me Outdoors"/>
              </a>
            </Typography>
          </Toolbar>
        </AppBarStyled>
        <nav>
          {isMobile ? (
            <DrawerStyled
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </DrawerStyled>
          ) : (
            <DrawerStyled variant="permanent" open>
              {drawer}
            </DrawerStyled>
          )}
        </nav>
    </Box>

    </div>
  );
}

JMOMenu.propTypes = {
  window: PropTypes.func,
};

export default JMOMenu;
