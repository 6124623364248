import * as React from 'react';
import Grid  from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import {useState, useEffect} from "react";
//import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';

import CampingStovesPic from '../images/CampingStoves.jpg';

//import BioliteCampStove2Pic from './images/BioliteCampStove2.png';
//import ColemanClassicPropaneStovePic from './images/ColemanClassicPropaneStove.png';
//import JetboilFlashCookingSystemPic from './images/JetboilFlashCookingSystem.png';
//import MSRPocketRocket2Pic from './images/MSRPocketRocket2.png';
//import SnowPeakLiteMaxTitaniumStovePic from './images/SnowPeakLiteMaxTitaniumStove.png';


function CampingStoves () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const items = [
        {
      //manuLink:"[get link from company website]",
      //imageAlt:"Biolite CampStove 2+",
      //imageRef:BioliteCampStove2Pic,
      id:"BioliteCampStove2",
      itemLink:"../Camping/CampingStoves/BioliteCampStove2",
      itemBoldText:"Biolite CampStove 2+:",
      itemText:"The Biolite CampStove 2+ is a portable camping stove that uses wood as fuel and generates electricity to charge devices via a USB port. It features an integrated battery, a fan for improved combustion, and a lightweight design, making it ideal for outdoor enthusiasts who want to cook meals and charge devices while camping."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Coleman Classic Propane Stove",
      //imageRef:ColemanClassicPropaneStovePic,
      id:"ColemanClassicPropaneStove",
      itemLink:"../Camping/CampingStoves/ColemanClassicPropaneStove",
      itemBoldText:"Coleman Classic Propane Stove:",
      itemText:"The Coleman Classic Propane Stove is a portable and reliable camping stove designed for outdoor cooking. It features two adjustable burners that provide a total of 20,000 BTUs of cooking power, allowing for efficient cooking in various outdoor conditions. The stove is equipped with wind-blocking panels to shield the burners from the wind, ensuring consistent performance. It is compact, easy to transport, and operates on propane cylinders, making it a popular choice for campers and outdoor enthusiasts."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Jetboil Flash Cooking System",
      //imageRef:JetboilFlashCookingSystemPic,
      id:"JetboilFlashCookingSystem",
      itemLink:"../Camping/CampingStoves/JetboilFlashCookingSystem",
      itemBoldText:"Jetboil Flash Cooking System:",
      itemText:"The Jetboil Flash Cooking System is a compact and efficient personal cooking system designed for outdoor enthusiasts. It features a fast boiling time, integrated burner and cooking cup, and a push-button igniter, making it ideal for quick meals and hot drinks on the go."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"MSR PocketRocket 2",
      //imageRef:MSRPocketRocket2Pic,
      id:"MSRPocketRocket2",
      itemLink:"../Camping/CampingStoves/MSRPocketRocket2",
      itemBoldText:"MSR PocketRocket 2:",
      itemText:"The MSR PocketRocket 2 is a compact and lightweight backpacking stove designed for outdoor enthusiasts. It is known for its fast boiling times, ease of use, and reliability in various weather conditions. The stove features a simple design with a durable build, making it a popular choice for hikers and campers who need a portable cooking solution."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Snow Peak LiteMax Titanium Stove",
      //imageRef:SnowPeakLiteMaxTitaniumStovePic,
      id:"SnowPeakLiteMaxTitaniumStove",
      itemLink:"../Camping/CampingStoves/SnowPeakLiteMaxTitaniumStove",
      itemBoldText:"Snow Peak LiteMax Titanium Stove:",
      itemText:"The Snow Peak LiteMax Titanium Stove is a lightweight and compact backpacking stove designed for outdoor enthusiasts. Made from titanium and aluminum, it offers a balance of durability and weight savings. This stove is ideal for those who prioritize a minimal pack weight and efficient cooking performance in their outdoor adventures."
    },
	
    ];
/*
  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setMobileOpen(false);
        //console.log('setMobileOpen=false');
      } else {
        setMobileOpen(true);
        //console.log('setMobileOpen=true');
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>
   <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <Typography align="left" variant="body1" component={'span'}>

    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
	        <h1>Camping Stoves</h1>
        </td>
        <td>
          <img className="RoundedCorners" src={CampingStovesPic} style={{height:'120px'}} alt="Camping Stoves"></img>
        </td>
      </tr>
      </tbody>
    </table>


    Cooking outdoors is special. Whether it is making breakfast first thing in the morning or cooking steaks at night. Very few things are as relaxing as cooking outdoors. Having the right camp stove can make the difference between having a relaxing or frustrating time. Here are some camp stoves that have made it to the top of the list.

    </Typography>
    <br/>

    {items.map((element)=> (
          <div key={`${element.id}-div`}>
            <Paper key={`${element.id}-paper`} elevation={3}
              sx={{
                p: 2,
                margin: 'auto',
                flexGrow: 1,
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
              }}
            >
            <Grid container key={`${element.id}-container`} spacing={12} rowSpacing={1}>
              <Grid item key={`${element.id}-outer`} xs={12} sm container>
                <Grid item key={`${element.id}-column`} xs container direction="column" spacing={9}>
                  <Grid item key={`${element.id}-inner`} xs>
                    <Typography key={`${element.id}-t1`} gutterBottom variant="subtitle1" component="div">
                      <a href={element.itemLink}><b>{element.itemBoldText}</b></a> {element.itemText}
                    </Typography>
                    <Typography key={`${element.id}-t2`} align="center">
                      <Link key={`${element.id}-l1`} href={element.itemLink}>
                        <Button key={`${element.id}-b1`} variant="contained">Pros & Cons</Button>
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Paper>
          <br/>
      </div>
      ))}

    </div>
    </>
  );
}

export default CampingStoves;

