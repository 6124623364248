import * as React from 'react';
import Grid  from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
// import {useState, useEffect} from "react";
// import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';

import HuntingKnivesPic from '../images/HuntingKnives.jpg';

//import BenchmadeHiddenCanyonHunterPic from './images/BenchmadeHiddenCanyonHunter.png';
//import BuckKnives119SpecialPic from './images/BuckKnives119Special.png';
//import ESEELaserStrikePic from './images/ESEELaserStrike.png';
//import KABARBeckerBK2CampanionPic from './images/KABARBeckerBK2Campanion.png';
//import MoraknivCompanionHeavyDutyPic from './images/MoraknivCompanionHeavyDuty.png';


function HuntingKnives () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const items = [
        {
      //manuLink:"[get link from company website]",
      //imageAlt:"Benchmade Hidden Canyon Hunter",
      //imageRef:BenchmadeHiddenCanyonHunterPic,
      id:"BenchmadeHiddenCanyonHunter",
      itemLink:"../HuntingGear/HuntingKnives/BenchmadeHiddenCanyonHunter",
      itemBoldText:"Benchmade Hidden Canyon Hunter:",
      itemText:"The Benchmade Hidden Canyon Hunter is a compact fixed-blade hunting knife known for its superior quality, durability, and performance in field dressing and hunting tasks. Crafted with precision, it features a full-tang CPM-S30V steel blade that offers excellent edge retention and corrosion resistance, coupled with ergonomic and durable handle options, making it a reliable companion for hunters."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Buck Knives 119 Special",
      //imageRef:BuckKnives119SpecialPic,
      id:"BuckKnives119Special",
      itemLink:"../HuntingGear/HuntingKnives/BuckKnives119Special",
      itemBoldText:"Buck Knives 119 Special:",
      itemText:"The Buck Knives 119 Special is a classic hunting knife known for its reliability and craftsmanship. It features a 6-inch stainless steel clip blade, an ergonomically designed handle made of phenolic material, and includes a genuine leather sheath for safe storage. It is widely appreciated for its rugged design, sharpness, and durability, making it a popular choice among outdoor enthusiasts and hunters."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"ESEE Laser Strike",
      //imageRef:ESEELaserStrikePic,
      id:"ESEELaserStrike",
      itemLink:"../HuntingGear/HuntingKnives/ESEELaserStrike",
      itemBoldText:"ESEE Laser Strike:",
      itemText:"The ESEE Laser Strike is a fixed-blade survival knife known for its durability, versatile design, and strong ergonomic grip, making it a favorite among outdoor enthusiasts and survivalists. It features a 4.75-inch high carbon steel blade with a black powder coating, combined with a removable Micarta handle. The knife also includes a fire-starting system and a durable Kydex sheath."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"KA-BAR Becker BK2 Campanion",
      //imageRef:KABARBeckerBK2CampanionPic,
      id:"KABARBeckerBK2Campanion",
      itemLink:"../HuntingGear/HuntingKnives/KABARBeckerBK2Campanion",
      itemBoldText:"KA-BAR Becker BK2 Campanion:",
      itemText:"The KA-BAR Becker BK2 Campanion is a robust, fixed-blade survival knife designed for heavy-duty use. Known for its durability and versatility, it is a favored tool among outdoor enthusiasts, campers, and those in need of a reliable cutting instrument in harsh environments."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Morakniv Companion Heavy Duty",
      //imageRef:MoraknivCompanionHeavyDutyPic,
      id:"MoraknivCompanionHeavyDuty",
      itemLink:"../HuntingGear/HuntingKnives/MoraknivCompanionHeavyDuty",
      itemBoldText:"Morakniv Companion Heavy Duty:",
      itemText:"The Morakniv Companion Heavy Duty is a robust and reliable fixed-blade knife designed for outdoor enthusiasts, bushcrafters, and survivalists. It features a thicker 4.1-inch stainless steel blade with a durable Scandi grind that offers exceptional strength and edge retention, making it ideal for heavy-duty tasks. The ergonomic handle provides a comfortable and secure grip, even in wet conditions, while the included plastic sheath ensures safe storage and easy portability."
    },
	
    ];
/*
  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setMobileOpen(false);
        //console.log('setMobileOpen=false');
      } else {
        setMobileOpen(true);
        //console.log('setMobileOpen=true');
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>
   <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <Typography align="left" variant="body1" component={'span'}>

    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
	        <h1>Hunting Knives</h1>
        </td>
        <td>
          <img className="RoundedCorners" src={HuntingKnivesPic} style={{height:'120px'}} alt="Hunting Knives"></img>
        </td>
      </tr>
      </tbody>
    </table>


    The right hunting knife is an incredibly valuable tool used to finish out the hunt. Processing the game you’ve worked so hard to harvest is where the work begins. That work can be made much easier when you have a great knife for the job. Below are some great options to finish out your successful hunt.


    </Typography>
    <br/>

    {items.map((element)=> (
          <div key={`${element.id}-div`}>
            <Paper key={`${element.id}-paper`} elevation={3}
              sx={{
                p: 2,
                margin: 'auto',
                flexGrow: 1,
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
              }}
            >
            <Grid container key={`${element.id}-container`} spacing={12} rowSpacing={1}>
              <Grid item key={`${element.id}-outer`} xs={12} sm container>
                <Grid item key={`${element.id}-column`} xs container direction="column" spacing={9}>
                  <Grid item key={`${element.id}-inner`} xs>
                    <Typography key={`${element.id}-t1`} gutterBottom variant="subtitle1" component="div">
                      <a href={element.itemLink}><b>{element.itemBoldText}</b></a> {element.itemText}
                    </Typography>
                    <Typography key={`${element.id}-t2`} align="center">
                      <Link key={`${element.id}-l1`} href={element.itemLink}>
                        <Button key={`${element.id}-b1`} variant="contained">Pros & Cons</Button>
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Paper>
          <br/>
      </div>
      ))}

    </div>
    </>
  );
}

export default HuntingKnives;

