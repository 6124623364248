import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import WesternMountaineeringAlpinlitePic from '../images/WesternMountaineeringAlpinlite.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import SleepingBagsPic from '../../images/SleepingBags.jpg';


function WesternMountaineeringAlpinlite () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Western Mountaineering Alpinlite</h1>
          <Link href="https://www.westernmountaineering.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ SleepingBagsPic} style={{height:'120px'}} alt="SleepingBags"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Western Mountaineering Alpinlite" height="130px" src={WesternMountaineeringAlpinlitePic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Western Mountaineering Alpinlite is a high-performance sleeping bag designed for backpackers and mountaineers who need a lightweight and warm option for cold weather camping. Built using premium materials, including 850+ fill goose down, this sleeping bag offers excellent insulation and a focus on comfort, making it a top choice for those venturing into alpine environments.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Extremely lightweight, making it ideal for backpacking.</li>
<li className="ListItemStyle">High-quality 850+ fill goose down provides excellent warmth.</li>
<li className="ListItemStyle">Durable and well-constructed for long-term use.</li>
<li className="ListItemStyle">Highly packable, conserving space in a backpack.</li>
<li className="ListItemStyle">Spacious footbox and comfortable fit for better sleep quality.</li>
<li className="ListItemStyle">Efficient draft collar and zipper tube to minimize heat loss.</li>
<li className="ListItemStyle">Breathable fabric reduces moisture buildup inside the bag.</li>
<li className="ListItemStyle">Offers good temperature regulation in cold weather conditions.</li>
<li className="ListItemStyle">Comes with a storage bag and stuff sack for versatility.</li>
<li className="ListItemStyle">Great reputation and trustworthy brand known for quality.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">High price point may not be affordable for all budgets.</li>
<li className="ListItemStyle">Down fill means it requires extra care around moisture.</li>
<li className="ListItemStyle">Not suitable for extremely wet or humid climates without additional protection.</li>
<li className="ListItemStyle">Limited size options may not fit everyone perfectly.</li>
<li className="ListItemStyle">No synthetic alternative for allergen-sensitive individuals.</li>
<li className="ListItemStyle">May be too warm for summer camping in moderate climates.</li>
<li className="ListItemStyle">Requires proper storage to maintain loft over time.</li>
<li className="ListItemStyle">Zipper can snag if not handled carefully.</li>
<li className="ListItemStyle">Lack of additional features such as inner pockets.</li>
<li className="ListItemStyle">The hood could be cumbersome for those who prefer minimalist designs.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default WesternMountaineeringAlpinlite;

