import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import GarminMontana700iPic from '../images/GarminMontana700i.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import HandheldGPSPic from '../../images/HandheldGPS.jpg';


function GarminMontana700i () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Garmin Montana 700i</h1>
          <Link href="https://buy.garmin.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ HandheldGPSPic} style={{height:'120px'}} alt="HandheldGPS"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Garmin Montana 700i" height="130px" src={GarminMontana700iPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Garmin Montana 700i is a rugged, handheld GPS device designed for outdoor enthusiasts and adventurers. It features a large, glove-friendly touchscreen display, inReach satellite communication capabilities, and multi-GNSS support for accurate navigation in challenging environments. The device also includes preloaded maps and supports custom mapping, making it ideal for hiking, off-roading, and other outdoor activities.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Rugged, durable construction suitable for harsh environments.</li>
<li className="ListItemStyle">Large 5-inch touchscreen display that is easy to read and use.</li>
<li className="ListItemStyle">InReach satellite communication for sending and receiving messages globally.</li>
<li className="ListItemStyle">Multi-GNSS support including GPS, GLONASS, and Galileo for reliable navigation.</li>
<li className="ListItemStyle">Preloaded topographic maps and public land boundaries.</li>
<li className="ListItemStyle">Ability to download BirdsEye Satellite Imagery directly to the device.</li>
<li className="ListItemStyle">Supports wireless connectivity via Bluetooth and ANT+.</li>
<li className="ListItemStyle">Long battery life with a rechargeable lithium-ion battery.</li>
<li className="ListItemStyle">Customizable data fields and option to add additional maps.</li>
<li className="ListItemStyle">Waterproof design with IPX7 rating for water resistance.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Higher price point compared to some other handheld GPS devices.</li>
<li className="ListItemStyle">InReach subscription required for satellite communication features.</li>
<li className="ListItemStyle">Large size may be cumbersome for some users during certain activities.</li>
<li className="ListItemStyle">Can be complex to set up and use for beginners.</li>
<li className="ListItemStyle">Weight may be a concern for ultralight backpacking.</li>
<li className="ListItemStyle">Touchscreen may be slow to respond in very cold conditions.</li>
<li className="ListItemStyle">Limited internal storage for additional maps and data.</li>
<li className="ListItemStyle">InReach features can significantly drain battery when used frequently.</li>
<li className="ListItemStyle">Might not support all international maps without additional purchases.</li>
<li className="ListItemStyle">Software updates occasionally required to address minor bugs.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default GarminMontana700i;

