import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import NikonMonarch5Pic from '../images/NikonMonarch5.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import BinocularsPic from '../../images/Binoculars.jpg';


function NikonMonarch5 () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Nikon Monarch 5</h1>
          <Link href="https://www.nikon.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ BinocularsPic} style={{height:'120px'}} alt="Binoculars"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Nikon Monarch 5" height="130px" src={NikonMonarch5Pic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Nikon Monarch 5 binoculars are a popular choice for wildlife enthusiasts and bird watchers, known for their high-quality optics and rugged design. They provide excellent clarity, brightness, and durability, making them suitable for various outdoor activities.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">High-quality optics with ED (Extra-low Dispersion) glass for sharper images.</li>
<li className="ListItemStyle">Lightweight and portable design, ideal for outdoor use.</li>
<li className="ListItemStyle">Durable, rubber-armored coating for shock resistance.</li>
<li className="ListItemStyle">Waterproof and fog-proof, suitable for all weather conditions.</li>
<li className="ListItemStyle">Wide field of view enhances tracking of moving subjects.</li>
<li className="ListItemStyle">Turn-and-slide rubber eyecups for comfortable viewing.</li>
<li className="ListItemStyle">Multi-layer prism coating for excellent light transmission.</li>
<li className="ListItemStyle">Good for low light conditions with high-quality lenses.</li>
<li className="ListItemStyle">Smooth central focus knob for easy adjustments.</li>
<li className="ListItemStyle">Well-balanced design for comfortable long-term use.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Higher price point compared to some other models.</li>
<li className="ListItemStyle">Limited zoom capability, fixed 8x or 10x magnification options.</li>
<li className="ListItemStyle">Lens caps are not tethered and can be easily lost.</li>
<li className="ListItemStyle">Eye relief may not be sufficient for all eyeglass wearers.</li>
<li className="ListItemStyle">Some may find the neck strap uncomfortable for extended use.</li>
<li className="ListItemStyle">Plastic parts like the focus wheel may be prone to wear.</li>
<li className="ListItemStyle">No image stabilization feature.</li>
<li className="ListItemStyle">Limited color options, typically just black.</li>
<li className="ListItemStyle">Carrying case may be considered basic.</li>
<li className="ListItemStyle">Objective lens covers can be difficult to attach.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default NikonMonarch5;

