import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import LightMyFireSwedishFireSteelPic from '../images/LightMyFireSwedishFireSteel.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import FireStartersPic from '../../images/FireStarters.jpg';


function LightMyFireSwedishFireSteel () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Light My Fire Swedish FireSteel</h1>
          <Link href="https://lightmyfire.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ FireStartersPic} style={{height:'120px'}} alt="FireStarters"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Light My Fire Swedish FireSteel" height="130px" src={LightMyFireSwedishFireSteelPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Light My Fire Swedish FireSteel is a reliable fire starter designed for outdoor enthusiasts. Originally developed for the Swedish Ministry of Defense, this fire steel can be used in all weather conditions and at any altitude. It produces a 3,000°C (5,500°F) spark and is ideal for lighting campfires, stoves, and barbecues. It is known for its durability and can last for approximately 3,000 strikes.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Produces a high temperature spark for reliable fire starting.</li>
<li className="ListItemStyle">Can be used in all weather conditions, including wet environments.</li>
<li className="ListItemStyle">Compact and lightweight, ideal for carrying in a backpack.</li>
<li className="ListItemStyle">Durable and long-lasting, with thousands of strikes per unit.</li>
<li className="ListItemStyle">Does not require flammable liquid fuels.</li>
<li className="ListItemStyle">Consistent performance at all altitudes.</li>
<li className="ListItemStyle">Safety and ease of use with a built-in handle.</li>
<li className="ListItemStyle">Bright spark can also be used as a signal.</li>
<li className="ListItemStyle">Environmentally friendly compared to disposable lighters.</li>
<li className="ListItemStyle">Suitable for a variety of outdoor activities, including camping and hiking.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">May require practice to use effectively, especially for beginners.</li>
<li className="ListItemStyle">Sparks may not work on damp or non-flammable materials without preparation.</li>
<li className="ListItemStyle">Some users may find instruction manual lacking detail.</li>
<li className="ListItemStyle">Can become worn over time, reducing effectiveness.</li>
<li className="ListItemStyle">Small size makes it easy to misplace.</li>
<li className="ListItemStyle">Striker attached with a cord which can come loose if not careful.</li>
<li className="ListItemStyle">Less convenient than lighters for quick ignition.</li>
<li className="ListItemStyle">Replacement availability can be limited depending on location.</li>
<li className="ListItemStyle">Price may be higher than other fire-starting methods.</li>
<li className="ListItemStyle">Functionality heavily relies on using correct technique from the user.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default LightMyFireSwedishFireSteel;

