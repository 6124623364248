import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import BaoFengBFF8HPPic from '../images/BaoFengBFF8HP.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import _2WayRadiosPic from '../../images/2WayRadios.jpg';


function BaoFengBFF8HP () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>BaoFeng BF-F8HP</h1>
          <Link href="https://baofengtech.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ _2WayRadiosPic} style={{height:'120px'}} alt="2WayRadios"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="BaoFeng BF-F8HP" height="130px" src={BaoFengBFF8HPPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The BaoFeng BF-F8HP is a high-power handheld dual band two way radio. It's an upgrade from the popular UV-5R, featuring an upgraded transmission power of 8 watts, improved receiver, and enhanced battery life. It is favored by amateur radio enthusiasts for its affordability, durability, and versatility in operations across various frequency ranges.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Higher transmission power of 8 watts for better range.</li>
<li className="ListItemStyle">Affordable price point compared to other high-powered radios.</li>
<li className="ListItemStyle">Dual-band capability (VHF/UHF) for flexible communication options.</li>
<li className="ListItemStyle">Long battery life with a 2000mAh battery.</li>
<li className="ListItemStyle">Sturdy and durable design suitable for outdoor use.</li>
<li className="ListItemStyle">Wide frequency coverage allows communication on both ham and commercial channels.</li>
<li className="ListItemStyle">Compatible with a range of aftermarket accessories.</li>
<li className="ListItemStyle">Easy to program via computer software.</li>
<li className="ListItemStyle">Improved receiver sensitivity and selectivity.</li>
<li className="ListItemStyle">Three power levels (8W/4W/1W) to conserve battery as needed.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Complex to set up for beginners without prior experience.</li>
<li className="ListItemStyle">Limited warranty and customer service support.</li>
<li className="ListItemStyle">Stock antenna may not provide optimal performance; users may need to purchase an upgraded antenna.</li>
<li className="ListItemStyle">Reputation for inconsistent quality control between individual units.</li>
<li className="ListItemStyle">Must have an appropriate license to operate on ham frequencies legally.</li>
<li className="ListItemStyle">Audio quality may be less crisp compared to higher-end models.</li>
<li className="ListItemStyle">User interface and navigation can be confusing.</li>
<li className="ListItemStyle">Programming software not very user-friendly.</li>
<li className="ListItemStyle">No Bluetooth compatibility for wireless operation.</li>
<li className="ListItemStyle">Some users report issues with the longevity of the battery after extended use.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default BaoFengBFF8HP;

