import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import UnidenSX5072CKHSPic from '../images/UnidenSX5072CKHS.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import _2WayRadiosPic from '../../images/2WayRadios.jpg';


function UnidenSX5072CKHS () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Uniden SX507-2CKHS</h1>
          <Link href="https://uniden.com/" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ _2WayRadiosPic} style={{height:'120px'}} alt="2WayRadios"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Uniden SX507-2CKHS" height="130px" src={UnidenSX5072CKHSPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Uniden SX507-2CKHS is a set of long-range FRS two-way radios designed for outdoor adventures, offering up to 50 miles of communication range in optimal conditions. It features 22 channels and 121 privacy codes to minimize interference, NOAA weather alerts for safety, and is both waterproof and rugged against tough conditions. Additionally, it comes with rechargeable batteries and a hands-free operation mode.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Long communication range up to 50 miles.</li>
<li className="ListItemStyle">22 channels with 121 privacy codes for clear communication.</li>
<li className="ListItemStyle">NOAA weather alerts for emergency preparedness.</li>
<li className="ListItemStyle">Waterproof design for use in wet conditions.</li>
<li className="ListItemStyle">Rugged and durable construction.</li>
<li className="ListItemStyle">Rechargeable batteries included.</li>
<li className="ListItemStyle">Hands-free VOX operation mode.</li>
<li className="ListItemStyle">LED flashlight function for emergencies.</li>
<li className="ListItemStyle">Compatible with other FRS radios.</li>
<li className="ListItemStyle">Clear sound quality and good reception.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Actual range may be significantly less in real-world conditions.</li>
<li className="ListItemStyle">Battery life might be shorter during prolonged use.</li>
<li className="ListItemStyle">Charging time can be long.</li>
<li className="ListItemStyle">No GPS or location tracking features.</li>
<li className="ListItemStyle">VOX function might be too sensitive or unreliable for some users.</li>
<li className="ListItemStyle">Limited to FRS frequencies; no GMRS capability.</li>
<li className="ListItemStyle">Larger size may not be convenient for carrying in small pockets.</li>
<li className="ListItemStyle">No Bluetooth connectivity for device pairing.</li>
<li className="ListItemStyle">Squelch control might not eliminate all background noise.</li>
<li className="ListItemStyle">Price might be higher compared to other basic models.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default UnidenSX5072CKHS;

