import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import SnowPeakLiteMaxTitaniumStovePic from '../images/SnowPeakLiteMaxTitaniumStove.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import CampingStovesPic from '../../images/CampingStoves.jpg';


function SnowPeakLiteMaxTitaniumStove () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Snow Peak LiteMax Titanium Stove</h1>
          <Link href="https://www.snowpeak.com/products/litemax-titanium-stove" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ CampingStovesPic} style={{height:'120px'}} alt="CampingStoves"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Snow Peak LiteMax Titanium Stove" height="130px" src={SnowPeakLiteMaxTitaniumStovePic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Snow Peak LiteMax Titanium Stove is a lightweight and compact backpacking stove designed for outdoor enthusiasts. Made from titanium and aluminum, it offers a balance of durability and weight savings. This stove is ideal for those who prioritize a minimal pack weight and efficient cooking performance in their outdoor adventures.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Extremely lightweight at only 1.9 ounces, making it ideal for backpacking.</li>
<li className="ListItemStyle">Compact design allows it to fit easily in small spaces within a backpack.</li>
<li className="ListItemStyle">Made from durable titanium and aluminum materials.</li>
<li className="ListItemStyle">Offers a strong and stable flame for efficient cooking.</li>
<li className="ListItemStyle">Compatible with standard isobutane-propane fuel canisters.</li>
<li className="ListItemStyle">Easy to set up and use, even for beginners.</li>
<li className="ListItemStyle">Adjustable flame control for precise cooking.</li>
<li className="ListItemStyle">Highly portable, with foldable arms for easy storage.</li>
<li className="ListItemStyle">Reliable performance in various weather conditions.</li>
<li className="ListItemStyle">Efficient fuel consumption, extending the life of fuel canisters.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Does not include a built-in ignition system, requiring a separate lighter or matches.</li>
<li className="ListItemStyle">Limited pot support size, which may not be suitable for larger cookware.</li>
<li className="ListItemStyle">Higher price point compared to some other backpacking stoves.</li>
<li className="ListItemStyle">Requires careful handling due to its lightweight and delicate construction.</li>
<li className="ListItemStyle">May not perform as well in extremely windy conditions without a windscreen.</li>
<li className="ListItemStyle">Fuel canisters are sold separately, adding to overall cost.</li>
<li className="ListItemStyle">Not suitable for large group cooking due to its small size.</li>
<li className="ListItemStyle">Titanium construction can lead to uneven heat distribution.</li>
<li className="ListItemStyle">Can be difficult to use with gloves in cold weather.</li>
<li className="ListItemStyle">Limited to canister fuel, which may not be available in all regions.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default SnowPeakLiteMaxTitaniumStove;

