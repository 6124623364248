import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import ThermaRestHyperionPic from '../images/ThermaRestHyperion.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import SleepingBagsPic from '../../images/SleepingBags.jpg';


function ThermaRestHyperion () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Therm-a-Rest Hyperion</h1>
          <Link href="https://www.thermarest.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ SleepingBagsPic} style={{height:'120px'}} alt="SleepingBags"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Therm-a-Rest Hyperion" height="130px" src={ThermaRestHyperionPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Therm-a-Rest Hyperion is an ultra-lightweight and highly packable sleeping bag designed for backpackers and campers who prioritize low weight and compact size without sacrificing warmth. It features high-quality down insulation and a streamlined design to provide excellent thermal efficiency in cold environments.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Extremely lightweight, making it perfect for minimalist backpacking.</li>
<li className="ListItemStyle">Highly compressible, allowing for easy packing in small backpacks.</li>
<li className="ListItemStyle">Excellent warmth-to-weight ratio with 900-fill Nikwax Hydrophobic Down.</li>
<li className="ListItemStyle">Water-resistant down helps maintain warmth in damp conditions.</li>
<li className="ListItemStyle">Efficient design with zoned insulation to maximize warmth where needed.</li>
<li className="ListItemStyle">Includes a storage bag and compression sack for versatility in packing.</li>
<li className="ListItemStyle">Mummy bag shape enhances thermal efficiency and reduces weight.</li>
<li className="ListItemStyle">Breathable and soft fabric provides a comfortable sleep experience.</li>
<li className="ListItemStyle">Draft collar and zipper baffle prevent cold air from entering.</li>
<li className="ListItemStyle">Available in multiple sizes to fit different body types.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Premium price point may not be suitable for budget-conscious buyers.</li>
<li className="ListItemStyle">The ultralight materials may not be as durable over long-term use.</li>
<li className="ListItemStyle">Slim cut might feel restrictive for users who prefer more room.</li>
<li className="ListItemStyle">Not suitable for extremely cold winter conditions.</li>
<li className="ListItemStyle">Some users might find the zipper snagging occasionally.</li>
<li className="ListItemStyle">Limited features due to its minimalist design focus.</li>
<li className="ListItemStyle">Requires proper care and storage to maintain down loft.</li>
<li className="ListItemStyle">Drying the bag after exposure to moisture can be time-consuming.</li>
<li className="ListItemStyle">Might require liners for added warmth in colder climates.</li>
<li className="ListItemStyle">Not machine-washable, requiring specific cleaning methods.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default ThermaRestHyperion;

