import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import KABARBeckerBK2CampanionPic from '../images/KABARBeckerBK2Campanion.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import HuntingKnivesPic from '../../images/HuntingKnives.jpg';


function KABARBeckerBK2Campanion () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>KA-BAR Becker BK2 Campanion</h1>
          <Link href="https://www.kabar.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ HuntingKnivesPic} style={{height:'120px'}} alt="Hunting Knives"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="KA-BAR Becker BK2 Campanion" height="130px" src={KABARBeckerBK2CampanionPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The KA-BAR Becker BK2 Campanion is a robust, fixed-blade survival knife designed for heavy-duty use. Known for its durability and versatility, it is a favored tool among outdoor enthusiasts, campers, and those in need of a reliable cutting instrument in harsh environments.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Extremely durable 1095 Cro-Van steel blade.</li>
<li className="ListItemStyle">Thick, full tang design for increased strength.</li>
<li className="ListItemStyle">Versatile for various outdoor tasks including chopping and splitting.</li>
<li className="ListItemStyle">Comfortable and ergonomic handle grip.</li>
<li className="ListItemStyle">Heavy-duty and can withstand tough conditions.</li>
<li className="ListItemStyle">Comes with a sturdy, protective sheath.</li>
<li className="ListItemStyle">Blade holds a sharp edge well.</li>
<li className="ListItemStyle">Ideal for survival and bushcraft needs.</li>
<li className="ListItemStyle">Excellent weight distribution for heavy tasks.</li>
<li className="ListItemStyle">High-quality construction and materials.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Heavier than other knives in the same category, which may not suit all users.</li>
<li className="ListItemStyle">Requires regular maintenance to prevent rust if not properly cared for.</li>
<li className="ListItemStyle">Sheath may be considered bulky by some users.</li>
<li className="ListItemStyle">Not ideal for delicate or precision tasks due to its size and weight.</li>
<li className="ListItemStyle">Handle scales may become slippery when wet.</li>
<li className="ListItemStyle">Limited in use for fine carving tasks.</li>
<li className="ListItemStyle">Blade finish can wear off with heavy use.</li>
<li className="ListItemStyle">Can be cumbersome for carry unless securely attached to gear.</li>
<li className="ListItemStyle">Initial cost may be higher than other options.</li>
<li className="ListItemStyle">Requires a bowie-style grinding for sharpening, which may be difficult for beginners.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default KABARBeckerBK2Campanion;

