import { Grid } from "@mui/material";

function About() {
  return (
    <>
      <Grid container justifyContent={"center"}>
        <Grid>
          <h1>About</h1>
          <p>This is About page</p>
        </Grid>
      </Grid>
    </>
  );
}

export default About;