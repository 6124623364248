import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import ColemanPortableCampingQuadChairwith4CanCoolerPic from '../images/ColemanPortableCampingQuadChairwith4CanCooler.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import CampChairsPic from '../../images/CampChairs.jpg';


function ColemanPortableCampingQuadChairwith4CanCooler () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Coleman Portable Camping Quad Chair with 4-Can Cooler</h1>
          <Link href="https://www.coleman.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={CampChairsPic} style={{height:'120px'}} alt="Camp Chairs"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Coleman Portable Camping Quad Chair with 4-Can Cooler" height="130px" src={ColemanPortableCampingQuadChairwith4CanCoolerPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Coleman Portable Camping Quad Chair with 4-Can Cooler is a versatile and comfortable folding chair designed for outdoor activities such as camping, tailgating, and picnics. It features a built-in cooler pouch that can hold up to four cans, a mesh cup holder, a storage pocket, and a cushioned seat and backrest for enhanced comfort. The chair is made with a sturdy steel frame and durable fabric, supporting up to 325 pounds. It is easy to fold and transport, making it a convenient choice for outdoor enthusiasts.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Built-in cooler pouch holds up to four cans.</li>
<li className="ListItemStyle">Comfortable cushioned seat and backrest.</li>
<li className="ListItemStyle">Sturdy steel frame supports up to 325 pounds.</li>
<li className="ListItemStyle">Includes a mesh cup holder for convenience.</li>
<li className="ListItemStyle">Storage pocket for personal items.</li>
<li className="ListItemStyle">Easy to fold and transport.</li>
<li className="ListItemStyle">Durable fabric suitable for outdoor use.</li>
<li className="ListItemStyle">Affordable price point.</li>
<li className="ListItemStyle">Available in multiple colors.</li>
<li className="ListItemStyle">Ideal for various outdoor activities.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Heavier than some other portable chairs.</li>
<li className="ListItemStyle">Cooler pouch may not keep drinks cold for extended periods.</li>
<li className="ListItemStyle">Limited color options may not suit all preferences.</li>
<li className="ListItemStyle">Cup holder may not fit larger bottles.</li>
<li className="ListItemStyle">Fabric may fade with prolonged sun exposure.</li>
<li className="ListItemStyle">Not as compact as some ultralight chairs.</li>
<li className="ListItemStyle">May not be suitable for very tall individuals.</li>
<li className="ListItemStyle">Armrests are not adjustable.</li>
<li className="ListItemStyle">Storage pocket is relatively small.</li>
<li className="ListItemStyle">May require additional padding for extended sitting.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default ColemanPortableCampingQuadChairwith4CanCooler;

