import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import KUIUProPackSystemPic from '../images/KUIUProPackSystem.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import HuntingPacksPic from '../../images/HuntingPacks.jpg';


function KUIUProPackSystem () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>KUIU Pro Pack System</h1>
          <Link href="https://www.kuiu.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ HuntingPacksPic} style={{height:'120px'}} alt="Hunting Packs"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="KUIU Pro Pack System" height="130px" src={KUIUProPackSystemPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The KUIU Pro Pack System is a versatile and high-performance backpack designed specifically for hunters and outdoor enthusiasts. It is known for its durability, lightweight design, and customizable features, providing ample storage and organization for various gear and necessities during outdoor adventures.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Lightweight design minimizes carrying burden during long treks.</li>
<li className="ListItemStyle">Modular system allows for customization and adaptability for different needs.</li>
<li className="ListItemStyle">High-quality, durable materials withstand harsh outdoor conditions.</li>
<li className="ListItemStyle">Ergonomic design ensures comfortable carry, even with heavy loads.</li>
<li className="ListItemStyle">Ample storage options with various compartments and pockets.</li>
<li className="ListItemStyle">Water-resistant to keep gear dry in wet conditions.</li>
<li className="ListItemStyle">Ventilated back panel enhances airflow and reduces sweat build-up.</li>
<li className="ListItemStyle">Compression straps help secure loads and improve stability.</li>
<li className="ListItemStyle">Quick-access pockets for frequently used items like GPS or snacks.</li>
<li className="ListItemStyle">Adjustable harness system for a personalized and secure fit.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Premium price point may be prohibitive for some budgets.</li>
<li className="ListItemStyle">Complexity of the modular system may require a learning curve for new users.</li>
<li className="ListItemStyle">Limited availability in certain regions or retailers.</li>
<li className="ListItemStyle">Minimalist design may not appeal to all users seeking more features.</li>
<li className="ListItemStyle">May require additional purchases for specific add-ons or accessories.</li>
<li className="ListItemStyle">Some users may find the shoulder straps less padded compared to competitors.</li>
<li className="ListItemStyle">Occasional difficulty in accessing items at the bottom of fully packed bags.</li>
<li className="ListItemStyle">Initial setup and adjustments might be time-consuming.</li>
<li className="ListItemStyle">Color options may be limited, affecting personalization.</li>
<li className="ListItemStyle">Not designed primarily for non-hunting outdoor activities, which may limit its use.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default KUIUProPackSystem;

