import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import SwarovskiELPic from '../images/SwarovskiEL.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import BinocularsPic from '../../images/Binoculars.jpg';


function SwarovskiEL () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Swarovski EL</h1>
          <Link href="https://www.swarovskioptik.com/" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ BinocularsPic} style={{height:'120px'}} alt="Binoculars"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Swarovski EL" height="130px" src={SwarovskiELPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Swarovski EL is a premium line of binoculars renowned for its exceptional optical performance, clarity, and comfort. Designed for outdoor enthusiasts and professionals who demand the highest quality, these binoculars offer a combination of innovative technology and top-tier craftsmanship, making them a popular choice among bird watchers, hunters, and nature lovers.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Exceptional optical clarity and sharpness.</li>
<li className="ListItemStyle">Ergonomic design for comfortable handling.</li>
<li className="ListItemStyle">Robust and durable construction.</li>
<li className="ListItemStyle">Wide field of view for better observation.</li>
<li className="ListItemStyle">Waterproof and fog-proof for use in various weather conditions.</li>
<li className="ListItemStyle">High-quality lens coatings improve light transmission.</li>
<li className="ListItemStyle">Precise focusing mechanism.</li>
<li className="ListItemStyle">Lightweight for extended use without fatigue.</li>
<li className="ListItemStyle">Long eye relief suitable for eyeglass wearers.</li>
<li className="ListItemStyle">Outstanding reputation and reliability.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">High price point limits accessibility.</li>
<li className="ListItemStyle">Might be too advanced for casual users.</li>
<li className="ListItemStyle">Limited availability of certain models.</li>
<li className="ListItemStyle">Heavier than some compact alternatives.</li>
<li className="ListItemStyle">Requires careful maintenance to protect lens quality.</li>
<li className="ListItemStyle">Replacement parts can be expensive.</li>
<li className="ListItemStyle">Neck strap design could be improved.</li>
<li className="ListItemStyle">Battery-equipped model can have limited battery life.</li>
<li className="ListItemStyle">Not the most compact for backcountry travel.</li>
<li className="ListItemStyle">Some users might prefer smaller objective lenses for portability.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default SwarovskiEL;

