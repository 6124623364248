import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import ZippoEmergencyFireStarterKitPic from '../images/ZippoEmergencyFireStarterKit.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import FireStartersPic from '../../images/FireStarters.jpg';


function ZippoEmergencyFireStarterKit () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Zippo Emergency Fire Starter Kit</h1>
          <Link href="https://www.zippo.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ FireStartersPic} style={{height:'120px'}} alt="FireStarters"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Zippo Emergency Fire Starter Kit" height="130px" src={ZippoEmergencyFireStarterKitPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Zippo Emergency Fire Starter Kit is a compact and reliable tool designed for outdoor adventurers and emergency situations. It comes with water-resistant waxed tinder sticks and a flint wheel ignition system, making it easy to start a fire even in adverse weather conditions. The kit is lightweight, easy to carry, and built with durable materials for long-lasting performance.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Compact and portable design.</li>
<li className="ListItemStyle">Easy-to-use flint wheel ignition.</li>
<li className="ListItemStyle">Comes with weather-resistant tinder sticks.</li>
<li className="ListItemStyle">Durable construction ensures longevity.</li>
<li className="ListItemStyle">Reliable fire starting in various weather conditions.</li>
<li className="ListItemStyle">Refillable design promotes sustainability.</li>
<li className="ListItemStyle">Bright color makes it easy to locate in gear.</li>
<li className="ListItemStyle">Lightweight and doesn't add much weight to gear.</li>
<li className="ListItemStyle">Works well with wet or damp tinder.</li>
<li className="ListItemStyle">Trusted brand with a good reputation.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Tinder sticks may need to be replaced frequently.</li>
<li className="ListItemStyle">Initial lighting can be tricky until accustomed.</li>
<li className="ListItemStyle">Flint wheel may wear out over time with use.</li>
<li className="ListItemStyle">Not as effective in extreme weather without preparation.</li>
<li className="ListItemStyle">Basic in function compared to multi-tool kits.</li>
<li className="ListItemStyle">Limited tinder capacity; needs frequent restocking.</li>
<li className="ListItemStyle">More expensive than some basic fire starter options.</li>
<li className="ListItemStyle">Initial flint adjustments may be necessary.</li>
<li className="ListItemStyle">Requires some manual dexterity to operate.</li>
<li className="ListItemStyle">Replacement parts might not be readily available.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default ZippoEmergencyFireStarterKit;

