import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import MSRPocketRocket2Pic from '../images/MSRPocketRocket2.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import CampingStovesPic from '../../images/CampingStoves.jpg';


function MSRPocketRocket2 () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>MSR PocketRocket 2</h1>
          <Link href="https://www.msrgear.com/stoves/stove-systems/pocketrocket-2-stove/09884.html" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ CampingStovesPic} style={{height:'120px'}} alt="CampingStoves"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="MSR PocketRocket 2" height="130px" src={MSRPocketRocket2Pic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The MSR PocketRocket 2 is a compact and lightweight backpacking stove designed for outdoor enthusiasts. It is known for its fast boiling times, ease of use, and reliability in various weather conditions. The stove features a simple design with a durable build, making it a popular choice for hikers and campers who need a portable cooking solution.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Lightweight and compact, making it easy to pack and carry.</li>
<li className="ListItemStyle">Boils water quickly, saving time and fuel.</li>
<li className="ListItemStyle">Easy to set up and use, even for beginners.</li>
<li className="ListItemStyle">Durable construction ensures long-lasting performance.</li>
<li className="ListItemStyle">Compatible with most isobutane-propane fuel canisters.</li>
<li className="ListItemStyle">Adjustable flame control for precise cooking.</li>
<li className="ListItemStyle">Stable pot supports accommodate a variety of cookware sizes.</li>
<li className="ListItemStyle">Performs well in windy conditions with a built-in wind clip.</li>
<li className="ListItemStyle">Reliable ignition without the need for a built-in lighter.</li>
<li className="ListItemStyle">Affordable price point for the quality and performance.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Does not include a built-in igniter, requiring matches or a lighter.</li>
<li className="ListItemStyle">Limited simmer control compared to some other stoves.</li>
<li className="ListItemStyle">Requires a separate windscreen for optimal performance in very windy conditions.</li>
<li className="ListItemStyle">Not suitable for very large pots or group cooking.</li>
<li className="ListItemStyle">Fuel canisters are not included and must be purchased separately.</li>
<li className="ListItemStyle">Can be unstable on uneven surfaces.</li>
<li className="ListItemStyle">No integrated pot or pan, requiring additional cookware.</li>
<li className="ListItemStyle">Can be noisy at high flame settings.</li>
<li className="ListItemStyle">Not as efficient in extreme cold temperatures.</li>
<li className="ListItemStyle">Requires careful packing to avoid damage to pot supports.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default MSRPocketRocket2;

