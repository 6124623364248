import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import MidlandGXT1000VP4Pic from '../images/MidlandGXT1000VP4.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import _2WayRadiosPic from '../../images/2WayRadios.jpg';


function MidlandGXT1000VP4 () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Midland GXT1000VP4</h1>
          <Link href="https://midlandusa.com/products/gxt1000vp4" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ _2WayRadiosPic} style={{height:'120px'}} alt="2WayRadios"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Midland GXT1000VP4" height="130px" src={MidlandGXT1000VP4Pic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Midland GXT1000VP4 is a popular two-way radio designed for long-range communication, making it ideal for outdoor activities like hiking, camping, and hunting. It boasts features such as NOAA weather alerts, 50 channels, and a robust build suitable for rugged conditions. The device comes with eVOX for hands-free operation and offers a reliable range with various privacy codes for secure communication.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Long range communication, up to 36 miles in optimal conditions.</li>
<li className="ListItemStyle">50 available channels with 142 privacy codes for secure conversations.</li>
<li className="ListItemStyle">Weather alerts with NOAA channels to keep you informed of severe conditions.</li>
<li className="ListItemStyle">Durable and water-resistant design suitable for outdoor use.</li>
<li className="ListItemStyle">Hands-free operation with eVOX function for ease of use.</li>
<li className="ListItemStyle">Vibrant backlit display for nighttime visibility.</li>
<li className="ListItemStyle">Dual power options with rechargeable battery packs and regular AA compatibility.</li>
<li className="ListItemStyle">Includes a variety of accessories such as chargers and headsets.</li>
<li className="ListItemStyle">Has a whisper function for quiet, private communications.</li>
<li className="ListItemStyle">SOS Siren for emergency situations.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Actual range may be less than advertised in real-world conditions.</li>
<li className="ListItemStyle">Battery life could be improved for extended use.</li>
<li className="ListItemStyle">Units can experience interference in urban areas.</li>
<li className="ListItemStyle">Volume can be excessively loud even on lower settings.</li>
<li className="ListItemStyle">No direct USB charging option available.</li>
<li className="ListItemStyle">Some users report fragility in belt clips.</li>
<li className="ListItemStyle">Setting up the preferred channels and codes can be complex.</li>
<li className="ListItemStyle">Slightly bulkier compared to other models in the market.</li>
<li className="ListItemStyle">VOX sensitivity settings can sometimes misactivate.</li>
<li className="ListItemStyle">Weather alerts can be repetitive if there is no change in conditions.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default MidlandGXT1000VP4;

