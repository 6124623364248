import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";

import JMOMenu from "./components/jmomenu.js";
/* import Layout from "./components/Layout.js"; */
// import FloatingDiv from "./components/FloatingDiv.js"

import CampingStoves from "./pages/Camping/CampingStoves.js";
import BioliteCampStove2 from "./pages/Camping/CampingStoves/BioliteCampStove2.js";
import ColemanClassicPropaneStove from "./pages/Camping/CampingStoves/ColemanClassicPropaneStove.js";
import JetboilFlashCookingSystem from "./pages/Camping/CampingStoves/JetboilFlashCookingSystem.js";
import MSRPocketRocket2 from "./pages/Camping/CampingStoves/MSRPocketRocket2.js";
import SnowPeakLiteMaxTitaniumStove from "./pages/Camping/CampingStoves/SnowPeakLiteMaxTitaniumStove.js";

import CampChairs from "./pages/Camping/CampChairs.js";
import ALPSMountaineeringKingKongChair from "./pages/Camping/CampChairs/ALPSMountaineeringKingKongChair.js";
import ColemanPortableCampingQuadChairwith4CanCooler from "./pages/Camping/CampChairs/ColemanPortableCampingQuadChairwith4CanCooler.js";
import HelinoxChairOne from "./pages/Camping/CampChairs/HelinoxChairOne.js";
import KijaroDualLockPortableCampingandSportsChair from "./pages/Camping/CampChairs/KijaroDualLockPortableCampingandSportsChair.js";
import REICoopCampXChair from "./pages/Camping/CampChairs/REICoopCampXChair.js";

import FireStarters from "./pages/Camping/FireStarters.js";
import ExotacnanoSTRIKERXL from "./pages/Camping/FireStarters/ExotacnanoSTRIKERXL.js";
import GerberBearGryllsFireStarter from "./pages/Camping/FireStarters/GerberBearGryllsFireStarter.js";
import LightMyFireSwedishFireSteel from "./pages/Camping/FireStarters/LightMyFireSwedishFireSteel.js";
import UCOStormproofMatches from "./pages/Camping/FireStarters/UCOStormproofMatches.js";
import ZippoEmergencyFireStarterKit from "./pages/Camping/FireStarters/ZippoEmergencyFireStarterKit.js";

import SleepingBags from "./pages/Camping/SleepingBags.js";
import KeltyCosmicDown from "./pages/Camping/SleepingBags/KeltyCosmicDown.js";
import MarmotTrestlesEliteEco from "./pages/Camping/SleepingBags/MarmotTrestlesEliteEco.js";
import NEMODisco from "./pages/Camping/SleepingBags/NEMODisco.js";
import ThermaRestHyperion from "./pages/Camping/SleepingBags/ThermaRestHyperion.js";
import WesternMountaineeringAlpinlite from "./pages/Camping/SleepingBags/WesternMountaineeringAlpinlite.js";

import HuntingKnives from "./pages/HuntingGear/HuntingKnives.js";
import BenchmadeHiddenCanyonHunter from "./pages/HuntingGear/HuntingKnives/BenchmadeHiddenCanyonHunter.js";
import BuckKnives119Special from "./pages/HuntingGear/HuntingKnives/BuckKnives119Special.js";
import ESEELaserStrike from "./pages/HuntingGear/HuntingKnives/ESEELaserStrike.js";
import KABARBeckerBK2Campanion from "./pages/HuntingGear/HuntingKnives/KABARBeckerBK2Campanion.js";
import MoraknivCompanionHeavyDuty from "./pages/HuntingGear/HuntingKnives/MoraknivCompanionHeavyDuty.js";

import HuntingPacks from "./pages/HuntingGear/HuntingPacks.js";
import ALPSOutdoorZCommanderPackBag from "./pages/HuntingGear/HuntingPacks/ALPSOutdoorZCommanderPackBag.js";
import Badlands2200 from "./pages/HuntingGear/HuntingPacks/Badlands2200.js";
import EberlestockX2Pack from "./pages/HuntingGear/HuntingPacks/EberlestockX2Pack.js";
import KUIUProPackSystem from "./pages/HuntingGear/HuntingPacks/KUIUProPackSystem.js";
import MysteryRanchMetcalf from "./pages/HuntingGear/HuntingPacks/MysteryRanchMetcalf.js";

import GarmineTrex32x from "./pages/Electronics/HandheldGPS/GarmineTrex32x.js";
import GarminGPSMAP66sst from "./pages/Electronics/HandheldGPS/GarminGPSMAP66sst.js";
import GarmininReachExplorer from "./pages/Electronics/HandheldGPS/GarmininReachExplorer.js";
import GarminMontana700i from "./pages/Electronics/HandheldGPS/GarminMontana700i.js";
import GarminOregon750 from "./pages/Electronics/HandheldGPS/GarminOregon750.js";
import HandheldGPS from "./pages/Electronics/HandheldGPS.js";

import SatelliteCommunicators from "./pages/Electronics/SatelliteCommunicators.js";
import GarminGPSMAP66i from "./pages/Electronics/SatelliteCommunicators/GarminGPSMAP66i.js";
import GarmininReachMini2 from "./pages/Electronics/SatelliteCommunicators/GarmininReachMini2.js";
import SomewearGlobalHotspot from "./pages/Electronics/SatelliteCommunicators/SomewearGlobalHotspot.js";
import SPOTX from "./pages/Electronics/SatelliteCommunicators/SPOTX.js";
import ZOLEOSatelliteCommunicator from "./pages/Electronics/SatelliteCommunicators/ZOLEOSatelliteCommunicator.js";

import _2WayRadios from "./pages/Electronics/2WayRadios.js";
import BaoFengBFF8HP from "./pages/Electronics/2WayRadios/BaoFengBFF8HP.js";
import MidlandGXT1000VP4 from "./pages/Electronics/2WayRadios/MidlandGXT1000VP4.js";
import MotorolaMS350R from "./pages/Electronics/2WayRadios/MotorolaMS350R.js";
import MotorolaT600H2O from "./pages/Electronics/2WayRadios/MotorolaT600H2O.js";
import UnidenSX5072CKHS from "./pages/Electronics/2WayRadios/UnidenSX5072CKHS.js";

import TrailCams from "./pages/Electronics/TrailCams.js";
import BrowningStrikeForceHDProX from "./pages/Electronics/TrailCams/BrowningStrikeForceHDProX.js";
import BushnellCoreDSNoGlow from "./pages/Electronics/TrailCams/BushnellCoreDSNoGlow.js";
import MoultrieA900i from "./pages/Electronics/TrailCams/MoultrieA900i.js";
import SpypointSolarDark from "./pages/Electronics/TrailCams/SpypointSolarDark.js";
import StealthCamG42NG from "./pages/Electronics/TrailCams/StealthCamG42NG.js";

import Binoculars from "./pages/Optics/Binoculars.js";
import CelestronNatureDX from "./pages/Optics/Binoculars/CelestronNatureDX.js";
import NikonMonarch5 from "./pages/Optics/Binoculars/NikonMonarch5.js";
import SwarovskiEL from "./pages/Optics/Binoculars/SwarovskiEL.js";
import VortexOpticsViperHD from "./pages/Optics/Binoculars/VortexOpticsViperHD.js";
import ZeissTerraED from "./pages/Optics/Binoculars/ZeissTerraED.js";

import RedDotSights from "./pages/Optics/RedDotSights.js";
import AimpointPROPatrolRifleOptic from "./pages/Optics/RedDotSights/AimpointPROPatrolRifleOptic.js";
import EOTechEXPS3HolographicWeaponSight from "./pages/Optics/RedDotSights/EOTechEXPS3HolographicWeaponSight.js";
import HolosunHS510C from "./pages/Optics/RedDotSights/HolosunHS510C.js";
import SigSauerRomeo5 from "./pages/Optics/RedDotSights/SigSauerRomeo5.js";
import TrijiconMROMiniatureRifleOptic from "./pages/Optics/RedDotSights/TrijiconMROMiniatureRifleOptic.js";

function App() {

  window.addEventListener("orientationchange", (event) => {
    //window.location.reload(true);
    console.log(
      `the orientation of the device is now ${event.target.screen.orientation.angle}`,
    );
  });


  return (


		<BrowserRouter>
    
    <JMOMenu />
      {/* <FloatingDiv dataAdSlot='3584139985'></FloatingDiv> */}
      <Routes>
        {/* <Route index element={<Layout><Home /></Layout>}></Route>           */}
        <Route index element={<Home />}></Route>  
          <Route path="about" element={<About />}></Route>
          <Route path="contact" element={<Contact />}></Route>

          <Route path="Camping/CampChairs" element={<CampChairs />}></Route>
          <Route path="Camping/CampChairs/ALPSMountaineeringKingKongChair" element={<ALPSMountaineeringKingKongChair />}></Route>
          <Route path="Camping/CampChairs/ColemanPortableCampingQuadChairwith4CanCooler" element={<ColemanPortableCampingQuadChairwith4CanCooler />}></Route>
          <Route path="Camping/CampChairs/HelinoxChairOne" element={<HelinoxChairOne />}></Route>
          <Route path="Camping/CampChairs/KijaroDualLockPortableCampingandSportsChair" element={<KijaroDualLockPortableCampingandSportsChair />}></Route>
          <Route path="Camping/CampChairs/REICoopCampXChair" element={<REICoopCampXChair />}></Route>


          <Route path="Camping/CampingStoves" element={<CampingStoves />}></Route>

          <Route path="Camping/CampingStoves/BioliteCampStove2" element={<BioliteCampStove2 />}></Route>
<Route path="Camping/CampingStoves/ColemanClassicPropaneStove" element={<ColemanClassicPropaneStove />}></Route>
<Route path="Camping/CampingStoves/JetboilFlashCookingSystem" element={<JetboilFlashCookingSystem />}></Route>
<Route path="Camping/CampingStoves/MSRPocketRocket2" element={<MSRPocketRocket2 />}></Route>
<Route path="Camping/CampingStoves/SnowPeakLiteMaxTitaniumStove" element={<SnowPeakLiteMaxTitaniumStove />}></Route>

<Route path="Camping/FireStarters" element={<FireStarters />}></Route>
<Route path="Camping/FireStarters/ExotacnanoSTRIKERXL" element={<ExotacnanoSTRIKERXL />}></Route>
<Route path="Camping/FireStarters/GerberBearGryllsFireStarter" element={<GerberBearGryllsFireStarter />}></Route>
<Route path="Camping/FireStarters/LightMyFireSwedishFireSteel" element={<LightMyFireSwedishFireSteel />}></Route>
<Route path="Camping/FireStarters/UCOStormproofMatches" element={<UCOStormproofMatches />}></Route>
<Route path="Camping/FireStarters/ZippoEmergencyFireStarterKit" element={<ZippoEmergencyFireStarterKit />}></Route>

<Route path="Camping/SleepingBags" element={<SleepingBags />}></Route>  
<Route path="Camping/SleepingBags/KeltyCosmicDown" element={<KeltyCosmicDown />}></Route>
<Route path="Camping/SleepingBags/MarmotTrestlesEliteEco" element={<MarmotTrestlesEliteEco />}></Route>
<Route path="Camping/SleepingBags/NEMODisco" element={<NEMODisco />}></Route>
<Route path="Camping/SleepingBags/ThermaRestHyperion" element={<ThermaRestHyperion />}></Route>
<Route path="Camping/SleepingBags/WesternMountaineeringAlpinlite" element={<WesternMountaineeringAlpinlite />}></Route>

<Route path="Electronics/HandheldGPS" element={<HandheldGPS />}></Route> 
<Route path="Electronics/HandheldGPS/GarmineTrex32x" element={<GarmineTrex32x />}></Route>
<Route path="Electronics/HandheldGPS/GarminGPSMAP66sst" element={<GarminGPSMAP66sst />}></Route>
<Route path="Electronics/HandheldGPS/GarmininReachExplorer" element={<GarmininReachExplorer />}></Route>
<Route path="Electronics/HandheldGPS/GarminMontana700i" element={<GarminMontana700i />}></Route>
<Route path="Electronics/HandheldGPS/GarminOregon750" element={<GarminOregon750 />}></Route>

<Route path="Electronics/SatelliteCommunicators" element={<SatelliteCommunicators />}></Route>  
<Route path="Electronics/SatelliteCommunicators/GarminGPSMAP66i" element={<GarminGPSMAP66i />}></Route>
<Route path="Electronics/SatelliteCommunicators/GarmininReachMini2" element={<GarmininReachMini2 />}></Route>
<Route path="Electronics/SatelliteCommunicators/SomewearGlobalHotspot" element={<SomewearGlobalHotspot />}></Route>
<Route path="Electronics/SatelliteCommunicators/SPOTX" element={<SPOTX />}></Route>
<Route path="Electronics/SatelliteCommunicators/ZOLEOSatelliteCommunicator" element={<ZOLEOSatelliteCommunicator />}></Route>

<Route path="Electronics/2WayRadios" element={<_2WayRadios />}></Route> 
<Route path="Electronics/2WayRadios/BaoFengBFF8HP" element={<BaoFengBFF8HP />}></Route>
<Route path="Electronics/2WayRadios/MidlandGXT1000VP4" element={<MidlandGXT1000VP4 />}></Route>
<Route path="Electronics/2WayRadios/MotorolaMS350R" element={<MotorolaMS350R />}></Route>
<Route path="Electronics/2WayRadios/MotorolaT600H2O" element={<MotorolaT600H2O />}></Route>
<Route path="Electronics/2WayRadios/UnidenSX5072CKHS" element={<UnidenSX5072CKHS />}></Route>

<Route path="Electronics/TrailCams" element={<TrailCams />}></Route>
<Route path="Electronics/TrailCams/BrowningStrikeForceHDProX" element={<BrowningStrikeForceHDProX />}></Route>
<Route path="Electronics/TrailCams/BushnellCoreDSNoGlow" element={<BushnellCoreDSNoGlow />}></Route>
<Route path="Electronics/TrailCams/MoultrieA900i" element={<MoultrieA900i />}></Route>
<Route path="Electronics/TrailCams/SpypointSolarDark" element={<SpypointSolarDark />}></Route>
<Route path="Electronics/TrailCams/StealthCamG42NG" element={<StealthCamG42NG />}></Route>

<Route path="HuntingGear/HuntingKnives" element={<HuntingKnives />}></Route> 
<Route path="HuntingGear/HuntingKnives/BenchmadeHiddenCanyonHunter" element={<BenchmadeHiddenCanyonHunter />}></Route>
<Route path="HuntingGear/HuntingKnives/BuckKnives119Special" element={<BuckKnives119Special />}></Route>
<Route path="HuntingGear/HuntingKnives/ESEELaserStrike" element={<ESEELaserStrike />}></Route>
<Route path="HuntingGear/HuntingKnives/KABARBeckerBK2Campanion" element={<KABARBeckerBK2Campanion />}></Route>
<Route path="HuntingGear/HuntingKnives/MoraknivCompanionHeavyDuty" element={<MoraknivCompanionHeavyDuty />}></Route>

<Route path="HuntingGear/HuntingPacks" element={<HuntingPacks />}></Route> 
<Route path="HuntingGear/HuntingPacks/ALPSOutdoorZCommanderPackBag" element={<ALPSOutdoorZCommanderPackBag />}></Route>
<Route path="HuntingGear/HuntingPacks/Badlands2200" element={<Badlands2200 />}></Route>
<Route path="HuntingGear/HuntingPacks/EberlestockX2Pack" element={<EberlestockX2Pack />}></Route>
<Route path="HuntingGear/HuntingPacks/KUIUProPackSystem" element={<KUIUProPackSystem />}></Route>
<Route path="HuntingGear/HuntingPacks/MysteryRanchMetcalf" element={<MysteryRanchMetcalf />}></Route>

<Route path="Optics/Binoculars" element={<Binoculars />}></Route>
<Route path="Optics/Binoculars/CelestronNatureDX" element={<CelestronNatureDX />}></Route>
<Route path="Optics/Binoculars/NikonMonarch5" element={<NikonMonarch5 />}></Route>
<Route path="Optics/Binoculars/SwarovskiEL" element={<SwarovskiEL />}></Route>
<Route path="Optics/Binoculars/VortexOpticsViperHD" element={<VortexOpticsViperHD />}></Route>
<Route path="Optics/Binoculars/ZeissTerraED" element={<ZeissTerraED />}></Route>

<Route path="Optics/RedDotSights" element={<RedDotSights />}></Route>
<Route path="Optics/RedDotSights/AimpointPROPatrolRifleOptic" element={<AimpointPROPatrolRifleOptic />}></Route>
<Route path="Optics/RedDotSights/EOTechEXPS3HolographicWeaponSight" element={<EOTechEXPS3HolographicWeaponSight />}></Route>
<Route path="Optics/RedDotSights/HolosunHS510C" element={<HolosunHS510C />}></Route>
<Route path="Optics/RedDotSights/SigSauerRomeo5" element={<SigSauerRomeo5 />}></Route>
<Route path="Optics/RedDotSights/TrijiconMROMiniatureRifleOptic" element={<TrijiconMROMiniatureRifleOptic />}></Route>

{/*          <Route path="Camping/CampChairs" element={<CampChairs />}></Route>
          <Route path="Camping/CampChairs/GCIOutdoorFreestyleRockerCampChair" element={<GCIOutdoorFreestyleRockerCampChair />}></Route>
          <Route path="Camping/CampChairs/GCIOutdoorKickbackRockerCampChair" element={<GCIOutdoorKickbackRockerCampChair />}></Route>
          <Route path="Camping/CampChairs/AscendRestLightCampChair" element={<AscendRestLightCampChair />}></Route>
          <Route path="Camping/CampChairs/GCIOutdoorStowawayRockerCampChair" element={<GCIOutdoorStowawayRockerCampChair />}></Route>
          <Route path="Camping/CampChairs/OZTENTKingKokodaLoungeCampChair" element={<OZTENTKingKokodaLoungeCampChair />}></Route>

          <Route path="Camping/FireStarters" element={<FireStarters />}></Route>
          <Route path="Camping/FireStarters/FastFireFireStarters" element={<FastFireFireStarters />}></Route>
          <Route path="Camping/FireStarters/ZippoFireStarterComboKit" element={<ZippoFireStarterComboKit />}></Route>
          <Route path="Camping/FireStarters/CoghlanSMagnesiumFireStarter" element={<CoghlanSMagnesiumFireStarter />}></Route>
          <Route path="Camping/FireStarters/NOMADGrillsFireStarters" element={<NOMADGrillsFireStarters />}></Route>
          <Route path="Camping/FireStarters/ZippoTinderShredsFireStarter" element={<ZippoTinderShredsFireStarter />}></Route>

          <Route path="Camping/SleepingBags" element={<SleepingBags />}></Route>   
          <Route path="Camping/SleepingBags/AscendHex0LongMummySleepingBag" element={<AscendHex0LongMummySleepingBag />}></Route>
          <Route path="Camping/SleepingBags/AscendHoodoo20MummySleepingBag" element={<AscendHoodoo20MummySleepingBag />}></Route>
          <Route path="Camping/SleepingBags/AscendHex0RegularMummySleepingBag" element={<AscendHex0RegularMummySleepingBag />}></Route>
          <Route path="Camping/SleepingBags/StansportGlacier0MummySleepingBag" element={<StansportGlacier0MummySleepingBag />}></Route>
          <Route path="Camping/SleepingBags/StansportGrizzly10CanvasSleepingBag" element={<StansportGrizzly10CanvasSleepingBag />}></Route>     

          <Route path="OutdoorGear/HuntingKnives" element={<HuntingKnives />}></Route>   
          <Route path="OutdoorGear/HuntingKnives/HavalonTalonHuntReplaceableBladeKnifeKit" element={<HavalonTalonHuntReplaceableBladeKnifeKit />}></Route>
          <Route path="OutdoorGear/HuntingKnives/OutdoorEdgeButcherMax11PieceGameProcessingSet" element={<OutdoorEdgeButcherMax11PieceGameProcessingSet />}></Route>
          <Route path="OutdoorGear/HuntingKnives/HavalonKnivesPirantaEdgeReplaceableBladeFoldingKnife" element={<HavalonKnivesPirantaEdgeReplaceableBladeFoldingKnife />}></Route>
          <Route path="OutdoorGear/HuntingKnives/OutdoorEdgeSwingBlazeSwingBladeDropPointSkinnerBigGameGuttingKnife" element={<OutdoorEdgeSwingBlazeSwingBladeDropPointSkinnerBigGameGuttingKnife />}></Route>
          <Route path="OutdoorGear/HuntingKnives/Pursuit7PieceKnifeSet" element={<Pursuit7PieceKnifeSet />}></Route>

          <Route path="OutdoorGear/HuntingPacks" element={<HuntingPacks />}></Route>   
          <Route path="OutdoorGear/HuntingPacks/BadlandsATX25HuntingPack" element={<BadlandsATX25HuntingPack />}></Route>
          <Route path="OutdoorGear/HuntingPacks/BadlandsRiseHuntingPack" element={<BadlandsRiseHuntingPack />}></Route>
          <Route path="OutdoorGear/HuntingPacks/CabelasMultiDayHuntingPack" element={<CabelasMultiDayHuntingPack />}></Route>
          <Route path="OutdoorGear/HuntingPacks/CabelasVersaHunt80LHuntingPack" element={<CabelasVersaHunt80LHuntingPack />}></Route>
          <Route path="OutdoorGear/HuntingPacks/MuddyProSeriesLumbar500HuntingPack" element={<MuddyProSeriesLumbar500HuntingPack />}></Route>

          <Route path="Electronics/HandheldGPS" element={<HandheldGPS />}></Route>  
          <Route path="Electronics/HandheldGPS/GarminETrex22xHandheldGPSUnit" element={<GarminETrex22xHandheldGPSUnit />}></Route>
          <Route path="Electronics/HandheldGPS/GarminETrexSEHandheldGPSNavigationUnit" element={<GarminETrexSEHandheldGPSNavigationUnit />}></Route>
          <Route path="Electronics/HandheldGPS/GarminGPSMAP64sxHandheldGPSWithNavigationSensors" element={<GarminGPSMAP64sxHandheldGPSWithNavigationSensors />}></Route>
          <Route path="Electronics/HandheldGPS/GarminGPSMAP66iGPSHandheldAndSatelliteCommunicatorWithInReachTechnology" element={<GarminGPSMAP66iGPSHandheldAndSatelliteCommunicatorWithInReachTechnology />}></Route>
          <Route path="Electronics/HandheldGPS/GarminGPSMAP67HandheldGPS" element={<GarminGPSMAP67HandheldGPS />}></Route>

          <Route path="Electronics/SatelliteCommunicators" element={<SatelliteCommunicators />}></Route>  
          <Route path="Electronics/SatelliteCommunicators/ACRElectronicsBivyStickSatelliteCommunicationDevice" element={<ACRElectronicsBivyStickSatelliteCommunicationDevice />}></Route>
          <Route path="Electronics/SatelliteCommunicators/GarminGPSMAP66iGPSHandheldAndSatelliteCommunicatorWithInReachTechnology" element={<GarminGPSMAP66iGPSHandheldAndSatelliteCommunicatorWithInReachTechnologySC />}></Route>
          <Route path="Electronics/SatelliteCommunicators/GarminGPSMAP67iGPSHandheldAndSatelliteCommunicator" element={<GarminGPSMAP67iGPSHandheldAndSatelliteCommunicator />}></Route>
          <Route path="Electronics/SatelliteCommunicators/GarminInReachMini2SatelliteCommunicator" element={<GarminInReachMini2SatelliteCommunicator />}></Route>
          <Route path="Electronics/SatelliteCommunicators/ZOLEOSatelliteCommunicator" element={<ZOLEOSatelliteCommunicator />}></Route>

          <Route path="Electronics/2WayRadios" element={<_2WayRadios />}></Route>  
          <Route path="Electronics/2WayRadios/Cobra30MileWalkieTalkie2WayRadio2PackSet" element={<Cobra30MileWalkieTalkie2WayRadio2PackSet />}></Route>
          <Route path="Electronics/2WayRadios/MidlandMXT275MicroMobile2WayRadio" element={<MidlandMXT275MicroMobile2WayRadio />}></Route>
          <Route path="Electronics/2WayRadios/MidlandT77VP5XTalkerExtreme2WayRadios2Pack" element={<MidlandT77VP5XTalkerExtreme2WayRadios2Pack />}></Route>
          <Route path="Electronics/2WayRadios/MidlandXTALKERT299VP4Outfitter2WayRadioPack" element={<MidlandXTALKERT299VP4Outfitter2WayRadioPack />}></Route>
          <Route path="Electronics/2WayRadios/MotorolaTalkaboutT1102WayRadios" element={<MotorolaTalkaboutT1102WayRadios />}></Route>

          <Route path="OutdoorGear/TrailCams" element={<TrailCams />}></Route>  
          <Route path="OutdoorGear/TrailCams/CabelasOutfitterG532MPBlackIRTrailCameraCombo" element={<CabelasOutfitterG532MPBlackIRTrailCameraCombo />}></Route>
          <Route path="OutdoorGear/TrailCams/MoultrieMobileEDGECellularTrailCamera" element={<MoultrieMobileEDGECellularTrailCamera />}></Route>
          <Route path="OutdoorGear/TrailCams/SpyPointFlexMCellularTrailCamera2Pack" element={<SpyPointFlexMCellularTrailCamera2Pack />}></Route>
          <Route path="OutdoorGear/TrailCams/StealthCamRevolver360CellularTrailCamera" element={<StealthCamRevolver360CellularTrailCamera />}></Route>
          <Route path="OutdoorGear/TrailCams/TactacamRevealXProCellularTrailCamera" element={<TactacamRevealXProCellularTrailCamera />}></Route>
 */}

        </Routes>
		</BrowserRouter>

    
  );
}

export default App;