import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import ZOLEOSatelliteCommunicatorPic from '../images/ZOLEOSatelliteCommunicator.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import SatelliteCommunicatorsPic from '../../images/SatelliteCommunicators.jpg';


function ZOLEOSatelliteCommunicator () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>ZOLEO Satellite Communicator</h1>
          <Link href="https://www.zoleo.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ SatelliteCommunicatorsPic} style={{height:'120px'}} alt="SatelliteCommunicators"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="ZOLEO Satellite Communicator" height="130px" src={ZOLEOSatelliteCommunicatorPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The ZOLEO Satellite Communicator is a rugged and versatile device designed for reliable communication beyond cellular coverage. It connects seamlessly with a smartphone or tablet via the ZOLEO app, allowing users to send and receive messages, track locations, and send SOS alerts worldwide through the Iridium satellite network.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Global satellite coverage via the Iridium network.</li>
<li className="ListItemStyle">Seamless messaging between satellite, cellular, and Wi-Fi connections.</li>
<li className="ListItemStyle">Long battery life, lasting several days on a single charge.</li>
<li className="ListItemStyle">SOS alerting feature with 24/7 monitoring and GPS location reporting.</li>
<li className="ListItemStyle">Weather forecasts available through the app.</li>
<li className="ListItemStyle">Compact and rugged design suitable for harsh environments.</li>
<li className="ListItemStyle">Easy-to-use mobile app interface.</li>
<li className="ListItemStyle">Affordable pricing for both the device and subscription plans.</li>
<li className="ListItemStyle">Ability to share location with friends and family.</li>
<li className="ListItemStyle">Bluetooth connectivity for easy pairing with smartphones and tablets.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Requires a subscription plan for satellite communication.</li>
<li className="ListItemStyle">Cannot make voice calls, only text messaging.</li>
<li className="ListItemStyle">Initial setup requires a smartphone and internet connection.</li>
<li className="ListItemStyle">Dependent on smartphone app for full functionality.</li>
<li className="ListItemStyle">Limited standalone functionality without a paired device.</li>
<li className="ListItemStyle">Occasional message delays due to satellite communication.</li>
<li className="ListItemStyle">Relatively high cost of data usage compared to cellular.</li>
<li className="ListItemStyle">Does not support two-way messaging with SMS on all networks.</li>
<li className="ListItemStyle">Subscription cancellation policy may include termination fees.</li>
<li className="ListItemStyle">Battery life may decrease over time with frequent usage.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default ZOLEOSatelliteCommunicator;

