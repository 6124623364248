import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import GarmininReachExplorerPic from '../images/GarmininReachExplorer.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import HandheldGPSPic from '../../images/HandheldGPS.jpg';


function GarmininReachExplorer () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Garmin inReach Explorer+</h1>
          <Link href="https://www.garmin.com/en-US/p/561269" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ HandheldGPSPic} style={{height:'120px'}} alt="HandheldGPS"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Garmin inReach Explorer+" height="130px" src={GarmininReachExplorerPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Garmin inReach Explorer+ is a robust satellite communicator and GPS device designed for outdoor adventurers. It offers reliable communication capabilities, including sending and receiving text messages and SOS alerts, in areas without cellular service. The device features topographic maps, GPS navigation, and tracking functions, making it a versatile tool for hiking, camping, and other remote activities.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Global Iridium satellite coverage ensures reliable communication anywhere.</li>
<li className="ListItemStyle">Two-way messaging to communicate with family and friends.</li>
<li className="ListItemStyle">Interactive SOS function connects you with a 24/7 search and rescue center.</li>
<li className="ListItemStyle">Preloaded DeLorme topographic maps with on-screen GPS routing.</li>
<li className="ListItemStyle">Built-in digital compass, barometric altimeter, and accelerometer.</li>
<li className="ListItemStyle">Long battery life suitable for extended trips.</li>
<li className="ListItemStyle">Weather forecast service to help plan outdoor activities.</li>
<li className="ListItemStyle">Rugged and durable design to withstand harsh environments.</li>
<li className="ListItemStyle">Integration with the Garmin Earthmate app for additional navigation features.</li>
<li className="ListItemStyle">Can track and share your location with others in real-time.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Requires a subscription plan to access satellite communication features.</li>
<li className="ListItemStyle">Initial cost is relatively high compared to other similar devices.</li>
<li className="ListItemStyle">The interface can be complex and takes time to learn.</li>
<li className="ListItemStyle">Text input is cumbersome due to limited keyboard options.</li>
<li className="ListItemStyle">Screen resolution is lower compared to modern smartphones.</li>
<li className="ListItemStyle">Heavy and bulky, which might not be ideal for ultralight hikers.</li>
<li className="ListItemStyle">Limited functionality without satellite subscription.</li>
<li className="ListItemStyle">Bluetooth connectivity can sometimes be unreliable.</li>
<li className="ListItemStyle">No touchscreen, which may be a drawback for some users.</li>
<li className="ListItemStyle">Satellite communication can be delayed in heavily wooded or obstructed areas.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default GarmininReachExplorer;

