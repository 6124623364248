import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import SPOTXPic from '../images/SPOTX.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import SatelliteCommunicatorsPic from '../../images/SatelliteCommunicators.jpg';


function SPOTX () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>SPOT X</h1>
          <Link href="https://www.findmespot.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ SatelliteCommunicatorsPic} style={{height:'120px'}} alt="SatelliteCommunicators"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="SPOT X" height="130px" src={SPOTXPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The SPOT X is a satellite messenger device designed for outdoor enthusiasts and professionals who require reliable communication when off-the-grid. It offers two-way messaging, GPS tracking, an SOS feature for emergencies, and a backlit keyboard for easy typing. The device connects directly to satellites, ensuring communication where cell service is unavailable.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Two-way messaging capability allows for communication even in remote areas.</li>
<li className="ListItemStyle">Integrated GPS tracking helps share your location with others.</li>
<li className="ListItemStyle">Dedicated SOS button for emergency situations, ensuring swift assistance.</li>
<li className="ListItemStyle">Backlit QWERTY keyboard facilitates easy typing, even in low-light conditions.</li>
<li className="ListItemStyle">Rugged, durable design suitable for harsh outdoor environments.</li>
<li className="ListItemStyle">Rechargeable lithium-ion battery offers a long battery life.</li>
<li className="ListItemStyle">Allows sharing of location via a web-based map link.</li>
<li className="ListItemStyle">Bluetooth connectivity enables syncing with mobile devices.</li>
<li className="ListItemStyle">Supports multiple languages, broadening its usability.</li>
<li className="ListItemStyle">Monthly and annual service plans provide flexible subscription options.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Can be relatively expensive compared to other devices in its category.</li>
<li className="ListItemStyle">Device size and weight may be cumbersome for ultralight backpackers.</li>
<li className="ListItemStyle">Requires a clear view of the sky for optimal satellite connection.</li>
<li className="ListItemStyle">Annual subscription plans can add up to the overall cost.</li>
<li className="ListItemStyle">Some users experience delays in message delivery.</li>
<li className="ListItemStyle">Keyboard can be challenging to use with gloves or cold hands.</li>
<li className="ListItemStyle">Limited extra features compared to some more modern competitors.</li>
<li className="ListItemStyle">Customer support may not be as responsive as desired by some users.</li>
<li className="ListItemStyle">Not as compact as some other satellite communicators.</li>
<li className="ListItemStyle">Initial setup can be a bit complex for the technologically uninitiated.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default SPOTX;

