import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import GarminGPSMAP66iPic from '../images/GarminGPSMAP66i.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import SatelliteCommunicatorsPic from '../../images/SatelliteCommunicators.jpg';


function GarminGPSMAP66i () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Garmin GPSMAP 66i</h1>
          <Link href="https://www.garmin.com/en-US/p/623975" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ SatelliteCommunicatorsPic} style={{height:'120px'}} alt="SatelliteCommunicators"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Garmin GPSMAP 66i" height="130px" src={GarminGPSMAP66iPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Garmin GPSMAP 66i is a rugged, handheld GPS and satellite communicator designed for outdoor enthusiasts and professionals. It combines reliable topographic navigation with inReach satellite communication technology for real-time location sharing and SOS emergency functionality. The device features a 3-inch color display, preloaded maps, and weather forecasts, making it suitable for hiking, camping, and other remote adventures.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Rugged and durable design suitable for harsh environments.</li>
<li className="ListItemStyle">Combines GPS navigation with inReach satellite communication.</li>
<li className="ListItemStyle">Real-time location sharing for added safety.</li>
<li className="ListItemStyle">SOS emergency function with 24/7 monitoring center.</li>
<li className="ListItemStyle">Preloaded topographic maps for easy navigation.</li>
<li className="ListItemStyle">Large, easy-to-read 3-inch color display.</li>
<li className="ListItemStyle">Two-way text messaging when outside of cellular coverage.</li>
<li className="ListItemStyle">Weather forecast updates help plan outdoor activities.</li>
<li className="ListItemStyle">Long battery life for extended outdoor adventures.</li>
<li className="ListItemStyle">Compatible with Garmin Explore app for trip planning.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Premium price point compared to other handheld GPS devices.</li>
<li className="ListItemStyle">Requires subscription for inReach satellite services.</li>
<li className="ListItemStyle">The device can be bulky for some users.</li>
<li className="ListItemStyle">Satellite messaging can be slow in dense cover.</li>
<li className="ListItemStyle">User interface may have a learning curve for new users.</li>
<li className="ListItemStyle">Limited internal storage for additional maps and data.</li>
<li className="ListItemStyle">Overall weight could be heavy for ultralight backpackers.</li>
<li className="ListItemStyle">Mapping updates require connection to a computer.</li>
<li className="ListItemStyle">Display visibility can be compromised in bright sunlight.</li>
<li className="ListItemStyle">May have occasional signal loss in very remote areas.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default GarminGPSMAP66i;

