import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import ALPSOutdoorZCommanderPackBagPic from '../images/ALPSOutdoorZCommanderPackBag.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import HuntingPacksPic from '../../images/HuntingPacks.jpg';


function ALPSOutdoorZCommanderPackBag () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>ALPS OutdoorZ Commander + Pack Bag</h1>
          <Link href="https://alpsoutdoorz.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ HuntingPacksPic} style={{height:'120px'}} alt="Hunting Packs"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="ALPS OutdoorZ Commander + Pack Bag" height="130px" src={ALPSOutdoorZCommanderPackBagPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The ALPS OutdoorZ Commander + Pack Bag is a versatile and durable external frame backpack designed for hunting and outdoor adventures. It features a spacious pack bag along with a stand-alone external frame that can be used for hauling meat or other heavy loads. This backpack is built from rugged materials, with adjustable components for a personalized fit, and includes several compartments and attachment points for accessories.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Spacious main compartment for extended trips.</li>
<li className="ListItemStyle">Removable pack bag allows for frame-only use.</li>
<li className="ListItemStyle">Durable construction designed for rugged use.</li>
<li className="ListItemStyle">Multiple pockets and compartments for organization.</li>
<li className="ListItemStyle">Adjustable torso length for a customized fit.</li>
<li className="ListItemStyle">Freighter shelf for hauling heavier loads.</li>
<li className="ListItemStyle">Hydration compatible design.</li>
<li className="ListItemStyle">Easy access to gear and contents.</li>
<li className="ListItemStyle">Included rifle holder for hunters.</li>
<li className="ListItemStyle">Adjustable straps for added comfort.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Heavier than some internal frame backpacks.</li>
<li className="ListItemStyle">Can be bulky and cumbersome for certain terrains.</li>
<li className="ListItemStyle">External frame may not suit all trekking styles.</li>
<li className="ListItemStyle">Limited color options.</li>
<li className="ListItemStyle">Pack bag not waterproof without a cover.</li>
<li className="ListItemStyle">May require additional purchases for complete waterproofing.</li>
<li className="ListItemStyle">Initial setup and adjustment can be time-consuming.</li>
<li className="ListItemStyle">Bulky frame not suitable for dense brush environments.</li>
<li className="ListItemStyle">Higher price point compared to simpler packs.</li>
<li className="ListItemStyle">Frame might squeak when loaded heavily.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default ALPSOutdoorZCommanderPackBag;

