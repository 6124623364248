import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import KijaroDualLockPortableCampingandSportsChairPic from '../images/KijaroDualLockPortableCampingandSportsChair.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import CampChairsPic from '../../images/CampChairs.jpg';


function KijaroDualLockPortableCampingandSportsChair () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Kijaro Dual Lock Portable Camping and Sports Chair</h1>
          <Link href="https://kijaro.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={CampChairsPic} style={{height:'120px'}} alt="Camp Chairs"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Kijaro Dual Lock Portable Camping and Sports Chair" height="130px" src={KijaroDualLockPortableCampingandSportsChairPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Kijaro Dual Lock Portable Camping and Sports Chair is a versatile and comfortable folding chair designed for outdoor activities such as camping, sports events, and picnics. It features a dual lock system for extra stability, a durable steel frame, breathable mesh fabric, and convenient accessories like cup holders and an organizer with zip and mesh pockets.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Dual lock system provides extra stability and safety.</li>
<li className="ListItemStyle">Durable steel frame can support up to 300 pounds.</li>
<li className="ListItemStyle">Breathable mesh fabric keeps you cool and comfortable.</li>
<li className="ListItemStyle">Includes two cup holders for convenience.</li>
<li className="ListItemStyle">Comes with an organizer with zip and mesh pockets for storage.</li>
<li className="ListItemStyle">Easy to fold and transport with a carry bag included.</li>
<li className="ListItemStyle">Available in multiple colors to suit personal preferences.</li>
<li className="ListItemStyle">Seat height is higher than many other portable chairs, offering better comfort.</li>
<li className="ListItemStyle">No-sag seating design for improved comfort over long periods.</li>
<li className="ListItemStyle">Sturdy construction suitable for various outdoor activities.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Heavier than some other portable chairs, making it less ideal for long-distance carrying.</li>
<li className="ListItemStyle">Bulkier when folded, which may be an issue for compact storage.</li>
<li className="ListItemStyle">Armrests are not adjustable.</li>
<li className="ListItemStyle">Cup holders may not fit larger bottles or cups.</li>
<li className="ListItemStyle">Mesh fabric may not be as durable as other materials over time.</li>
<li className="ListItemStyle">May be too tall for shorter individuals, affecting comfort.</li>
<li className="ListItemStyle">Limited recline options compared to some other chairs.</li>
<li className="ListItemStyle">Price may be higher than basic camping chairs.</li>
<li className="ListItemStyle">Some users report difficulty in locking and unlocking the chair.</li>
<li className="ListItemStyle">The chair's width may not be comfortable for all body types.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default KijaroDualLockPortableCampingandSportsChair;

