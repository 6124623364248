import * as React from 'react';
import Grid  from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
// import {useState, useEffect} from "react";
// import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';

import HandheldGPSPic from '../images/HandheldGPS.jpg';

//import GarmineTrex32xPic from './images/GarmineTrex32x.png';
//import GarminGPSMAP66sstPic from './images/GarminGPSMAP66sst.png';
//import GarmininReachExplorerPic from './images/GarmininReachExplorer.png';
//import GarminMontana700iPic from './images/GarminMontana700i.png';
//import GarminOregon750Pic from './images/GarminOregon750.png';


function HandheldGPS () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const items = [
        {
      //manuLink:"[get link from company website]",
      //imageAlt:"Garmin eTrex 32x",
      //imageRef:GarmineTrex32xPic,
      id:"GarmineTrex32x",
      itemLink:"../Electronics/HandheldGPS/GarmineTrex32x",
      itemBoldText:"Garmin eTrex 32x:",
      itemText:"The Garmin eTrex 32x is a handheld GPS device known for its durability and versatility, designed for outdoor enthusiasts. It features a 2.2-inch sunlight-readable display and preloaded maps, including roads and trial routes from all terrains. The device supports both GPS and GLONASS satellite systems, offering improved positioning in challenging environments, along with a 3-axis compass and barometric altimeter."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Garmin GPSMAP 66s/st",
      //imageRef:GarminGPSMAP66sstPic,
      id:"GarminGPSMAP66sst",
      itemLink:"../Electronics/HandheldGPS/GarminGPSMAP66sst",
      itemBoldText:"Garmin GPSMAP 66s/st:",
      itemText:"The Garmin GPSMAP 66s/st is a handheld GPS device designed for outdoor enthusiasts, such as hikers, campers, and geocachers, seeking reliable navigation tools. It features a robust build, a bright 3-inch color display, and supports multiple satellite systems (GPS, GLONASS, and Galileo) for improved positioning. The 66st model also includes preloaded topographic maps. Both models offer wireless connectivity and various navigation and tracking features, making them a versatile choice for outdoor navigation."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Garmin inReach Explorer+",
      //imageRef:GarmininReachExplorerPic,
      id:"GarmininReachExplorer",
      itemLink:"../Electronics/HandheldGPS/GarmininReachExplorer",
      itemBoldText:"Garmin inReach Explorer+:",
      itemText:"The Garmin inReach Explorer+ is a robust satellite communicator and GPS device designed for outdoor adventurers. It offers reliable communication capabilities, including sending and receiving text messages and SOS alerts, in areas without cellular service. The device features topographic maps, GPS navigation, and tracking functions, making it a versatile tool for hiking, camping, and other remote activities."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Garmin Montana 700i",
      //imageRef:GarminMontana700iPic,
      id:"GarminMontana700i",
      itemLink:"../Electronics/HandheldGPS/GarminMontana700i",
      itemBoldText:"Garmin Montana 700i:",
      itemText:"The Garmin Montana 700i is a rugged, handheld GPS device designed for outdoor enthusiasts and adventurers. It features a large, glove-friendly touchscreen display, inReach satellite communication capabilities, and multi-GNSS support for accurate navigation in challenging environments. The device also includes preloaded maps and supports custom mapping, making it ideal for hiking, off-roading, and other outdoor activities."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Garmin Oregon 750",
      //imageRef:GarminOregon750Pic,
      id:"GarminOregon750",
      itemLink:"../Electronics/HandheldGPS/GarminOregon750",
      itemBoldText:"Garmin Oregon 750:",
      itemText:"The Garmin Oregon 750 is a versatile handheld GPS device designed for outdoor enthusiasts. It features a dual-orientation, sunlight-readable touchscreen with high-resolution display and multi-touch capabilities. The device comes equipped with an 8-megapixel autofocus camera, wireless connectivity for sharing data, and access to GPS and GLONASS satellite systems to ensure accurate navigation in challenging environments. Ideal for hiking, geocaching, and other outdoor activities, the Oregon 750 offers customizable mapping options and advanced tracking features."
    },
	
    ];
/*
  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setMobileOpen(false);
        //console.log('setMobileOpen=false');
      } else {
        setMobileOpen(true);
        //console.log('setMobileOpen=true');
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>
   <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <Typography align="left" variant="body1" component={'span'}>

    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
	        <h1>Handheld GPS</h1>
        </td>
        <td>
          <img className="RoundedCorners" src={HandheldGPSPic} style={{height:'120px'}} alt="Handheld GPS"></img>
        </td>
      </tr>
      </tbody>
    </table>


    Handheld GPS devices are a fun tool to have in the outdoors. They can help you navigate from point A to point B and mark items along the way. Do you need help getting back to your truck? A handheld GPS can help make that happen. Below are some great options for your next handheld device. <i>(Note: never rely solely on electronic devices when in the outdoors. Electronic devices can fail, so always have a backup.)</i>

    </Typography>
    <br/>

    {items.map((element)=> (
          <div key={`${element.id}-div`}>
            <Paper key={`${element.id}-paper`} elevation={3}
              sx={{
                p: 2,
                margin: 'auto',
                flexGrow: 1,
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
              }}
            >
            <Grid container key={`${element.id}-container`} spacing={12} rowSpacing={1}>
              <Grid item key={`${element.id}-outer`} xs={12} sm container>
                <Grid item key={`${element.id}-column`} xs container direction="column" spacing={9}>
                  <Grid item key={`${element.id}-inner`} xs>
                    <Typography key={`${element.id}-t1`} gutterBottom variant="subtitle1" component="div">
                      <a href={element.itemLink}><b>{element.itemBoldText}</b></a> {element.itemText}
                    </Typography>
                    <Typography key={`${element.id}-t2`} align="center">
                      <Link key={`${element.id}-l1`} href={element.itemLink}>
                        <Button key={`${element.id}-b1`} variant="contained">Pros & Cons</Button>
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Paper>
          <br/>
      </div>
      ))}

    </div>
    </>
  );
}

export default HandheldGPS;

