import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import HelinoxChairOnePic from '../images/HelinoxChairOne.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import CampChairsPic from '../../images/CampChairs.jpg';


function HelinoxChairOne () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Helinox Chair One</h1>
          <Link href="https://helinox.com/products/chair-one" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={CampChairsPic} style={{height:'120px'}} alt="Camp Chairs"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Helinox Chair One" height="130px" src={HelinoxChairOnePic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Helinox Chair One is a lightweight, portable camping chair designed for outdoor enthusiasts. It features a compact design that is easy to assemble and disassemble, making it ideal for camping, hiking, and other outdoor activities. The chair is made with durable materials and offers a comfortable seating experience despite its minimalistic design.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Lightweight and portable, easy to carry on hikes and camping trips.</li>
<li className="ListItemStyle">Compact design allows for easy storage and transportation.</li>
<li className="ListItemStyle">Durable construction with high-quality materials.</li>
<li className="ListItemStyle">Quick and easy to assemble and disassemble.</li>
<li className="ListItemStyle">Comfortable seating despite its small size.</li>
<li className="ListItemStyle">Stable on various terrains due to its design.</li>
<li className="ListItemStyle">Breathable mesh fabric for comfort in warm weather.</li>
<li className="ListItemStyle">Supports a good amount of weight relative to its size.</li>
<li className="ListItemStyle">Comes with a convenient carrying case.</li>
<li className="ListItemStyle">Stylish design with multiple color options.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Higher price point compared to other camping chairs.</li>
<li className="ListItemStyle">Low seating position may not be comfortable for everyone.</li>
<li className="ListItemStyle">Limited back support for extended sitting periods.</li>
<li className="ListItemStyle">Not suitable for very heavy individuals due to weight limit.</li>
<li className="ListItemStyle">Can be tricky to assemble on uneven ground.</li>
<li className="ListItemStyle">Legs can sink into soft ground like sand or mud.</li>
<li className="ListItemStyle">Fabric may wear over time with heavy use.</li>
<li className="ListItemStyle">Not as wide as some users might prefer.</li>
<li className="ListItemStyle">Requires careful packing to fit back into its carrying case.</li>
<li className="ListItemStyle">May not be ideal for those who prefer more traditional camping chairs.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default HelinoxChairOne;

