import * as React from 'react';
import Grid  from "@mui/material/Grid";
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import {useState, useEffect} from "react";
//import ButtonBase from '@mui/material/ButtonBase';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { useMediaQuery } from '@mui/material';

import SleepingBagsPic from '../images/SleepingBags.jpg';

//import KeltyCosmicDownPic from './images/KeltyCosmicDown.png';
//import MarmotTrestlesEliteEcoPic from './images/MarmotTrestlesEliteEco.png';
//import NEMODiscoPic from './images/NEMODisco.png';
//import ThermaRestHyperionPic from './images/ThermaRestHyperion.png';
//import WesternMountaineeringAlpinlitePic from './images/WesternMountaineeringAlpinlite.png';


function SleepingBags () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  const items = [
        {
      //manuLink:"[get link from company website]",
      //imageAlt:"Kelty Cosmic Down",
      //imageRef:KeltyCosmicDownPic,
      id:"KeltyCosmicDown",
      itemLink:"../Camping/SleepingBags/KeltyCosmicDown",
      itemBoldText:"Kelty Cosmic Down:",
      itemText:"The Kelty Cosmic Down sleeping bag is a lightweight, compressible, and affordable sleeping bag designed for backpackers and campers. It features water-resistant down insulation, a draft collar, and a durable construction suited for three-season use."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Marmot Trestles Elite Eco",
      //imageRef:MarmotTrestlesEliteEcoPic,
      id:"MarmotTrestlesEliteEco",
      itemLink:"../Camping/SleepingBags/MarmotTrestlesEliteEco",
      itemBoldText:"Marmot Trestles Elite Eco:",
      itemText:"The Marmot Trestles Elite Eco is a synthetic sleeping bag that balances eco-friendliness with performance. It's designed for backpackers and campers who demand a reliable and versatile sleeping bag that performs well in various conditions. It features an anatomically shaped footbox, fold-down second zipper for easy access and ventilation, a full-length locking YKK two-way zipper with anti-snag slider, and 3D hood construction. The synthetic insulation is made from recycled materials, making it an environmentally conscious choice."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"NEMO Disco",
      //imageRef:NEMODiscoPic,
      id:"NEMODisco",
      itemLink:"../Camping/SleepingBags/NEMODisco",
      itemBoldText:"NEMO Disco:",
      itemText:"The NEMO Disco is a sleeping bag designed for camping and backpacking, offering comfort and warmth without sacrificing space. Known for its distinctive spoon shape, it provides more room around the knees and elbows, making it ideal for side sleepers. The Disco is constructed with high-quality insulation materials and features like a waterproof footbox and a thermo gills system for temperature regulation, making it versatile for different weather conditions."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Therm-a-Rest Hyperion",
      //imageRef:ThermaRestHyperionPic,
      id:"ThermaRestHyperion",
      itemLink:"../Camping/SleepingBags/ThermaRestHyperion",
      itemBoldText:"Therm-a-Rest Hyperion:",
      itemText:"The Therm-a-Rest Hyperion is an ultra-lightweight and highly packable sleeping bag designed for backpackers and campers who prioritize low weight and compact size without sacrificing warmth. It features high-quality down insulation and a streamlined design to provide excellent thermal efficiency in cold environments."
    },
	    {
      //manuLink:"[get link from company website]",
      //imageAlt:"Western Mountaineering Alpinlite",
      //imageRef:WesternMountaineeringAlpinlitePic,
      id:"WesternMountaineeringAlpinlite",
      itemLink:"../Camping/SleepingBags/WesternMountaineeringAlpinlite",
      itemBoldText:"Western Mountaineering Alpinlite:",
      itemText:"The Western Mountaineering Alpinlite is a high-performance sleeping bag designed for backpackers and mountaineers who need a lightweight and warm option for cold weather camping. Built using premium materials, including 850+ fill goose down, this sleeping bag offers excellent insulation and a focus on comfort, making it a top choice for those venturing into alpine environments."
    },
	
    ];
/*
  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setMobileOpen(false);
        //console.log('setMobileOpen=false');
      } else {
        setMobileOpen(true);
        //console.log('setMobileOpen=true');
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>
   <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <Typography align="left" variant="body1"  component={'span'}>

    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
	        <h1>Sleeping Bags</h1>
        </td>
        <td>
          <img className="RoundedCorners" src={SleepingBagsPic} style={{height:'120px'}} alt="Sleeping Bags"></img>
        </td>
      </tr>
      </tbody>
    </table>


    Have you ever been spending the night outdoors without a sleeping bag warm enough for the cold? I have, and it isn’t very much fun. Sleeping bags are a must have when sleeping outdoors, even in the summer in some parts of the world. Below are some great options for keeping you warm on those cold nights.

    </Typography>
    <br/>

    {items.map((element)=> (
          <div key={`${element.id}-div`}>
            <Paper key={`${element.id}-paper`} elevation={3}
              sx={{
                p: 2,
                margin: 'auto',
                flexGrow: 1,
                backgroundColor: (theme) =>
                  theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
              }}
            >
            <Grid container key={`${element.id}-container`} spacing={12} rowSpacing={1}>
              <Grid item key={`${element.id}-outer`} xs={12} sm container>
                <Grid item key={`${element.id}-column`} xs container direction="column" spacing={9}>
                  <Grid item key={`${element.id}-inner`} xs>
                    <Typography key={`${element.id}-t1`} gutterBottom variant="subtitle1" component="div">
                      <a href={element.itemLink}><b>{element.itemBoldText}</b></a> {element.itemText}
                    </Typography>
                    <Typography key={`${element.id}-t2`} align="center">
                      <Link key={`${element.id}-l1`} href={element.itemLink}>
                        <Button key={`${element.id}-b1`} variant="contained">Pros & Cons</Button>
                      </Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

          </Paper>
          <br/>
      </div>
      ))}

    </div>
    </>
  );
}

export default SleepingBags;

