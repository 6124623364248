import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import SomewearGlobalHotspotPic from '../images/SomewearGlobalHotspot.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import SatelliteCommunicatorsPic from '../../images/SatelliteCommunicators.jpg';


function SomewearGlobalHotspot () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Somewear Global Hotspot</h1>
          <Link href="https://somewearlabs.com/product/hotspot/" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ SatelliteCommunicatorsPic} style={{height:'120px'}} alt="SatelliteCommunicators"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Somewear Global Hotspot" height="130px" src={SomewearGlobalHotspotPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Somewear Global Hotspot is a compact and durable satellite communication device designed to provide reliable connectivity and essential location-based services in remote areas. It integrates with smartphones via Bluetooth, enabling users to send and receive messages, track location, access weather updates, and call for emergency help worldwide, all without relying on cellular networks.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Compact and lightweight design makes it highly portable.</li>
<li className="ListItemStyle">Provides global satellite coverage with Iridium network.</li>
<li className="ListItemStyle">SOS functionality for emergency situations.</li>
<li className="ListItemStyle">Long battery life suitable for extended outdoor use.</li>
<li className="ListItemStyle">Two-way texting capability enables communication anywhere.</li>
<li className="ListItemStyle">Weather report feature helps in planning outdoor activities.</li>
<li className="ListItemStyle">Bluetooth connectivity allows easy integration with smartphones.</li>
<li className="ListItemStyle">User-friendly mobile app interface.</li>
<li className="ListItemStyle">Durable design built to withstand tough environments.</li>
<li className="ListItemStyle">Water-resistant for added reliability in adverse weather.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Requires a subscription plan for satellite service.</li>
<li className="ListItemStyle">Initial cost is relatively high compared to similar devices.</li>
<li className="ListItemStyle">Dependent on smartphone for full functionality.</li>
<li className="ListItemStyle">Limited to text messaging; no voice communication available.</li>
<li className="ListItemStyle">SOS feature relies on third-party service for emergency response.</li>
<li className="ListItemStyle">Bluetooth connection can drain smartphone battery.</li>
<li className="ListItemStyle">Slower message delivery compared to cellular networks.</li>
<li className="ListItemStyle">Limited storage for messages and location data.</li>
<li className="ListItemStyle">App interface might have a learning curve for some users.</li>
<li className="ListItemStyle">Not suitable for those who need frequent, extensive data transfer.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default SomewearGlobalHotspot;

