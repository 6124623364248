import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import SpypointSolarDarkPic from '../images/SpypointSolarDark.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import TrailCamsPic from '../../images/TrailCams.jpg';


function SpypointSolarDark () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Spypoint Solar Dark</h1>
          <Link href="https://www.spypoint.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ TrailCamsPic} style={{height:'120px'}} alt="TrailCams"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Spypoint Solar Dark" height="130px" src={SpypointSolarDarkPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Spypoint Solar Dark is a trail camera primarily used for wildlife monitoring and hunting purposes. It features a solar panel for extended battery life, a fast trigger speed, and a low glow flash that reduces visible light to avoid spooking animals. The camera aims to provide consistent, reliable performance in outdoor environments.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Extended battery life due to the integrated solar panel.</li>
<li className="ListItemStyle">Fast trigger speed capturing quick-moving animals effortlessly.</li>
<li className="ListItemStyle">Low Glow flash minimizes disturbance to wildlife.</li>
<li className="ListItemStyle">Good image quality during both day and night.</li>
<li className="ListItemStyle">Reliable performance in varied weather conditions.</li>
<li className="ListItemStyle">Quick setup and user-friendly interface.</li>
<li className="ListItemStyle">Compact and durable design suitable for outdoor use.</li>
<li className="ListItemStyle">Wireless connectivity for easy photo retrieval and settings adjustments.</li>
<li className="ListItemStyle">Suitable for a wide range of animal monitoring and hunting applications.</li>
<li className="ListItemStyle">Integrated image recognition technology for better wildlife monitoring.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Higher initial cost compared to non-solar models.</li>
<li className="ListItemStyle">Occasional connectivity issues in remote areas.</li>
<li className="ListItemStyle">May require a strong cellular signal for optimal performance.</li>
<li className="ListItemStyle">Limited video recording length per trigger event.</li>
<li className="ListItemStyle">Firmware updates can be infrequent or difficult to install.</li>
<li className="ListItemStyle">May not perform well in dense canopy areas with limited sunlight exposure.</li>
<li className="ListItemStyle">Physical security measures must be taken to prevent theft.</li>
<li className="ListItemStyle">Some users report inconsistencies in motion detection sensitivity.</li>
<li className="ListItemStyle">Solar panel efficiency heavily depends on geographical location.</li>
<li className="ListItemStyle">Requires a subscription for full access to all features.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default SpypointSolarDark;

