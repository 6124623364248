import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import EOTechEXPS3HolographicWeaponSightPic from '../images/EOTechEXPS3HolographicWeaponSight.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import RedDotSightsPic from '../../images/RedDotSights.jpg';


function EOTechEXPS3HolographicWeaponSight () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>EOTech EXPS3 Holographic Weapon Sight</h1>
          <Link href="https://www.eotechinc.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ RedDotSightsPic} style={{height:'120px'}} alt="RedDotSights"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="EOTech EXPS3 Holographic Weapon Sight" height="130px" src={EOTechEXPS3HolographicWeaponSightPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The EOTech EXPS3 Holographic Weapon Sight is a high-performance firearm optic known for its advanced holographic technology, offering rapid target acquisition and adaptability in various lighting conditions. It is particularly popular among military, law enforcement, and shooting sports enthusiasts due to its durability and ease of use. The sight features a compact design, night vision compatibility, and a configurable reticle, which enhance its versatility and practicality in different shooting scenarios.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Rapid target acquisition with holographic technology.</li>
<li className="ListItemStyle">Excellent performance in diverse lighting conditions.</li>
<li className="ListItemStyle">Compact and lightweight design.</li>
<li className="ListItemStyle">Night vision compatibility with multiple settings.</li>
<li className="ListItemStyle">Waterproof and durable construction.</li>
<li className="ListItemStyle">Easily adjustable brightness settings.</li>
<li className="ListItemStyle">Wide field of view for situational awareness.</li>
<li className="ListItemStyle">Parallax-free sight for accurate shooting.</li>
<li className="ListItemStyle">User-friendly controls for quick adjustments.</li>
<li className="ListItemStyle">Reliable battery life with low battery indicator.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Higher price point compared to some alternatives.</li>
<li className="ListItemStyle">Limited to close to mid-range shooting applications.</li>
<li className="ListItemStyle">Requires regular battery replacement.</li>
<li className="ListItemStyle">Potential for lens glare in certain conditions.</li>
<li className="ListItemStyle">Can be bulkier than traditional red dot sights.</li>
<li className="ListItemStyle">Some users may find reticle too busy.</li>
<li className="ListItemStyle">Limited eye relief for some shooting positions.</li>
<li className="ListItemStyle">Mounting may require additional hardware.</li>
<li className="ListItemStyle">Holographic sights generally have shorter battery life than LED sights.</li>
<li className="ListItemStyle">Specific maintenance required to prevent lens damage.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default EOTechEXPS3HolographicWeaponSight;

