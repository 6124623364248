import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import NEMODiscoPic from '../images/NEMODisco.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import SleepingBagsPic from '../../images/SleepingBags.jpg';


function NEMODisco () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>NEMO Disco</h1>
          <Link href="https://www.nemoequipment.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ SleepingBagsPic} style={{height:'120px'}} alt="SleepingBags"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="NEMO Disco" height="130px" src={NEMODiscoPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The NEMO Disco is a sleeping bag designed for camping and backpacking, offering comfort and warmth without sacrificing space. Known for its distinctive spoon shape, it provides more room around the knees and elbows, making it ideal for side sleepers. The Disco is constructed with high-quality insulation materials and features like a waterproof footbox and a thermo gills system for temperature regulation, making it versatile for different weather conditions.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Spoon shape provides extra room for side sleepers.</li>
<li className="ListItemStyle">High-quality insulation offers excellent warmth.</li>
<li className="ListItemStyle">Thermo gills allow for adjustable temperature control.</li>
<li className="ListItemStyle">Waterproof footbox keeps feet dry in damp conditions.</li>
<li className="ListItemStyle">Draft collar and zipper improve heat retention.</li>
<li className="ListItemStyle">Good compressibility for easy packing.</li>
<li className="ListItemStyle">Durable materials increase longevity.</li>
<li className="ListItemStyle">Offered in different temperature ratings for versatility.</li>
<li className="ListItemStyle">Lightweight, making it suitable for backpacking.</li>
<li className="ListItemStyle">Comfortable and roomy, enhancing sleep quality.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Slightly heavier compared to ultralight sleeping bags.</li>
<li className="ListItemStyle">May be expensive for budget-conscious buyers.</li>
<li className="ListItemStyle">Bulky when compressed compared to some competitors.</li>
<li className="ListItemStyle">Not ideal for extreme cold weather.</li>
<li className="ListItemStyle">Limited color options available.</li>
<li className="ListItemStyle">Zipper can snag if not handled carefully.</li>
<li className="ListItemStyle">Might not fit well in smaller backpack compartments.</li>
<li className="ListItemStyle">Requires careful maintenance for long-lasting performance.</li>
<li className="ListItemStyle">Some users report the draft collar could be more substantial.</li>
<li className="ListItemStyle">Might not be warm enough for those who sleep cold.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default NEMODisco;

