import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import MotorolaMS350RPic from '../images/MotorolaMS350R.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import _2WayRadiosPic from '../../images/2WayRadios.jpg';


function MotorolaMS350R () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Motorola MS350R</h1>
          <Link href="https://www.motorolasolutions.com/en_us/products/two-way-radios/consumer-two-way-radios.html" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ _2WayRadiosPic} style={{height:'120px'}} alt="2WayRadios"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Motorola MS350R" height="130px" src={MotorolaMS350RPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Motorola MS350R is a two-way radio designed for outdoor enthusiasts and professionals who require reliable communication in rugged conditions. It offers a range of up to 35 miles under optimal conditions and is equipped with numerous features such as NOAA weather alerts, waterproof design, and multiple channels.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Long communication range of up to 35 miles, suitable for outdoor use.</li>
<li className="ListItemStyle">Waterproof and durable design ideal for harsh environments.</li>
<li className="ListItemStyle">Includes NOAA weather alerts for weather updates and emergency information.</li>
<li className="ListItemStyle">Comes with 22 channels and 121 privacy codes for secure and interference-free communication.</li>
<li className="ListItemStyle">Hands-free iVOX feature for easy operation without the need for pressing buttons.</li>
<li className="ListItemStyle">Rechargeable batteries with dual power options, including standard AA batteries.</li>
<li className="ListItemStyle">Built-in flashlight for added convenience in low-light conditions.</li>
<li className="ListItemStyle">Vibrating call alert ensures you'll never miss a message in noisy environments.</li>
<li className="ListItemStyle">Compatible with other FRS/GMRS radios for versatile communication options.</li>
<li className="ListItemStyle">Easy-to-read backlit display for night or low-light use.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Range varies significantly depending on the terrain and weather conditions.</li>
<li className="ListItemStyle">Can be complex to operate for users unfamiliar with two-way radios.</li>
<li className="ListItemStyle">Limited battery life may require carrying additional batteries for extended use.</li>
<li className="ListItemStyle">Audio quality can be inconsistent depending on the range and obstructions.</li>
<li className="ListItemStyle">Bulky design may not be comfortable for all-day carry.</li>
<li className="ListItemStyle">Some users report difficulty in accessing customer support.</li>
<li className="ListItemStyle">Charging time for rechargeable batteries can be lengthy.</li>
<li className="ListItemStyle">May suffer from interference in areas with numerous other radio signals.</li>
<li className="ListItemStyle">Initial setup can be challenging without reference to the manual.</li>
<li className="ListItemStyle">Digital noise reduction feature may not be as effective in very noisy environments.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default MotorolaMS350R;

