import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import SigSauerRomeo5Pic from '../images/SigSauerRomeo5.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import RedDotSightsPic from '../../images/RedDotSights.jpg';


function SigSauerRomeo5 () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Sig Sauer Romeo5</h1>
          <Link href="https://www.sigsauer.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ RedDotSightsPic} style={{height:'120px'}} alt="RedDotSights"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Sig Sauer Romeo5" height="130px" src={SigSauerRomeo5Pic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Sig Sauer Romeo5 is a popular red dot sight designed for use on rifles, shotguns, and other firearms. Featuring a 2 MOA dot, it offers reliable aiming precision with a range of brightness settings for different lighting conditions. The Romeo5 is known for its durable construction, ease of use, and affordability, making it a favorable option for both new and experienced shooters.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Affordable price point compared to other red dot sights.</li>
<li className="ListItemStyle">Features the MOTAC (Motion Activated Illumination) that powers up when it senses motion and powers down when it does not, saving power and extending battery life.</li>
<li className="ListItemStyle">Compact and lightweight, making it easy to mount on various firearms without adding significant weight.</li>
<li className="ListItemStyle">Durable construction that is waterproof and fog-proof, suitable for various environmental conditions.</li>
<li className="ListItemStyle">Offers 10 illumination settings including 8 for daylight and 2 for night vision compatibility.</li>
<li className="ListItemStyle">Has a long battery life which can last up to 40,000 hours.</li>
<li className="ListItemStyle">Easy to zero in, maintaining accuracy over a wide range of conditions.</li>
<li className="ListItemStyle">Wide field of view for quick target acquisition.</li>
<li className="ListItemStyle">Compatible with a variety of magnifiers for extended range shooting.</li>
<li className="ListItemStyle">Comes with a low and co-witness riser mount for versatility in mounting options.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Optical clarity may not match higher-end models.</li>
<li className="ListItemStyle">Limited magnification as it is a standard red dot sight.</li>
<li className="ListItemStyle">Some users report parallax error when not looking straight through the sight.</li>
<li className="ListItemStyle">Battery compartment design can complicate battery replacement.</li>
<li className="ListItemStyle">MOTAC feature might sometimes be too sensitive, activating with minor movements.</li>
<li className="ListItemStyle">Limited to close to mid-range shooting due to the nature of red dot sights.</li>
<li className="ListItemStyle">Occasional reports of dot distortion or blooming under high brightness settings.</li>
<li className="ListItemStyle">Not ideal for precision shooting at longer distances.</li>
<li className="ListItemStyle">Dials for brightness settings can be stiff or difficult to turn.</li>
<li className="ListItemStyle">Mounting screws can come loose if not properly tightened.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default SigSauerRomeo5;

