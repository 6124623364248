import * as React from 'react';
import { useState, useEffect} from "react";
import Typography from '@mui/material/Typography';
import HomePageBackground from '../images/MountainPeak.JPG';
import HomePageBackgroundsm from '../images/MountainPeak-sm.jpg';
import { useMediaQuery } from '@mui/material';

// import HuntingPacksPic from './images/HuntingPacks.jpg';
// import TrailCamsPic from './images/TrailCams.jpg';
// import CampChairsPic from './images/CampChairs.jpg';
// import HandheldGPSPic from './images/HandheldGPS.jpg';
// import HuntingKnivesPic from './images/HuntingKnives.jpg';
import UserReviewsPic from './images/UserReviews.jpg';
import HomePic from '../images/MountainPeak-sm.jpg';

function Home() {
  const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(orientation: portrait)").matches) {
        setMobileOpen(false);
        //console.log('setMobileOpen=false');
      } else {
        setMobileOpen(true);
        //console.log('setMobileOpen=true');
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
  <div>
    {isSmallScreen === true &&
/*             <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
            <img alt="Join Me Outdoors" width="100%" height="auto" src={HomePageBackgroundsm}/>
            <Typography>
              <center>
              Join Me Outdoors has summarized hundreds of user reviews from a variety of websites.<br/>
              <br/>
              No longer do you need to spend hours reading reviews to make product buying decisions.<br/>
              <br/>
              Each product will have a list of Pros and Cons, based on summarizing user feedback.<br/>
              <br/>Use the menu to explore outdoor products and user reviews.
              </center>
            </Typography>
            </div> */
            <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
{/*             <div>
              <img className="homePicUpperLeft" src={UserReviewsPic} alt="Hunting Knives"/>
            </div> */}
            <div className="homeText">
            <Typography component={'span'} variant={'body2'}>
                  <div className="headingone">Hundreds of Outdoor Product Reviews, Summarized for You!</div>
                  <br/>
                  <div className="headingtwo">Join Me Outdoors has summarized the pros and cons of outdoor products based on hundreds of user reviews from different of websites. 
                No longer do you need to spend hours reading reviews to make product buying decisions.<br/>
                <br/>Use the menu to explore outdoor products and user reviews.
                </div>
            </Typography>
              </div>
              <br/>
            <br/>
            <img className='RoundedCorners' src={HomePic} style={{width:'100%'}} alt="Join Me Outdoors Product Reviews"/>
          </div>

    }
    {isSmallScreen === false &&
        <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
{/*           <div>
            <img className="homePicUpperLeft" src={UserReviewsPic} alt="Hunting Knives"/>
         </div> */}
          <div className="homeText">
              <Typography component={'span'} variant={'body2'}>
                  <div className="headingone">Hundreds of Outdoor Product Reviews, Summarized for You!</div>
                  <br/>
                  <div className="headingtwo">Join Me Outdoors has summarized the pros and cons of outdoor products based on hundreds of user reviews from different of websites. 
                No longer do you need to spend hours reading reviews to make product buying decisions.<br/>
                <br/>Use the menu to explore outdoor products and user reviews.
                </div>
            </Typography>
            </div>
            <br/>
            <br/>
            <img className='RoundedCorners' src={HomePic} style={{width:'100%'}} alt="Join Me Outdoors Product Reviews"/>
        </div>
      }
    </div> 
  );
}

export default Home;