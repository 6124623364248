import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import ALPSMountaineeringKingKongChairPic from '../images/ALPSMountaineeringKingKongChair.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import CampChairsPic from '../../images/CampChairs.jpg';


function ALPSMountaineeringKingKongChair () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>ALPS Mountaineering King Kong Chair</h1>
          <Link href="https://alpsmountaineering.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={CampChairsPic} style={{height:'120px'}} alt="Camp Chairs"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="ALPS Mountaineering King Kong Chair" height="130px" src={ALPSMountaineeringKingKongChairPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The ALPS Mountaineering King Kong Chair is a durable and comfortable folding chair designed for outdoor use. It features a sturdy steel frame, a high weight capacity, and padded armrests for added comfort. The chair also includes convenient storage options with cup holders and side pockets, making it ideal for camping, tailgating, or any outdoor event.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">High weight capacity of up to 800 pounds.</li>
<li className="ListItemStyle">Durable steel frame construction.</li>
<li className="ListItemStyle">Padded armrests for added comfort.</li>
<li className="ListItemStyle">Includes two cup holders and side pockets for storage.</li>
<li className="ListItemStyle">Easy to fold and transport with a carry bag included.</li>
<li className="ListItemStyle">Spacious seating area for enhanced comfort.</li>
<li className="ListItemStyle">Adjustable armrests for personalized comfort.</li>
<li className="ListItemStyle">Weather-resistant fabric suitable for outdoor use.</li>
<li className="ListItemStyle">Stable design with reinforced corners.</li>
<li className="ListItemStyle">Available in multiple colors to suit personal preference.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Heavier than some other portable chairs, making it less ideal for long-distance carrying.</li>
<li className="ListItemStyle">Bulkier when folded compared to some compact chairs.</li>
<li className="ListItemStyle">May be too large for smaller individuals.</li>
<li className="ListItemStyle">Higher price point than basic camping chairs.</li>
<li className="ListItemStyle">Fabric may fade with prolonged sun exposure.</li>
<li className="ListItemStyle">Limited reclining options.</li>
<li className="ListItemStyle">Armrests may not be adjustable enough for all users.</li>
<li className="ListItemStyle">May not fit well in smaller car trunks when packed.</li>
<li className="ListItemStyle">Cup holders may not accommodate larger bottles.</li>
<li className="ListItemStyle">Some users may find the chair too firm.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default ALPSMountaineeringKingKongChair;

