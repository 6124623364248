import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import BrowningStrikeForceHDProXPic from '../images/BrowningStrikeForceHDProX.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import TrailCamsPic from '../../images/TrailCams.jpg';


function BrowningStrikeForceHDProX () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Browning Strike Force HD Pro X</h1>
          <Link href="https://www.browning.com" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ TrailCamsPic} style={{height:'120px'}} alt="TrailCams"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Browning Strike Force HD Pro X" height="130px" src={BrowningStrikeForceHDProXPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Browning Strike Force HD Pro X is a trail camera designed for wildlife monitoring and scouting. It boasts high-resolution imagery, a fast trigger speed, and an invisible infrared flash, which makes it suitable for both day and night use. The camera is compact, easy to set up, and offers advanced features like time-lapse and video capabilities.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">High-resolution image quality with 20MP sensor.</li>
<li className="ListItemStyle">Fast trigger speed of 0.22 seconds reduces the chances of missing fast-moving subjects.</li>
<li className="ListItemStyle">Compact and durable design suitable for harsh outdoor conditions.</li>
<li className="ListItemStyle">Invisible infrared flash for no-spook night photos.</li>
<li className="ListItemStyle">Time-lapse plus shooting mode for detailed surveillance over time.</li>
<li className="ListItemStyle">Adjustable detection range for customizing camera placement.</li>
<li className="ListItemStyle">Long battery life, capable of lasting months depending on use.</li>
<li className="ListItemStyle">Supports up to 512GB SD cards, allowing for extensive storage.</li>
<li className="ListItemStyle">Easy to set up and program for quick deployment.</li>
<li className="ListItemStyle">Versatile mounting options for flexibility in camera positioning.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">Higher price point compared to some other trail cameras.</li>
<li className="ListItemStyle">Nighttime image quality can sometimes be less clear depending on lighting conditions.</li>
<li className="ListItemStyle">Video recording is limited to 900p resolution, not full HD.</li>
<li className="ListItemStyle">Additional costs for SD card and batteries not included.</li>
<li className="ListItemStyle">Complex menu system may require a learning curve for some users.</li>
<li className="ListItemStyle">No wireless connectivity means manual data retrieval is necessary.</li>
<li className="ListItemStyle">Weatherproofing is good, but not entirely waterproof.</li>
<li className="ListItemStyle">Limited field of view compared to some competitors.</li>
<li className="ListItemStyle">No remote control or app functionality for real-time adjustments.</li>
<li className="ListItemStyle">Subtle design updates from previous models may not justify an upgrade for existing owners.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default BrowningStrikeForceHDProX;

