import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
//import MotorolaT600H2OPic from '../images/MotorolaT600H2O.png';
//import { useState, useEffect} from "react";
import { useMediaQuery } from '@mui/material';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import _2WayRadiosPic from '../../images/2WayRadios.jpg';


function MotorolaT600H2O () {
  //const [mobileOpen, setMobileOpen] = useState(true);
  const isSmallScreen = useMediaQuery('(max-width:600px)');

/*
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setMobileOpen(false);
      } else {
        setMobileOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
*/
  return (
    <>

    <div style={{marginLeft: isSmallScreen ? '20px' : '260px', marginTop: '150px', marginRight:'20px'}}>
    <table>
      <tbody>
      <tr>
        <td style={{width:'95%'}}>
          <h1>Motorola T600 H2O</h1>
          <Link href="https://www.motorolasolutions.com/en_us/products/two-way-radios/consumer-two-way-radios.html" target="_blank">
            <Button variant="contained">Manufacturer's Website</Button>
          </Link>
        </td>
        <td>
          <img className="RoundedCorners" src={ _2WayRadiosPic} style={{height:'120px'}} alt="2WayRadios"></img>
        </td>
      </tr>
      </tbody>
    </table>

    <br/> 

            {/* <img alt="Motorola T600 H2O" height="130px" src={MotorolaT600H2OPic}/>  */}

    <Typography align="left" variant="body1" component={'span'}>
        The Motorola T600 H2O is a durable and water-resistant two-way radio designed for outdoor enthusiasts. It is equipped with a range of up to 35 miles in ideal conditions, and features 22 channels, including weather channels to keep users updated on the latest forecasts. The device is built to float in water, making it ideal for boating and water activities. It also comes with a built-in flashlight with both white and red LEDs for visibility in the dark.
        <br/>
        <br/>
        Below are key points based on user reviews:
    </Typography>
    <Box sx={{ flexGrow: 1, }}>
      <Typography align="left" variant="body1" component={'span'}>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Pros:</b>
          <br/>

              <ul>
				<li className="ListItemStyle">Waterproof and floats on water, making it ideal for water-related activities.</li>
<li className="ListItemStyle">Up to 35 miles of range in optimal conditions, providing good communication distance.</li>
<li className="ListItemStyle">22 channels and 121 privacy codes to minimize interference from other users.</li>
<li className="ListItemStyle">Includes NOAA weather channels, providing real-time weather updates.</li>
<li className="ListItemStyle">Built-in flashlight with both white and red LEDs for emergency situations.</li>
<li className="ListItemStyle">Emergency alert feature to notify others in case of a crisis.</li>
<li className="ListItemStyle">Robust and rugged design suitable for outdoor use.</li>
<li className="ListItemStyle">Dual power options: NiMH rechargeable batteries or standard AA alkaline batteries.</li>
<li className="ListItemStyle">VibraCall alert for discreet notifications.</li>
<li className="ListItemStyle">Easy-to-use push-to-talk button for quick operations.</li>

              </ul>

        </Paper>
        <br/>
        <Paper elevation={3}
          sx={{
            p: 2,
            margin: 'auto',
            flexGrow: 1,
            backgroundColor: (theme) =>
              theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
          }}
        >
          <b>Cons:</b>

              <ul>
			  <li className="ListItemStyle">The range significantly decreases in obstacles like dense forests or urban areas.</li>
<li className="ListItemStyle">The device can be bulky for some users.</li>
<li className="ListItemStyle">Rechargeable battery life may not be sufficient for extended trips without charging options.</li>
<li className="ListItemStyle">Sound quality can degrade in high winds or noisy environments.</li>
<li className="ListItemStyle">Higher cost compared to basic two-way radios.</li>
<li className="ListItemStyle">Does not include a backlit display, which can make nighttime use difficult.</li>
<li className="ListItemStyle">Limited battery charge indicator accuracy.</li>
<li className="ListItemStyle">Channels may overlap with other public two-way radios in crowded areas.</li>
<li className="ListItemStyle">Requires additional accessories for helmet or bike use.</li>
<li className="ListItemStyle">No integrated GPS feature.</li>
			  
              </ul>

        </Paper>
      </Typography>
    </Box>
    <br/>
    </div>
    </>
  );
}

export default MotorolaT600H2O;

